import React, { useEffect } from "react";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from '@emotion/react';
import { Img, Text } from "components";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./mission.css";

const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
const ABOUTUSMission = (props) => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    tl.to(".ourMission", {
      height: 180,
      scrollTrigger: {
        scrub: 5,
        trigger: ".ourMission",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);

  return (
    <>
      <div className={props.className}>
        <span id="mission">
        <i class="ri-focus-line"></i>
        </span>
        <div className="ourMission"></div>
        <div className="flex relative flex-col gap-5 items-start justify-start w-auto md:w-full">
          <Reveal keyframes={SlideFade} duration="700">
            <div className="flex flex-row gap-2 items-center justify-start w-auto sm:w-full">
              {/* <Img
                className="h-16 w-16"
                src="images/img_camera.svg"
                alt="camera"
              /> */}
              <Text
                className="sm:text-[32px] md:text-[38px] text-[40px] text-gray-50 tracking-[0.20px]  w-[260px]"
                size="txtInterBold40Gray50"
              >
                {props?.missiontext}
              </Text>
            </div>
          </Reveal>
          <div className="flex flex-col items-center justify-start w-full">
            <Text
              className="leading-[28.00px] text-gray-50 text-lg md:text-base sm:text-sm w-full"
              size="txtInterRegular20"
            >
              {props?.descriptiontext}
            </Text>
            <li style={{ listStyle: "none" }} id="QNA"></li>
          </div>
        </div>
      </div>
    </>
  );
};

ABOUTUSMission.defaultProps = {
  missiontext: "Our Mission",
  descriptiontext: (
    <>
      With a customer-centric approach, we aim to forge strong partnerships with
      our clients, understanding their challenges, and providing personalised
      support and guidance. <br />
      <br />
      We are committed to delivering exceptional customer service, fostering
      long-term relationships, and continually adapting our solutions to meet
      the evolving needs of the industry.
      <br />
      <br />
      Together, we strive to elevate quality standards, ensure regulatory
      compliance, and contribute to the advancement of patient health and
      safety.
    </>
  ),
};

export default ABOUTUSMission;
