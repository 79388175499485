import React, { useEffect, useState } from "react";
import { Img, Line, Button, Text } from "components";
import ServicesSd from "pages/ServicesSd";
import ServicesSd1 from "pages/ServicesSd1";
import ServicesSd2 from "pages/ServicesSd2";
import { Link } from "react-router-dom";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "../../Main/HOMEPAGE/cards.css";
// import http from "http";
import { TextWithReadMore } from "components/TextWithReadMore";

const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const ServicesPage = () => {
  const [allServices, setAllServices] = useState([]);
  const [details, setDetails] = useState({});
  const [border, setBorder] = useState(0);

  useEffect(() => {
    
    window.scrollTo(0, 0);
    getdata()

    async function getdata() {
      fetch(`https://admin.qssence.com/api/showServices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          setAllServices(data.data);
          if (data.data.length > 1) {
            showDetails(data.data[0], 0)
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }, [])

  const showDetails = (item, index) => {
    setDetails(item);
    setBorder(index);
  }

  return (
    <>
      <div className="bg-blue_gray-50 flex flex-col font-inter items-center justify-start sm:w-full w-full">
        <div className="bg-gray-900 flex flex-col md:gap-10 gap-[60px] items-center justify-center max-w-full mb-auto mt-[-1px] mx-auto md:px-10 px-20 sm:px-5 pb-[40px] pt-[120px] sm:w-full w-full z-[1]">
          <div className="flex flex-col gap-5 items-start justify-start sm:w-full w-full">
            <div className="flex flex-col items-center justify-start w-[61%] md:w-full">
              <div className="flex flex-col items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-col items-start justify-start sm:w-full w-full">
                    <Reveal keyframes={SlideFade} duration="800">
                      <Text
                        className="sm:text-[38px] md:text-[44px] text-[52px] text-green-600"
                        size="txtInterBold52Green600"
                      >
                        Partners in pursuit of Excellence
                      </Text>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:gap-10 gap-[60px] items-start justify-start sm:w-full md:w-full w-full">
              <Text
                className="leading-[28.00px] text-gray-50 text-lg md:text-lg sm:text-sm "
                size="txtInterRegular20"
              >
                <>
                  Qssence Consulting Services (QCS) is proud to unveil a
                  dynamic and meticulously crafted professional service model
                  that represents a ground-breaking approach in the realm of
                  pharmaceutical consulting. Central to this innovative model
                  is the formation of a consortium comprising exceptionally
                  skilled and accomplished experts from the pharmaceutical
                  sector. These experts bring with them a wealth of
                  experience, qualifications, and unparalleled domain
                  knowledge that collectively constitutes a formidable force
                  in the field.
                  <br />
                  <br />
                  At the core of this visionary initiative lies the philosophy
                  of tailored consulting and advisory services. Qssence
                  recognizes that the modern pharmaceutical landscape demands
                  specialized insights and strategic guidance. Thus, our
                  consortium of experts stands poised to offer precisely that
                  – services customized to meet the unique needs and
                  challenges faced by our valued clients.
                </>
              </Text>
              <Img
                className="h-[630px] sm:h-auto sm:w-full  w-full"
                src="images/img_chemistputting_417x742.webp"
                alt="images/img_chemistputting_417x742.png"
              />
              <Text
                className="text-left text-gray-50 text-lg md:text-lg sm:text-sm "
                size="txtInterRegular20"
              >
                <>
                  The consortium&#39;s composition is carefully curated to
                  encompass a spectrum of strengths,experiences,and
                  domain-specific proficiencies.This diversity is a
                  cornerstone of our approach, ensuring that clients have
                  access to a comprehensive range of expertise , all
                  conveniently located within a single consultancy.
                  <br />
                  <br />
                  Our overarching objective is crystal clear : to empower our
                  clients to achieve excellence in the realms of
                  pharmaceuticals and life sciences . Through this pioneering
                  service model, we aim to facilitate a seamless journey
                  toward quality excellence.By harnessing the collective
                  wisdom <br />of our consortium members, we equip clients with the
                  tools and insights needed to navigate the complex terrain of
                  the pharmaceutical indust<br />- ry successfully.
                  <br />
                  <br />
                  In an ever-evolving field where compliance, innovation, and
                  precision are paramount, QCS’s professional service model
                  sets a new industry standard. We are committed to not just
                  meeting but exceeding the expectations of our clients,
                  ensuring that their unique challenges are met<br /> with
                  specialized solutions, and that their goals are translated
                  into tangible, market-leading achievements.
                </>
              </Text>
            </div>
          </div>
          <div className="bg-gray-900 border border-gray-50 border-solid flex flex-col items-start  justify-start p-11 md:px-10 sm:px-5 rounded-[40px] shadow-bs25 sm:w-full w-full">
            <Reveal keyframes={slideUp} delay="70">

              <Text
                className="leading-[40.00px] max-w-[full] mx-auto md:max-w-full lg:text-2xl md:text-xl mx:auto text-[28px] text-center text-gray-50 tracking-[0.32px]"
                size="txtInterBold32"
              >
                <Reveal keyframes={slideUp} delay="50">

                  With Qssence, excellence is not merely a goal; it is an
                  attainable reality,
                </Reveal>
                {/* <Reveal keyframes={slideUp} delay="70"> */}
                  driven by a consortium of experts united in
                  their dedication to advancing
                  {/* </Reveal> */}
                {/* <Reveal keyframes={slideUp} delay="900"> */}
                  the pharmaceutical and life
                  sciences sectors.
                  {/* </Reveal> */}
              </Text>
            </Reveal>
          </div>
          <div className="flex flex-col gap-10 items-start justify-start sm:w-full w-full">
            <Text
              className="md:text-3xl sm:text-[28px] text-[32px] text-gray-50 tracking-[0.32px] w-full"
              size="txtInterBold32"
            >
              Service Delivery:
            </Text>
            <div className="flex flex-col items-start justify-start sm:w-full md:w-full w-full">
              <div className="gap-[60px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-start justify-start sm:w-full w-full">
                <ServicesSd1 className="bg-white-A700_0a flex flex-1 flex-col gap-2.5 h-[400px] items-start justify-center sm:px-5 px-[31px] py-[60px] rounded-[12px] sm:w-full w-full" />
                <ServicesSd className="bg-white-A700_0a flex flex-1 flex-col gap-2.5  h-[400px] items-start justify-center sm:px-5 px-[31px] py-[60px] rounded-[12px] sm:w-full w-full" />
                <ServicesSd2 className="bg-white-A700_0a flex flex-1 flex-col gap-2.5 md:h-auto h-[400px] items-start justify-center sm:px-5 px-[31px] py-[60px] rounded-[12px] sm:w-full w-full" />
              </div>
            </div>
          </div>

        </div>
        <div className="bg-blue_gray-50 flex flex-col gap-5 items-start justify-start max-w-full md:px-10 px-20 sm:px-5 py-[20px] sm:w-full w-full">
          <Reveal keyframes={SlideFade} duration="900">
            <Text
              className="sm:text-[38px] md:text-[44px] text-[52px] text-green-600 w-full"
              size="txtInterBold52Green600"
            >
              Services
            </Text>
          </Reveal>
          <div className="flex md:flex-col sm:flex-col md:gap-10 gap-[73px] items-start justify-start max-w-full mx-auto sm:w-full w-full">
            <div className="flex flex-col items-start justify-between w-[260px]">
              <div className="flex flex-col gap-6 items-start justify-start sm:w-full w-full">
                {/* audit  */}
                {allServices.map((item, index) => (
                  <div onClick={() => showDetails(item, index)} key={index} className="flex flex-col items-start justify-start w-full " >{/*  style={border == index?{borderBottom:'green'}:""} */}
                    <div className="flex flex-col h-8 md:h-auto items-start justify-start w-[315px] cursor-pointer ">
                      <Text
                        className={`text-gray-900 text-base md:text-lg sm:text-base w-auto ${border === index ? 'border-b-4 border-green-700' : ''}`}
                        size="txtInterRegular20Gray900"
                      >
                        {item.title}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {details?.description != null ? <Line className="bg-gray-800 h-[814px] md:h-px md:w-full sm:w-full w-px" /> : null}

            {details?.description != null ? <div className="flex flex-1 flex-col md:gap-10 gap-[60px] items-start justify-start sm:w-full w-full">
              <div className="flex flex-col gap-6 items-center justify-center sm:w-full w-full">
                <div className="relative bg-blue_gray-100_06 h-[360px] sm:w-full w-full">
                  <Img className="h-full w-full" src={details.serviceIMG} alt={`${details.serviceIMG} image`} />
                  {console.log(details.serviceIMG)}
                </div>
                <div className="flex flex-col gap-3  items-center justify-center sm:w-full w-full">
                  <TextWithReadMore
                    className="leading-[24.00px] max-w-full md:max-w-full text-gray-900 text-base md:text-base sm:text-sm"
                    size="txtInterRegular18Gray900"
                  >
                    {details?.description}
                  </TextWithReadMore>

                </div>
              </div>

              <div className="flex flex-col items-center justify-start w-auto">
                <div className="head">
                  <Button
                    className="common-pointer cursor-pointer flex items-center justify-center min-w-[200px] rounded-[36px]"
                    rightIcon={
                      <Img
                        className="h-6 mb-px ml-2"
                        src="images/img_arrowright.svg"
                        alt="arrow_right"
                      />
                    }
                    size="xl"
                    variant="gradient"
                    color="indigo_800_green_600"
                  >
                    <div className="font-bold text-center text-lg">
                      <Link to="/talktoexperts">Request demo</Link>
                    </div>
                  </Button>
                </div>
              </div>
            </div> : null}
          </div>

        </div>
      </div>
    </>
  );
};

export default ServicesPage;

