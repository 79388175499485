import React from 'react';
import { Img, Button, Line, Text } from "components";
import { Link } from "react-router-dom";


export default function footer() {
    return (
        <div className="flex flex-col items-center  justify-start w-full">
            <div className="bg-gray-900_01 flex flex-col gap-10 items-start justify-start md:px-10 px-20 pt-[15px] sm:px-5 pb-[10px] w-full md:w-full footer">
                <div className="flex flex-row font-dmsans md:gap-10 items-start justify-between max-w-[full] mx-auto w-full">
                    <div className="flex flex-col items-center justify-center  w-auto">
                        <div className="flex flex-row gap-4 items-center justify-between w-full">
                                <Img
                                    className="h-[45px]"
                                    src="images/img_folder.svg"
                                    alt="footer_logo"
                                />
                            <div className="flex flex-row items-start justify-evenly mt-[9px] sm:mt-0">
                                <div className="flex flex-col items-center justify-start mt-[3px] w-[100%]">
                                    <div className="flex flex-col items-start justify-start w-full">
                                        <div className="flex flex-col font-brunoace items-center justify-start w-full">
                                            <Text
                                                className="sm:text-[16.25px] md:text-[18.25px] text-[15px] text-gray-50 tracking-[4.86px]"
                                                size="txtBrunoAceRegular2025"
                                            >
                                                QSSENCE
                                            </Text>
                                        </div>
                                        <span className="text-base sm:text-base text-white-A700" style={{ fontFamily: "'Caveat', cursive" }}>Make it Happen</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 items-center justify-center sm:w-full w-auto">
                        <Text
                            className="text-gray-50 text-xl w-auto"
                            size="txtDMSansBold20"
                        >
                            Follow Us
                        </Text>
                        <div className="flex flex-row gap-4 items-start justify-end w-auto">
                            <div className="flex flex-col h-9 items-center justify-start w-9">
                                <Button
                                    className="flex h-9 items-center justify-center rounded-lg w-9"
                                    color="white_A700_14"
                                    size="md"
                                    variant="fill"
                                >
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            window.open("https://www.linkedin.com/company/qssence-techvantage/", '_blank');
                                            e.preventDefault();
                                        }}
                                    >
                                        <Img src="images/img_linkedin.svg" alt="linkedin" />
                                    </Link>
                                </Button>
                            </div>
                            {/* <div className="flex flex-col h-9 items-center justify-start w-9">
                                <Button
                                    className="flex h-9 items-center justify-center rounded-lg w-9"
                                    color="white_A700_14"
                                    size="md"
                                    variant="fill"
                                >
                                    <Img src="images/img_play.svg" alt="play" />
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Line className="bg-gray-50_cc h-px max-w-[full] mx-auto w-full" />
                <Text
                    className="capitalize text-base text-center text-gray-50  w-auto"
                    size="txtInterRegular16"
                >
                    <span className="text-gray-50 font-inter font-normal">
                        Qssence | Copyright © 2023 | All Rights Reserved | {""}
                    </span>
                    <span className="text-green-600 font-inter font-normal">
                        <Link to="/privacy">Privacy Statement</Link>
                    </span>
                    <span className="text-gray-50 font-inter font-normal">
                        {" "}
                        |{" "}
                    </span>
                    <span className="text-green-600 font-inter font-normal">
                        <Link to="/terms">Terms & Conditions</Link>
                    </span>
                </Text>
            </div>
        </div>
    )
}
