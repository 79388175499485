import React from "react";
// import "./QMS.css"
import { Img, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const Qmsfdavision2 = (props) => {
  return (
    <>
      <Reveal keyframes={slideUp} delay="100">
        <div className={`${props.className} `}>
          <div className="card1">
            <div className="content relative">
              <div className="front border border-green-600 border-solid   flex flex-col md:h-auto  items-center justify-center m-auto px-3.5 py-[46px]  rounded-[20px] w-[full]  md:w-full">
                <div className="flex flex-col  justify-start w-[50%] bg-transparent  rounded-[20px]">
                  <Reveal keyframes={slideUp} delay="170">
                    <Text
                      className="bg-gray-900_01 flex h-[60px] ml-[40px]  items-center justify-center sm:px-5 rounded-[50%] text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl tracking-[0.48px] w-[58px]"
                      size="txtInterBold24Gray50"
                    >
                      3
                    </Text>
                  </Reveal>
                  <Text
                    className="text-2xl md:text-[22px] w-[180px] ml-[-28px] mt-[18px] text-gray-900 sm:text-xl"
                    size="txtInterRegular24"
                  >
                    {props?.deviationtexts}
                  </Text>
                </div>
              </div>
              <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                <Text
                  className="leading-[20.00px]  rounded-[20px] text-sm bg-green-600 sm:p-[20px] max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                  size="txtInterRegular18"
                >
                  <ul className="list-disc pl-5" style={{ lineHeight: "1.5" }}>
                    <li style={{ lineHeight: "1.5" }}>
                      Rate the Quality Management Maturity to incentivize of
                      pharmaceutical manufacturing sites to invest in achieving
                      Quality Management Maturity (QMM)
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Convince purchasers to consider QMM in decision-making
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Separate QMM appraisals from regulatory compliance
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      The market to reward products from facilities with higher
                      QMM
                    </li>
                  </ul>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

Qmsfdavision2.defaultProps = {
  deviationtexts: (
    <Img
      className="h-[180px] md:h-[200px]   sm:h-[200px]  w-[300px] sm:w-[250px] "
      src="images/img_qmmimage21.png"
      alt="imagesubmiss"
    />
  ),
};

export default Qmsfdavision2;
