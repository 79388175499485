import React, { useState, useEffect } from 'react'
import { Text } from 'components';
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { Dialog} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
// import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'



// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);
  const [myPath, setMyPath] = useState("/");
  // const navigate = useNavigate();

  useEffect(() => {
    getMyPath();
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const getMyPath = () => {
    const baseUrl = window.location.origin;
    const currentUrl = window.location.href;
    const path = currentUrl.replace(baseUrl, "");
    setMyPath(path);
  }
  return (
    <header className={`w-full fixed z-10 ${myPath === "/products" || myPath === "/services" || myPath === "/blog" ? 'bg-gradient5' : isScrolled ? 'bg-gradient5' : 'bg-transparent'}`}>
      <nav className="mx-auto flex max-w-full items-center justify-between p-4 px-8" aria-label="Global">
        <div className="flex items-center min-w-[10%]">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">QSSENCE</span>
            <img
              className="h-[40px] w-[50px] mb-2"
              src="images/img_folder.svg"
              alt="logo"
            />
          </Link>
          <Link to='/'>
            {/* <p className='text-gray-900 text-lg font-brunoace'>QSSENCE</p> */}
            <Text
              className="sm:text-[16.25px] md:text-[18.25px] text-[15px] text-gray-900 tracking-[4.86px]"
              size="txtBrunoAceRegular2025"
            >
              QSSENCE
            </Text>
            <p className='text-gray-900 ' style={{ fontFamily: "'Caveat', cursive" }}>Make it Happen</p>
          </Link>
        </div>
        <div className="flex hidden md:block sm:block">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:hidden md:hidden flex w-[55%]  items-center justify-between ">
          {myPath === '/' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700`}>
                Home
              </Link>
            </div>

          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 `}>
                Home
              </Link>
            </div>
          )
          }
          {myPath === '/aboutus' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/aboutus" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700`}>
                About Us
              </Link>
            </div>
          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/aboutus" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 `}>
                About Us
              </Link>
            </div>
          )
          }

          {myPath === '/products' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/products" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700`}>
                Products
              </Link>
            </div>

          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/products" className={`text-sm font-semibold max-w-[77px] min-w-[20px] leading-6   text-gray-900 `}>
                Products
              </Link>
            </div>

          )
          }

          {myPath === '/services' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/services" onClick={() => getMyPath()} className="text-sm font-semibold hover:font-bold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700">
                Services
              </Link>
            </div>

          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/services" className="text-sm font-semibold hover:font-bold max-w-[77px] min-w-[20px] leading-6  text-gray-900">
                Services
              </Link>
            </div>

          )
          }

          {myPath === '/blog' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/blog" className="text-sm font-semibold hover:font-bold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700">
                Blog
              </Link>
            </div>

          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/blog" className="text-sm font-semibold  hover:font-bold max-w-[77px] min-w-[20px] leading-6    text-gray-900">
                Blog
              </Link>
            </div>

          )
          }

          {myPath === '/talktoexperts' ? (
            <div onClick={() => getMyPath()}>
              <Link to="/talktoexperts" className="text-sm font-semibold hover:font-bold max-w-[77px] min-w-[20px] leading-6   text-gray-900 border-b-4 border-green-700">
                Request Demo
              </Link>
            </div>

          ) : (
            <div onClick={() => getMyPath()}>
              <Link to="/talktoexperts" className="text-sm font-semibold  hover:font-bold max-w-[77px] min-w-[20px] leading-6    text-gray-900">
                Request Demo
              </Link>
            </div>

          )
          }
          <Link onClick={(e) => {
            window.location.href = "mailto:info@qssence.com";
            e.preventDefault();
          }}
            to="#" className="text-sm font-semibold hover:font-bold min-w-[77px] h-[auto] w-[20px] leading-6    text-gray-900">
            <i class="ri-mail-line text-lg  text-black-400 rounded-full border border-black-900 p-[10px] hover:text-green-500 hover:border-green-500"></i>
          </Link>
        </div>
      </nav>

      <Dialog as="div" className="sm:block md:block" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed bg-white-A700 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {myPath === '/' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      Home
                    </Link>
                  </div>

                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-500 hover:text-white-A700"
                    >
                      Home
                    </Link>
                  </div>
                )
                }

                {myPath === '/aboutus' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/aboutus"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      About Us
                    </Link>
                  </div>

                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/aboutus"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-500 hover:text-white-A700"
                    >
                      About Us
                    </Link>
                  </div>
                )
                }

                {myPath === '/products' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/products"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      Products
                    </Link>
                  </div>

                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/products"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-green-500 hover:text-white-A700"
                    >
                      Products
                    </Link>
                  </div>
                )
                }

                {myPath === '/services' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/services"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      Services
                    </Link>
                  </div>
                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/services"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-500 hover:text-white-A700"
                    >
                      Services
                    </Link>
                  </div>
                )
                }

                {myPath === '/blog' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/blog"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      Blog
                    </Link>
                  </div>
                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/blog"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-500 hover:text-white-A700"
                    >
                      Blog
                    </Link>
                  </div>
                )
                }

                {myPath === '/talktoexperts' ? (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/talktoexperts"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-200 hover:text-white-A700 bg-green-500 text-white-A700"
                    >
                      Request Demo
                    </Link>
                  </div>
                ) : (
                  <div onClick={() => getMyPath()}>
                    <Link
                      to="/talktoexperts"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900  hover:bg-green-500 hover:text-white-A700"
                    >
                      Request Demo
                    </Link>
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header >
  )
}
