import React, { useEffect } from "react";
import { Button, Img, List, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import ProductsDMSChal3 from "components/ProductsDMSChal3";
import ProductsDMSColumn from "components/ProductsDMSColumn";
import ProductsDMSColumnOne from "components/ProductsDMSColumnOne";
import ProductsDMSColumnThree from "components/ProductsDMSColumnThree";
import ProductsDMSColumnTwo from "components/ProductsDMSColumnTwo";
import ProductsQMSStackOne from "components/ProductsDMSStackOne";
import ProductsQMSStackcomplaints from "components/ProductsDMSStackcomplaints";
import ProductsQMSStackoosoot from "components/ProductsDMSStackoosoot";
import ProductsQMSStackdocumentmanagemOne from "components/ProductsDMSStackdocumentmanagemOne";
import ProductsQMSStackinvestigationi from "components/ProductsDMSStackinvestigationi";
import ProductsQMSStackchangecontrol from "components/ProductsDMSStackchangecontrol";
import "./dms.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
// const slideRight = keyframes`
//         from {
//             opacity: 0;
//             transform: translate(90%, 0);
//         }

//         to {
//             opacity: 1;
//             transform: translate(0, 0);
//         }
//     `;
// const upFade = keyframes`
//         from {
//             opacity: 0;
//             transform: translate(0, 50%);
//         }

//         to {
//             opacity: 1;
//             transform: translate(0, 0%);
//         }
//     `;
const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;

export default function DMS() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline();
        tl.to(".dmsbenifits", {
            height: 370,
            scrollTrigger: {
                scrub: 2,
                trigger: ".line1",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play",
            },
        });
        tl.to(".dmsufo", {
            height: 370,
            scrollTrigger: {
                scrub: 2,
                trigger: ".dmsufo",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play",
            },
        });
        tl.to(".dmsindustry", {
            height: 320,
            scrollTrigger: {
                scrub: 2,
                trigger: ".dmsindustry",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play",
            },
        });
    }, []);

    return (
        <div>
            <div className="sm:h-[0px] md:h-[0px] md:invisible sm:invisible bg-blue_gray-50  flex flex-col font-dmsans items-center justify-start mx-auto  sm:w-full md:w-full w-full">
                <div className="sm:invisible bg-gray-900_01 font-inter  h-[650px] mt-28  md:h-[2384px] sm:h-[1100px] md:px-5 pb-[98px] pt-[70px] relative w-full">
                    <div className="ml-20 pt-3 pb-8 sm:hidden">
                        <Reveal keyframes={SlideFade} duration="700">
                            <Text
                                className=" left-[5%] sm:text-[38px]  md:text-[44px]   text-[48px] text-white-A700 top-[10%]"
                                size="txtInterBold52WhiteA700"
                            >
                                <span className="text-white-A700 font-brunoace ml-[15px] text-left font-normal">
                                    DoQssence
                                </span>
                                <span className="text-green-600 font-brunoace ml-[15px] text-left font-normal">
                                    Suite
                                </span>
                            </Text>
                        </Reveal>
                    </div>
                    <div className=" h-[1024px]  md:h-[2384px] sm:h-[875px] inset-[0] justify-center  sm:mt-28 w-full">
                        <div className="absolute  flex flex-col md:gap-10 gap-20 inset-x-[0] items-center justify-start mx-auto w-auto md:w-full">
                            <div className="flex sm:invisible sm:hidden flex-col items-center justify-start w-full">
                                <div className="flex flex-col items-center justify-start w-full">
                                    <div className="flex flex-col items-start justify-center w-full mt-0 px-20 md:w-full">
                                        <div className="bg-white-A700_14  flex flex-col  items-start justify-start max-w-full md:px-10 sm:px-5 mx-auto   py-15 rounded-[40px] w-full">
                                            <Reveal keyframes={SlideFade} duration="700">
                                                <Text
                                                    className="md:text-3xl sm:text-[28px] ml-[25px] text-[28px] text-gray-50 tracking-[0.32px] pt-[14px] w-auto"
                                                    size="txtInterBold32"
                                                >
                                                    <span className="text-gray-50 font-inter text-left font-bold ml-[25px]">
                                                        Qssence{" "}
                                                    </span>
                                                    <span className="text-green-600 font-inter text-left font-bold">
                                                        DMS Modules
                                                    </span>
                                                </Text>
                                            </Reveal>
                                            <div className="flex relative w-full">
                                                <div className="md:h-[495px] h-[480px]  w-[50%] sm:w-full">
                                                    <Img
                                                        className="absolute h-[480px] inset-[0] justify-center   rounded-[83px] w-[85%]"
                                                        src="images/QMS_Module1.webp"
                                                        alt="images/QMS_Module1.png"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-16 mb-[28px] pb-[56px] justify-start  my-auto w-[57%] z-[1]">
                                                    <List
                                                        className="flex flex-col gap-10 items-center ml-2 md:ml-[0] w-[99%]"
                                                        orientation="vertical"
                                                    >
                                                        <div className="flex flex-col gap-2.5  justify-start w-[105%] md:w-[110%] ml-[20px] md:w-full">
                                                            <div className="flex sm:flex-col flex-row gap-10  changeControlQMS md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Change control
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Deviation
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Out Of Specification
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-[9px] justify-start w-full">
                                                            <div className="flex sm:flex-col flex-row mb-[9px] sm:gap-10 gap-[45px] outOfTrendQMS items-center ml-[60px] justify-start md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px]  text-center text-gray-50 text-xl"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Out Of <br />
                                                                            Trend
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-xl"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Root Cause <br />
                                                                            Investigation
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-xl"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Corrective Actions
                                                                            <br /> & Preventive Actions
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-[9px] justify-start w-[92%] md:w-full">
                                                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[40px] internalAuditQMS items-start ml-[1px] justify-start md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Internal <br />
                                                                        Audit
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-xl"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Complaint <br />
                                                                            Management
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Regulatory <br /> Audit
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-2.5 justify-start w-[92%] w-full">
                                                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[60px] CustomerNotificationQMS  md:h-auto items-start justify-start  md:ml-[0] w-[559px] sm:w-full">
                                                                <div className="flex flex-col ml-[40px] items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-xl"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Customer
                                                                            <br />
                                                                            Notification
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Quality <br />
                                                                        Reviews
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-xl w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Vendor <br />
                                                                        Complaints
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="flex flex-col font-inter items-center justify-start w-full md:w-full"
                style={{ overflow: "hidden" }}
            >
                <div className="flex flex-col items-center justify-start w-full md:w-full">
                    <div className="flex flex-col gap-3 items-center justify-start w-full md:w-full">
                        <div
                            className="bg-blue_gray-50 flex flex-col  items-center justify-start max-w-full pt-[100px]  md:px-10 px-10 mt-[-220px] md:mt-[180px] sm:mt-[170px] sm:pt-0 sm:px-5 w-full"
                            style={{ overflow: "hidden" }}
                        >
                            <div className="flex md:flex-col sm:flex-col gap-1 items-center justify-between sm:gap-y-20 max-w-full mx-auto md:px-5   sm:p-0 w-full">
                                <div className="flex  flex-col gap-3 items-start justify-start sm:mt-32 w-[472px] sm:w-full">
                                    {/* <Reveal keyframes={SlideFade} duration="700"> */}

                                    <div className="font-brunoace md:h-[73px] h-[98px] relative  w-[472px] sm:w-full">
                                        <Text
                                            className=" left-[0] md:text-5xl text-5xl text-green-600 top-[0] w-auto"
                                            size="txtBrunoAceRegular60"
                                        >
                                            DoQssence
                                        </Text>
                                        <div className="bottom-[0] flex flex-col font-inter inset-x-[0] items-center justify-start mx-auto w-[472px] sm:w-full">
                                            <div className="flex flex-col items-start justify-start w-[472px] sm:w-full">
                                                <Text
                                                    className="text-xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                                                    size="txtInterBold24"
                                                >
                                                    Document Management System (DMS)
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </Reveal> */}
                                </div>
                                {/* img start  */}
                                <div className="flex relative md:flex-1 flex-col items-center justify-start w-[50%] md:mt-[-60px]  sm:w-full md:w-full">
                                    <div className="flex flex-col md:gap-10 gap-[60px] ml-16 sm:ml-[0px] items-center justify-start w-full md:w-full">
                                        {/* <Reveal keyframes={rightFade} duration="700"> */}
                                        <div className="flex  h-[600px] relative sm:h-[400px] sm:w-[full] w-full">
                                            <Img
                                                className=" h-[750px] sm:h-[500px]  inset-y-[0] my-auto   rounded-sm w-full"
                                                src="images/DoQssence_dashboard.webp"
                                                alt="images/DoQssence_dashboard.png"
                                            />
                                        </div>
                                        {/* </Reveal> */}
                                        {/* <div className="backdrop-opacity-[0.5] bg-blue_gray-100_04 blur-[24.00px] h-[51px] rounded-[267px] w-[77%]"></div> */}
                                    </div>
                                </div>
                                {/* img end  */}
                            </div>
                        </div>
                        {/* laptop right black  */}
                        <div className="flex flex-col items-center relative justify-start w-full">
                            <div className="bg-gray-900_01 flex md:flex-col sm:flex-col items-start justify-start max-w-full  md:px-10 px-20 sm:px-5 pt-[20px] w-full">
                                <Text
                                    className=" leading-[24.00px] text-gray-50 w-[60%] md:w-[100%] sm:w-full text-base sm:text-sm"
                                    size="txtInterRegular18"
                                >
                                    <>
                                        In today&#39;s rapidly evolving pharmaceutical and life
                                        science landscape, the paramount importance of quality and
                                        depth of content cannot be overstated.As the industrys quest
                                        for precision and innovation intensifies,so does the need
                                        for a cutting-edge electronic document system that
                                        transcends convention.
                                        <br />
                                        <br />
                                        Introducing DoQssence a revolutionary platform meticulously
                                        designed to address the intricate demands of the
                                        pharmaceutical and life science sector . In an era where
                                        information is not just power but also a matter of
                                        compliance , integrity , and reliability , DoQssence stands
                                        as the beacon of assurance.
                                        <br />
                                        <br />
                                        Our commitment to the life-cycle management of documents
                                        ensures that information remains not just relevant, but
                                        continuously optimized to meet the dynamic regulatory
                                        landscape. DoQssence doesn&#39;t just meet adequacy and
                                        regulatory expectations; it exceeds them, setting new
                                        standards for excellence in document management.
                                        <br />
                                        <br />
                                        With DoQssence, we embark on a journey to reshape the way
                                        the industry approaches documentation, ushering in a new era
                                        of precision, efficiency, and reliability. Welcome to a
                                        future where your document needs are not just met but
                                        exceeded, and where compliance is not a hurdle but a
                                        foundation for progress.
                                    </>
                                </Text>
                                <Img
                                    className="md:flex-1 h-[438px] md:h-[300px] sm:mb-0 sm:h-auto ml-[100px] md:ml-0 sm:ml-0 mt-[30px]   w-[380px] md:w-full"
                                    src="images/DoQssence.webp"
                                    alt="images/DoQssence.png"
                                />
                            </div>

                            {/* <div className="dmsindustry"></div> */}
                            <ProductsDMSChal3
                                className="bg-gray-900_01 flex relative flex-col md:gap-10 gap-[81px] items-start justify-center md:px-10 px-20 sm:px-5 pt-[20px] pb-[30px] sm:py-[80px] w-full md:w-full"
                                industrychallenOne={
                                    <Reveal keyframes={SlideFade} duration="700">
                                        <Text className="font-bold sm:text-[38px] md:text-[44px] sm:pl-[25px] font-brunoace text-[47px] text-gray-50 w-auto">
                                            <span className="text-gray-50  text-left">
                                                Industry{" "}
                                            </span>
                                            <span className="text-green-600  text-left">
                                                Challenges
                                            </span>
                                        </Text>
                                    </Reveal>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Applicatons Of DoQssence */}
            <div className="bg-blue_gray-50 flex flex-col font-inter items-start justify-start max-w-full md:px-10 px-20 sm:px-5 pt-[20px] w-full">
                <div className="flex flex-col md:gap-50 gap-[50px] items-start justify-start w-full md:w-full">
                    <Reveal keyframes={SlideFade} duration="700">
                        <Text
                            className="capitalize sm:text-[38px] font-brunoace md:text-[44px] text-[47px] text-gray-900 w-auto"
                            size="txtInterBold52"
                        >
                            <span className="text-gray-900 text-left font-bold">
                                Applicatons of{" "}
                            </span>
                            <span className="text-green-600 text-left font-bold">
                                DoQssence
                            </span>
                        </Text>
                    </Reveal>
                    <div className="flex flex-col items-start justify-start sm:justify-center w-full md:w-full">
                        <div className="gap-10 md:gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto]   md:w-full w-full">
                            <ProductsQMSStackOne className="  h-[250px] relative md:mb-10   w-full" />
                            <ProductsQMSStackcomplaints className=" h-[250px] relative w-full" />
                            <ProductsQMSStackoosoot className=" h-[250px]  relative md:mt-[25px] w-full" />
                            <ProductsQMSStackdocumentmanagemOne className=" h-[250px] relative mt-4  mb-5 sm:!mb-1 w-full" />
                            <ProductsQMSStackinvestigationi className=" h-[250px] relative mt-4 mb-5 sm:!mb-1 w-full" />
                            <ProductsQMSStackchangecontrol className=" h-[250px] relative mt-4 mb-5 w-full" />
                        </div>
                    </div>
                </div>
            </div>
            {/* dmsbenifits  */}
            {/* <List
                className="bg-gray-900_01 flex flex-col font-inter gap-[20px] relative items-start max-w-full md:px-10 px-20 sm:px-5 py-[20px] w-full"
                orientation="vertical"
            >
                <span id="dmsbenifits">
                    <i class="ri-seedling-line"></i>
                </span>
                <div className="dmsbenifits"></div>
                <div className="flex flex-1 flex-col md:gap-10 gap-[20px]  items-start justify-start max-w-[1352px] mx-auto my-0 w-full">
                    <div className="flex flex-col items-center justify-start">
                        <Reveal keyframes={SlideFade} duration="700">
                            <Text
                                className="capitalize sm:text-[38px] md:text-[44px] text-[47px] text-green-600"
                                size="txtInterBold52Green600"
                            >
                                <span className="font-brunoace">Benefits</span>
                            </Text>
                        </Reveal>
                    </div>
                    <div className="flex flex-col items-start justify-start w-auto md:w-full">
                        <div className="md:gap-10 gap-[60px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-start justify-between max-w-[1352px] w-full">
                            <ProductsDMSColumn className="bg-white-A700_14 relative flex px-[25px] py-[15px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]" />
                            <div className="bg-white-A700_14 flex  hover:bg-gradient-to-r easeofUseParent  from-transparent to-green-600_1e flex-col items-start justify-start px-[25px] py-[20px]    rounded-[24px] shadow-bs9 w-[316]">
                                <div className="flex flex-col gap-2 easeofUseChild items-center justify-start w-auto">
                                    <Img
                                        className="h-[114px] md:h-auto object-cover w-[114px]"
                                        src="images/EASE OF DOCUMENT archival.png"
                                        alt="maskgroup_One"
                                    />
                                    <Text
                                        className="leading-[40.00px] max-w-[268px] md:w-full text-2xl md:text-[22px] text-center md:w-full text-gray-50 sm:text-xl"
                                        size="txtInterRegular24Gray50"
                                    >
                                        Ease of document Archival / Retrieval
                                    </Text>
                                </div>
                            </div>
                            <ProductsDMSColumnTwo
                                className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex px-[25px] py-[38px] flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                                imageUrl="images/audit readiness 01.png"
                            />
                            <ProductsDMSColumnThree
                                className="bg-white-A700_14 flex hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col px-[25px] py-[38px] Quicksearch gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                                imageUrl="images/quick search and view.png"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-1 md:gap-10 gap-[60px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-start justify-between max-w-[1352px] mx-auto my-0 w-full">
                    <ProductsDMSColumn 
                        className="bg-white-A700_14 flex px-[25px] py-[15px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                        usertext="Low Cost Of Ownership"
                    />
                    <ProductsDMSColumnOne className="bg-white-A700_14 px-[25px] py-[38px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col gap-2.5 items-center justify-start rounded-[24px] shadow-bs9 w-[316]" />
                    <ProductsDMSColumnTwo
                        className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col gap-2.5 items-center px-[25px] py-[22px] deploymentGParent justify-start rounded-[24px] shadow-bs9 w-[316]"
                        speedofdeployme="Speed Of Deployment"
                        imageUrl="images/speed of deployment (1).png"
                    />
                    <ProductsDMSColumnThree
                        className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col px-[25px] py-[20px] gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                        usertext="Ease Of Use / Compliance"
                        imageUrl="images/ease of use compliance (1).png"
                    />
                </div>
            </List> */}
            <List
                className="bg-gray-900_01 flex flex-col font-inter gap-[20px] sm:gap-10 relative items-start max-w-full md:px-10 px-20 sm:px-5 py-[20px] w-full"
                orientation="vertical"
            >
                {/* ... (your existing code) ... */}
                <span id="dmsbenifits">
                    <i class="ri-seedling-line"></i>
                </span>
                <div className="dmsbenifits"></div>
                <div className="flex flex-col gap-6 items-start justify-start w-full md:w-full">
                <div className="flex flex-col items-center justify-start">
                        <Reveal keyframes={SlideFade} duration="700">
                            <Text
                                className="capitalize sm:text-[38px] md:text-[44px] text-[47px] text-green-600 sm:pl-5"
                                size="txtInterBold52Green600"
                            >
                                <span className="font-brunoace">Benefits</span>
                            </Text>
                        </Reveal>
                    </div>
                    <div className="md:gap-10 gap-[60px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-start justify-between max-w-full md:max-w-full w-full">
                        {/* ... (your existing code) ... */}
                        {/* <ProductsDMSColumn className="bg-white-A700_14 relative flex px-[25px] h-[255px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]" /> */}
                        <ProductsDMSColumn className="bg-white-A700_14 relative flex px-[29px] py-[15px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]" />

                        <div className="bg-white-A700_14 relative flex px-[25px] h-[255px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]">
                            <div className="flex flex-col gap-2 easeofUseChild items-center justify-start w-auto">
                                <Img
                                    className="h-[114px] md:h-auto object-cover w-[114px]"
                                    src="images/EASE OF DOCUMENT archival.png"
                                    alt="maskgroup_One"
                                />
                                <Text
                                    className="leading-[40.00px] max-w-[268px] md:w-full text-2xl md:text-[22px] text-center md:w-full text-gray-50 sm:text-xl"
                                    size="txtInterRegular24Gray50"
                                >
                                    Ease of document
                                    {/* Archival / Retrieval */}
                                </Text>
                            </div>
                        </div>

                        <ProductsDMSColumnTwo
                            className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex px-[25px] h-[255px]  flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                            imageUrl="images/audit readiness 01.png"
                        />
                        <ProductsDMSColumnThree
                            className="bg-white-A700_14 flex hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col px-[25px] h-[255px] Quicksearch gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                            imageUrl="images/quick search and view.png"
                        />
                        {/* ... (your existing code) ... */}
                    </div>
                </div>
                <div className="flex-1 md:gap-10 gap-[60px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-start justify-between max-w-full md:max-w-full w-full">
                    <ProductsDMSColumn
                        className="bg-white-A700_14 flex px-[25px] py-[15px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex-col gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[316]"
                        usertext="Low Cost Of Ownership"
                    />
                    {/* ... (your existing code) ... */}
                    <ProductsDMSColumnOne
                        className="bg-white-A700_14 px-[25px] h-[255px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col gap-2.5 items-center justify-start rounded-[24px] shadow-bs9 w-full md:w-[316]"
                    />
                    <ProductsDMSColumnTwo
                        className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col gap-2.5 items-center px-[25px] h-[255px] deploymentGParent justify-start rounded-[24px] shadow-bs9 w-[316]"
                        speedofdeployme="Speed Of Deployment"
                        imageUrl="images/speed of deployment (1).png"
                    />
                    <ProductsDMSColumnThree
                        className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col px-[25px] h-[255px] gap-2.5 items-center justify-center rounded-[24px] shadow-bs9 w-[310]"
                        usertext="Ease Of Use / Compliance"
                        imageUrl="images/ease of use compliance (1).png"
                    />
                    {/* ... (your existing code) ... */}
                </div>
            </List>

            <div className="bg-blue_gray-50 flex flex-col font-inter items-start justify-start md:px-10 px-10  sm:px-5 py-[20px] w-full  md:w-full">
                <div className="flex md:flex-col sm:flex-col md:gap-10 items-start justify-between max-w-full mx-auto w-full">
                    <Reveal keyframes={SlideFade} duration="700">
                        <Text
                            className="capitalize sm:text-[38px] font-brunoace md:text-[44px] text-[47px] text-green-600 w-auto"
                            size="txtInterBold52Green600"
                        >
                            Features
                        </Text>
                    </Reveal>
                    <List
                        className="flex-1 sm:flex-col flex-row md:gap-3 grid md:grid-cols-1 grid-cols-2 ml-20 sm:ml-0 dmsFeatures justify-between max-w-[full] w-full"
                        orientation="horizontal"
                    >
                        <div className="flex flex-1 flex-col gap-3 sm:h-auto items-start justify-start w-full">
                            <Reveal keyframes={slideUp} delay="50">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <i class="ri-file-text-line text-xl" style={{ color: "#458795" }}></i>

                                        </Button>
                                    </div>

                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Content development & review
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="60">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39776.png"
                                                alt="group39776"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Template management
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="70">
                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <i class="ri-database-2-fill text-xl" style={{ color: "#458795" }}></i>

                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize leading-[24.00px] md:max-w-full max-w-xs text-gray-900 text-xl md:text-lg sm:text-base"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Version control & revision history
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="80">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <i class="ri-user-2-fill text-xl" style={{ color: "#458795" }}></i>
                                            {/* <Img
                            className="h-7"
                            src="images/img_group39778.png"
                            alt="group39778"
                          /> */}
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Metadata & indexing
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="90">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39779.png"
                                                alt="group39779"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Advance search & retrieval
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="100">
                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <i class="ri-folder-5-fill text-xl" style={{ color: "#458795" }}></i>
                                            {/* <Img
                            className="h-7"
                            src="images/img_group39780.png"
                            alt="group39780"
                          /> */}
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize leading-[24.00px] md:max-w-full max-w-xs text-gray-900 text-xl md:text-lg sm:text-base"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Migartion of paper documents into e-DMS
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="110">
                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39781.png"
                                                alt="group39781"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize leading-[24.00px] md:max-w-full max-w-xs text-gray-900 text-xl md:text-lg sm:text-base"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Document workflow & collaboration
                                    </Text>
                                </div>
                            </Reveal>
                        </div>
                        <div className="flex sm:flex-1 flex-col gap-3 items-start justify-center w-auto sm:w-full">
                            <Reveal keyframes={slideUp} delay="50">
                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                src="images/img_group39782.png"
                                                alt="group39782"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize leading-[24.00px] md:max-w-full max-w-xs text-gray-900 text-xl md:text-lg sm:text-base"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Document security & access control
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="60">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                src="images/img_group39783.png"
                                                alt="group39783"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Print control
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="70">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                src="images/img_group39784.png"
                                                alt="group39784"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        User management & control
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="80">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                src="images/audit trial capabilities.png"
                                                alt="group39785"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Audit trail capabilities
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="90">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39786.png"
                                                alt="group39786"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Governance & advance analytics
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="100">
                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39787.png"
                                                alt="group39787"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize leading-[24.00px] md:max-w-full max-w-xs text-gray-900 text-xl md:text-lg sm:text-base"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Configuration management / retrieval
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="110">
                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <div className="name">
                                        <Button
                                            className="flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <Img
                                                className="h-7"
                                                src="images/img_group39788.png"
                                                alt="group39788"
                                            />
                                        </Button>
                                    </div>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-lg sm:text-base w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Mobile accessibility
                                    </Text>
                                </div>
                            </Reveal>
                        </div>
                    </List>
                </div>
            </div>
            <div className="bg-gray-900_01  flex  flex-col  font-inter relative md:gap-10 gap-[50px] items-start justify-start max-w-full md:px-10 px-20 py-[40px] sm:px-5 sm:w-full md:w-full sm:pt-[20px] pt-[20px] w-full">
                <span id="dmsufo">
                    <i class="ri-key-2-line"></i>
                </span>
                <div className="dmsufo"></div>
                <div className="flex sm:flex-col h-[auto] sm:h-auto items-center justify-start">
                    <Reveal keyframes={SlideFade} duration="700">
                        <Text
                            className="capitalize sm:text-[38px] sm:pl-[10px] font-brunoace md:text-[44px] text-[47px] text-green-600"
                            size="txtInterBold52Green600"
                        >
                            <span className="text-green-600 text-left font-bold">
                                Unique{" "}
                            </span>
                            <span className="text-gray-50 text-left font-bold">
                                features
                            </span>
                        </Text>
                    </Reveal>
                </div>
                <div className="gap-10 md:gap-5 sm:gap-y-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto]   md:w-full w-full">
                    <div className="md:h-[400px] sm:h-[280px] sm:mb-[30px] h-[250px] relative   w-full">
                        <div className="card">
                            <div className="content relative">
                                <div className="front border  border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px] sm:py-[20px] md:p-[20px]  rounded-[24px] w-[full]  md:w-full">
                                    <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center py-10 rounded-[12px] w-full">
                                        <Img
                                            className="h-16 md:h-auto object-cover w-16"
                                            src="images/Document Revision.png"
                                            alt="imageTwelve"
                                        />
                                        <Text
                                            className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                            size="txtInterRegular24"
                                        >
                                            Document Revision Not Urgent?
                                        </Text>
                                    </div>
                                </div>
                                <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                                    <Text
                                        className="leading-[20.00px] text-sm bg-green-600 text-center max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                        size="txtInterRegular18"
                                    >
                                        Use &quot;Feedback And Content Tweaks (FACT)” feature
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:h-[400px] sm:h-[280px] sm:mb-[30px]  h-[250px] relative   w-full">
                        <div className="card">
                            <div className="content relative">
                                <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px] md:py-[5px] sm:py-[20px] rounded-[24px] w-[full]  md:w-full">
                                    <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center  py-10 rounded-[12px] w-full">
                                        <Img
                                            className="h-16 md:h-auto object-cover w-16"
                                            src="images/worried About unintended removal of regulatory.png"
                                            alt="imageTwelve"
                                        />
                                        <Text
                                            className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                            size="txtInterRegular24"
                                        >
                                            Worried About Unintended Removal Of Regulatory ?
                                        </Text>
                                    </div>
                                </div>
                                <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                                    <Text
                                        className="leading-[20.00px] text-center text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                        size="txtInterRegular18"
                                    >
                                        Use &quot;Commitment Assurance Tag (CAT)” feature
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:h-[400px] sm:h-[280px]  h-[250px] relative   w-full">
                        <div className="card">
                            <div className="content relative">
                                <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px] sm:py-[20px] md:py-[5px] rounded-[24px] w-[full]  md:w-full">
                                    <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center  py-10 rounded-[12px] w-full">
                                        <Img
                                            className="h-16 md:h-auto object-cover w-16"
                                            src="images/Difficult to manage definations & their reduncies.png"
                                            alt="imageTwelve"
                                        />
                                        <Text
                                            className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                            size="txtInterRegular24"
                                        >
                                            Difficult To Manage Definitions & Their
                                            Redundancies?
                                        </Text>
                                    </div>
                                </div>
                                <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                                    <Text
                                        className="leading-[20.00px] text-sm text-center bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                        size="txtInterRegular18"
                                    >
                                        Use “Lexicon of Critical Knowledge (LOCK)
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gap-10 md:gap-5 sm:gap-y-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-2 justify-center min-h-[auto] mt-5 sm:!mt-0  md:w-full w-full">
                    <div className="md:h-[400px] sm:h-[280px]  h-[250px] relative   w-full">
                        <div className="card">
                            <div className="content relative">
                                <div className="front border  border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px] md:py-[5px] sm:py-[20px] rounded-[24px] w-[full]  md:w-full">
                                    <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center py-10 rounded-[12px] w-full">
                                        <Img
                                            className="h-16 md:h-auto object-cover w-16"
                                            src="images/Missout on critical inputs on training.png"
                                            alt="imageTwelve"
                                        />
                                        <Text
                                            className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                            size="txtInterRegular24"
                                        >
                                            Missing Out On Critical Inputs For Training?
                                        </Text>
                                    </div>
                                </div>
                                <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                                    <Text
                                        className="leading-[20.00px] text-sm bg-green-600 text-center max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                        size="txtInterRegular18"
                                    >
                                        Use “Training Assistance Program (TAP) feature
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:h-[400px] sm:h-[280px]  h-[250px] relative   w-full">
                        <div className="card">
                            <div className="content relative">
                                <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px] md:py-[15px]  rounded-[24px] w-[full]  md:w-full">
                                    <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center  py-10 rounded-[12px] w-full">
                                        <Img
                                            className="h-16 md:h-auto object-cover w-16"
                                            src="images/Difficulty in finding contextual content.png"
                                            alt="imageTwelve"
                                        />
                                        <Text
                                            className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                            size="txtInterRegular24"
                                        >
                                            Difficulty In Finding Contextual Content?
                                        </Text>
                                    </div>
                                </div>
                                <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                                    <Text
                                        className="leading-[20.00px] text-center text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                        size="txtInterRegular18"
                                    >
                                        Use Contextual intelligent search feature
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
