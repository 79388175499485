import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackinvestigationi = (props) => {
  return (
    <>
      <div className={props.className}>
        {/* <div className="card">
                    <div className="content">
                        <div className="front border border-green-600 border-solid   flex flex-col md:h-auto items-center justify-center m-auto  py-[46px]  rounded-[24px] w-[full] md:w-full">
                            <div className="flex flex-col gap-[20px] sm:gap-y-5  justify-start w-[auto] bg-transparent">
                                <Img
                                    className="h-20 md:h-auto md:ml-[0] mx-auto  w-20"
                                    src="images/img_maskgroup_13.png"
                                    alt="imageTwelve"
                                />
                                <Text
                                    className="text-2xl md:text-[22px] text-center mb-[20px] text-gray-900 sm:text-xl"
                                    size="txtInterRegular24"
                                >
                                    {props?.userprocess}
                                </Text>
                            </div>
                        </div>
                        <div className="absolute back bg-green-600 px-[10px]  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                            <Text
                                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                size="txtInterRegular18"
                            >
                                <li>Investigation workflow</li>
                                <li>Immediate assessment &nbsp;&nbsp;&nbsp;&nbsp;template</li>
                                <li>Investigation plan template</li>
                                <li>Investigation tool selection &nbsp;&nbsp;&nbsp;&nbsp;toolkit</li>
                                <li>Collaborative review and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;approval</li>
                                <li>Assisted conclusion and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;classification</li>
                            </Text>
                        </div>
                    </div>
                </div> */}
        <div className="card h-full">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col gap-6 justify-start w-[full] bg-transparent">
                <Img
                  className="h-20 md:h-auto md:ml-[0] mx-auto  w-20"
                  src="images/img_maskgroup_13.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] mb-[20px] ml-[18px] text-gray-900 sm:text-xl"
                  size="txtInterRegular24"
                >
                  {props?.userprocess}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>Investigation workflow</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Immediate assessment template
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Investigation plan template
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Investigation tool selection toolkit
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Collaborative review and approval
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Assisted conclusion and classification
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackinvestigationi.defaultProps = {
  userprocess: <>Investigation Reports</>,
};

export default ProductsQMSStackinvestigationi;
