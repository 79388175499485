import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackvendorquality = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="card md:h-[300px]">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col justify-start w-[50%] bg-transparent rounded-[24px] ">
                <Img
                  className="h-35 md:h-auto md:ml-[0] object-cover w-50"
                  src="images/Quality Culture.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] mb-[20px] ml-[18px] text-gray-900 sm:text-lg"
                  size="txtInterRegular24"
                >
                  {props?.vendorquality}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] rounded-[24px]  sm:text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-base md:text-sm"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    Vender Capability assessment
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Vender Documentation package
                  </li>
                  <li style={{ lineHeight: "1.5" }}>Vender audit & approval</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Vender performance management
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Tachnical agreement tracking & repository
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated vender complaint management
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated vender surveilance
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackvendorquality.defaultProps = {
  // description: (
  //   <>
  //     Deviation reporting and immediate actions
  //     <br />
  //     Correction, QA review & approval
  //     <br />
  //     Initial risk classification and escalation
  //     <br />
  //     Investigation and root cause determination
  //     <br />
  //     Final classification and escalation <br />
  //     QA review and final disposition
  //   </>
  // ),
  vendorquality: "Vendor Quality",
};

export default ProductsQMSStackvendorquality;
