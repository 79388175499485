import React from "react";

const sizeClasses = {
  txtInterRegular60: "font-inter font-normal",
  txtInterRegular20: "font-inter font-normal",
  txtAvenirRoman12: "font-avenir font-normal",
  txtInterBold28: "font-bold font-inter",
  txtInterBold32WhiteA700: "font-bold font-inter",
  txtBrunoAceRegular52: "font-brunoace font-normal",
  txtOpenSansRegular8: "font-normal font-opensans",
  txtInterBold24: "font-bold font-inter",
  txtInterBold24Gray90001: "font-bold font-inter",
  txtInterBold20: "font-bold font-inter",
  txtBrunoAceRegular18: "font-brunoace font-normal",
  txtInterBold18Gray900: "font-bold font-inter",
  txtBrunoAceRegular16: "font-brunoace font-normal",
  txtInterRegular16Gray90001: "font-inter font-normal",
  txtInterRegular24: "font-inter font-normal",
  txtInterRegular8: "font-inter font-normal",
  txtInterRegular28: "font-inter font-normal",
  txtDMSansRegular28: "font-dmsans font-normal",
  txtInterRegular24Gray90001: "font-inter font-normal",
  txtInterBold18: "font-bold font-inter",
  txtInterBold40Gray900: "font-bold font-inter",
  txtInterRegular18Gray90001: "font-inter font-normal",
  txtInterBold24Gray50: "font-bold font-inter",
  txtInterBold40Gray50: "font-bold font-inter",
  txtDMSansBold18: "font-bold font-dmsans",
  txtInterRegular20Black900: "font-inter font-normal",
  txtOpenSansRegular12: "font-normal font-opensans",
  txtOpenSansRegular10: "font-normal font-opensans",
  txtInterBold52: "font-bold font-inter",
  txtInterRegular20Gray900: "font-inter font-normal",
  txtInterBold52Gray800: "font-bold font-inter",
  txtInterRegular18WhiteA700: "font-inter font-normal",
  txtInterBold52Gray90001: "font-bold font-inter",
  txtInterRegular12: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtDMSansBold52: "font-bold font-dmsans",
  txtInterBold52Gray50: "font-bold font-inter",
  txtInterBold28Gray90001: "font-bold font-inter",
  txtInterRegular16: "font-inter font-normal",
  txtAvenirRoman1519: "font-avenir font-normal",
  txtInterRegular18: "font-inter font-normal",
  txtInterRegular18Gray900: "font-inter font-normal",
  txtInterRegular14Gray90001: "font-inter font-normal",
  txtInterRegular20Gray90001: "font-inter font-normal",
  txtInterBold52Green600: "font-bold font-inter",
  txtInterBold40: "font-bold font-inter",
  txtDMSansBold20: "font-bold font-dmsans",
  txtInterBold52WhiteA700: "font-bold font-inter",
  txtInterRegular18Gray50cc: "font-inter font-normal",
  txtBrunoAceRegular60Gray50: "font-brunoace font-normal",
  txtInterRegular16WhiteA700: "font-inter font-normal",
  txtBrunoAceRegular2025: "font-brunoace font-normal",
  txtBrunoAceRegular60: "font-brunoace font-normal",
  txtOpenSansBold14: "font-bold font-opensans",
  txtOpenSansRegular12Gray50002: "font-normal font-opensans",
  txtInterBold32: "font-bold font-inter",
  txtInterRegular24Gray50: "font-inter font-normal",
  txtBrunoAceRegular52Gray90001: "font-brunoace font-normal",
  txtInterBold32Gray90001: "font-bold font-inter",
  txtDMSansRegular18: "font-dmsans font-normal",
};


const renderDetails = (item) => {
  const sentences = item?.split('.');
  const renderParagraph = () => {
    return sentences?.map((sentence, index) => (
      <React.Fragment key={index}>
        {/* {sentence} */}
        <div dangerouslySetInnerHTML={{ __html: sentence }} />
        {(index + 1) % 2 === 0 && index !== sentences?.length - 1 && <> <br /> <br /> </>}
      </React.Fragment>
    ));
  };

  return <div>{renderParagraph()}</div>;
};

const TextWithReadMore = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";
  const [showFullText, setShowFullText] = React.useState(false);
  const textContent = typeof children === 'string' ? children : String(children);
  const truncatedText = showFullText ? textContent : textContent.slice(0, 710);
  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const renderTextContent = () => {
    if (showFullText) {
      return renderDetails(textContent);

      // return <div dangerouslySetInnerHTML={{ __html: textContent }} />;
    } else {
      return renderDetails(truncatedText);
    }
  };

  return (
    <div>
      <Component
        className={`text-left ${className} ${size && sizeClasses[size]}`}
        {...restProps}
      >
        {renderTextContent()}
      </Component>
      {textContent.length > 150 && (
        <button onClick={toggleReadMore} className="text-blue-500 underline">
          {showFullText ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export { TextWithReadMore };