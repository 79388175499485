import React from "react";
import "./Qmsfda.css";
import { Img, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const Qmsfdavision = (props) => {
  return (
    <>
      <Reveal keyframes={slideUp} delay="70">
        <div className={`${props.className} `}>
          <div className="card1">
            <div className="content relative">
              <div className="front border border-green-600 border-solid   flex flex-col md:h-auto  items-center justify-center m-auto px-3.5 py-[46px]  rounded-[20px] w-[full]  md:w-full">
                <div className="flex flex-col  justify-center w-[50%] bg-transparent  rounded-[20px]">
                  <Reveal keyframes={slideUp} delay="130">
                    <Text
                      className="bg-gray-900_01 flex h-[60px] sm:ml-[20px] ml-[40px] items-center justify-center sm:px-5 rounded-[50%] text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl tracking-[0.48px] w-[58px]"
                      size="txtInterBold24Gray50"
                    >
                      1
                    </Text>
                  </Reveal>
                  <Text
                    className="text-2xl  mt-[10px] ml-[-25px] w-[180px] sm:text-xl"
                    size="txtInterRegular24"
                  >
                    {props?.deviationtext12}
                  </Text>
                </div>
              </div>
              <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                {/* <Text
                  className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] sm:w-[400px] sm:p-[20px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                  size="txtInterRegular18"
                >
                  <li>QMS objective:</li>
                  <li>
                    Correction, QA review & &nbsp;&nbsp;&nbsp;&nbsp; approval
                  </li>
                  <li>Product realization</li>
                  <li>
                    Establish and maintain a state of &nbsp;&nbsp;&nbsp;&nbsp;
                    control.
                  </li>{" "}
                  <li>
                    Final classification and <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;escalation.
                  </li>
                  <li>Facilitate continual improvement</li>
                  <li>
                    The key enablers for achieving these
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; objectives are.
                  </li>
                  <li>
                    Process performance and product
                    <br />
                    &nbsp; &nbsp; quality monitoring system
                  </li>
                  <li>Management review</li>
                  
                </Text> */}
                <Text
                  className="leading-[20.00px]  rounded-[20px] text-sm bg-green-600 max-w-[272px] sm:w-[400px] sm:p-[20px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                  size="txtInterRegular18"
                >
                  <ul className="list-disc pl-5" style={{ lineHeight: "1.5" }}>
                    <li style={{ lineHeight: "1.5" }}>QMS objective:</li>
                    <li style={{ lineHeight: "1.5" }}>
                      Correction, QA review & approval
                    </li>
                    <li style={{ lineHeight: "1.5" }}>Product realization</li>
                    <li style={{ lineHeight: "1.5" }}>
                      Establish and maintain a state of control.
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Final classification and escalation.
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Facilitate continual improvement
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      The key enablers for achieving these objectives are.
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Process performance and product quality monitoring system
                    </li>
                    <li style={{ lineHeight: "1.5" }}>Management review</li>
                  </ul>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

Qmsfdavision.defaultProps = {
  deviationtext12: (
    <Img
      className="h-[180px] md:h-[200px]   sm:h-[200px]  w-[300px] sm:w-[250px] "
      src="images/img_image4.png"
      alt="imagesubmiss"
    />
  ),
};

export default Qmsfdavision;
