import React from 'react'
import Footer from '../Main/Footer/index'
import { Outlet } from 'react-router-dom'
import MobileHeader from "./Header/mobile";

export default function Main() {
    return (
        <div>
            <MobileHeader />
            <Outlet />
            <Footer />
        </div>
    )
}
