import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Main/HOMEPAGE/index";
import NotFound from "pages/NotFound";
import RequestdemoPage from "pages/Requestdemo";
import Main from "./Main/Main";
import ABOUTUS from "./Main/ABOUTUS/index";
import Products from "./Main/Products/index";
import Services from "./Main/Services/index";
import DMS from "Main/Products/DMS";
import LMS from "Main/Products/LMS";
import QMS from "Main/Products/QMS";


const TalktoExperts = React.lazy(() => import("pages/TalktoExpert"));
const PrivacyStatement = React.lazy(() => import("pages/PrivacyStatement"));
const Terms = React.lazy(() => import("pages/Terms"));
// const Services = React.lazy(() => import("Main/Services"));
const Blog = React.lazy(() => import("pages/Blog"));
const BlogDetails = React.lazy(() => import("pages/BlogDetails"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<></>}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} >
            <Route path="/" element={<Home />} />
            <Route path="aboutus" element={<ABOUTUS />} />
            <Route path="products" element={<Products />} />
            <Route path="services" element={<Services />} />
            <Route path="blog" element={<Blog />} />
            <Route path="/talktoexperts" element={<TalktoExperts />} />
            <Route path="/blog_details/:blog_id" element={<BlogDetails />} />
            <Route path="/requestdemo" element={<RequestdemoPage />} />
            <Route path="/DMS" element={<DMS />} />
            <Route path="/LMS" element={<LMS/>} />
            <Route path="/QMS" element={<QMS/>} />

          </Route>

          <Route path="*" element={<NotFound />} />
          <Route path="/privacy" element={<PrivacyStatement />} />
          <Route path="/terms" element={<Terms />} />

        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
