import React from "react";

import { Img, Text } from "components";

const ProductsDMSColumn = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-8 items-center  justify-center w-[244px]">
          <Img
            className="h-[114px] md:h-auto object-cover w-[114px]"
            src="images/low-cost of  ownership (1).png"
            alt="maskgroup"
          />
          <Text
            className="leading-[40.00px] max-w-full ALCOA text-center md:max-w-full text-2xl md:text-[22px]  text-gray-50 sm:text-xl"
            size="txtInterRegular24Gray50"
          >
            {props?.usertext}
          </Text>
        </div>
      </div>
    </>
  );
};

ProductsDMSColumn.defaultProps = {
  usertext: (
    <>
      Assurance On ALCOA plus
    </>
  ),
};

export default ProductsDMSColumn;
