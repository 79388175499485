import React from "react";

import { Img, Text } from "components";

const ProductsDMSColumnThree = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-8 items-center justify-start w-auto">
          <Img
            className="h-[114px] md:h-auto object-cover w-[114px]"
            src={props.imageUrl}
            alt="maskgroup_Three"
          />
          <Text
            className="text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl w-auto"
            size="txtInterRegular24Gray50"
          >
            {props?.usertext}
          </Text>
        </div>
      </div>
    </>
  );
};

ProductsDMSColumnThree.defaultProps = { usertext: "Quick Search & View" };

export default ProductsDMSColumnThree;
