import React from "react";
import PropTypes from "prop-types";

const shapes = { round: "rounded-[12px]", circle: "rounded-[50%]" };
const variants = {
  gradient: {
    teal_900_01_green_800_01: "bg-gradient  text-gray-50",
    gray_900_08_green_900_01: "bg-gradient4  text-gray-50",
    indigo_800_green_600: "bg-gradient2  text-gray-50",
  },
  outline: {
    gray_500: "border border-gray-500 border-solid",
    gray_50: "border border-gray-50 border-solid",
    green_600: "border border-green-600 border-solid",
  },
  fill: {
    green_600: "bg-green-600 shadow-bs1 text-gray-50",
    white_A700_14: "bg-white-A700_14 text-gray-50",
  },
};
const sizes = {
  xs: "py-px",
  sm: "p-1.5",
  md: "p-[9px]",
  lg: "p-[13px]",
  xl: "p-[23px] sm:px-5",
  "2xl": "p-[27px] sm:px-5",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round", "circle"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "2xl"]),
  variant: PropTypes.oneOf(["gradient", "outline", "fill"]),
  color: PropTypes.oneOf([
    "teal_900_01_green_800_01",
    "gray_900_08_green_900_01",
    "indigo_800_green_600",
    "gray_500",
    "gray_50",
    "green_600",
    "white_A700_14",
  ]),
};

export { Button };
