import React, {  useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Img, List, Text } from "components";
import HOMEPAGEAspire from "components/HOMEPAGEAspire";
import HOMEPAGEChal from "components/HOMEPAGEChal";
import HOMEPAGEColumnwarningletters from "components/HOMEPAGEColumnwarningletters";
import HOMEPAGEComp from "components/HOMEPAGEComp";
import HOMEPAGEDoqssence from "components/HOMEPAGEDoqssence";
import HOMEPAGEElevate from "components/HOMEPAGEElevate";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./cards.css";
import { Fade, Reveal, Zoom } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;

const HOMEPAGEPage = () => {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline();
    tl.to(".dmsLine", {
      height: 350,
      scrollTrigger: {
        scrub: 2,
        trigger: ".dmsLine",
        start: "top 80%",
        end: "top 20%",
        toggleActions: "play",
      },
    })
      .to(".lmsLine", {
        height: 350,
        scrollTrigger: {
          scrub: 2,
          trigger: ".lmsLine",
          start: "top 80%",
          end: "top 20%",
          toggleActions: "play",
        },
      })
      .to(".qmsLine", {
        height: 340,
        scrollTrigger: {
          scrub: 2,
          trigger: ".qmsLine",
          start: "top 80%",
          end: "top 20%",
          toggleActions: "play",
        },
      })
      .to(".indus", {
        height: 450,
        scrollTrigger: {
          scrub: 2,
          trigger: ".indus",
          start: "top 80%",
          end: "top 20%",
          toggleActions: "play",
        },
      })
      .to(".diffHome", {
        height: 550,
        scrollTrigger: {
          scrub: 5,
          trigger: ".diffHome",
          start: "top 80%",
          end: "top 20%",
          toggleActions: "play",
        },
      });
  }, []);

  return (
    <div className="bg-white-A700 flex flex-col font-inter items-center overflow-x-hidden  justify-start mx-auto md:w-full w-full">
      <div className=" relative w-full md:w-full landingDiv">
        <Img
          className=" m-auto sm:object-cover  object-cover w-full landingImage"
          src="images/img_globetechnolog.png"
          alt="globetechnolog"
        />
        <div className="absolute bottom-[5%] flex  flex-row  md:gap-[45px] items-center justify-between left-[2%]  w-full landingpara">
          <div className=" flex flex-col items-center sm:w-[92%] relative  justify-start">
            <div className=" flex flex-col md:gap-10 gap-[60px]  items-start  justify-start w-full md:w-full ml-[30px] mb-[20px] homepara">
              <div className="flex flex-col  gap-6 sm:gap-5 items-start  justify-start w-full md:w-full">
                <Text
                  className="leading-[48.00px] sm:text-[24px]  md:text-[35px] text-[44px] text-gray-900"
                  size="txtInterBold52"
                >
                  <span className="text-gray-900 font-inter text-left font-bold">
                    <>
                      <Fade cascade damping={3e-2} className="head">
                        Your Partner For Excellence
                      </Fade>
                      <br />
                    </>
                  </span>
                  <span className="md:text-[30px] sm:text-[17px]  text-gray-900 tracking-[0.20px]  font-inter text-left text-[35px] font-bold">
                    <>
                      <Fade cascade damping={1e-1} className="head">
                        <div className="sm:flex-wrap">
                          Masterful IT Products For Life-Science Industry
                        </div>
                      </Fade>
                    </>
                  </span>
                </Text>

                <Text
                  className="leading-[32.00px] max-w-[915px] md:max-w-[95%] text-xl md:text-lg sm:text-sm  text-gray-900 "
                  size="txtInterRegular24"
                >
                  <Zoom>
                    <span>
                      Fulfil your requirements to achieve a high level of
                      compliance & efficiency using a highly-integratable &
                      scalable IT platform.
                    </span>
                  </Zoom>
                </Text>
              </div>

              <div className="flex flex-col items-center justify-start w-auto">
                <Reveal keyframes={SlideFade} duration="700">
                  <div className="head">
                    <Button
                      className="common-pointer cursor-pointer flex items-center justify-center py-[18px] min-w-[210px] rounded-[36px]"
                      rightIcon={
                        <Img
                          className="h-6 mb-px ml-2"
                          src="images/img_arrowright.svg"
                          alt="arrow_right"
                        />
                      }
                      size="xl"
                      variant="gradient"
                      color="indigo_800_green_600"
                    >
                      <div className="font-bold text-center text-lg">
                        <Link to="/talktoexperts">Request Demo</Link>
                      </div>
                    </Button>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="qssenceTwo"
        className="bg-gray-900_01 flex flex-col md:gap-10 gap-x-[60px] gap-y-[10px] pb-5  items-start justify-start md:px-10 md:py-10  sm:px-5 sm:py-5 w-full md:w-full"
      >
        {/* QSSENCE ideates, */}
        <div className="flex md:flex-col relative sm:flex-col px-[60px] md:px-0 sm:px-0  md:gap-10 items-center justify-between max-w-[full] mx-auto w-full">
          <Text
            className="leading-[28.00px] text-gray-50 text-lg"
            size="txtInterRegular20"
          >
            <span className="md:text-3xl sm:text-[28px] text-green-600 font-brunoace uppercase text-left text-[28px] font-normal">
              Qssence{" "}
            </span>
            <span className="text-gray-50 font-inter text-left font-normal">
              <>
                ideates, develops and sells IT products that fulfils the Pharma,
                Life-science and allied industry requirements to achieve high
                degree of reliability, compliance, capability and agility.
                <br />
                <br />
                Our products are intended to be the backbone of organizations
                aiming to stay ahead of the curve in the era of ever increasing
                business and regulatory complexities.
              </>
            </span>
          </Text>
          <div className="h-[280px] w-[500px] md:w-full sm:w-full md:mx-auto md:flex md:items-center md:justify-center lg:my-10 sm:mx-auto mx-auto ">
            <Img
              className="md:flex h-[250px] mb-[20px] sm:mb-0 sm:h-auto sm:ml-[-25px] md:ml-[-35px] w-[358px] md:mt-8 md:w-[300px] "
              src="images/DoQssence.webp"
              alt="images/DoQssence.png"
            />
          </div>
        </div>

        <div className="flex flex-col md:gap-10 gap-[60px] items-start justify-start max-w-[full] mx-auto w-full">
          <Text
            className="max-w-[1352px] md:max-w-full md:text-3xl pl-16 sm:pl-0 md:pl-0 sm:text-[28px] text-[28px] text-gray-50 tracking-[0.32px] w-full"
            size="txtInterBold32"
          >
            Game-Changing Tools Fueling Success For Pharma And Life Sciences
          </Text>
          <List
            className="flex flex-col gap-[60px] items-center px-[60px] md:px-0 sm:px-0 sm:w-full w-full"
            orientation="vertical"
          >
            {/* this comes in home page  */}
            <HOMEPAGEDoqssence className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col relative gap-5 items-start justify-start max-w-[full] my-0 md:pr-10 sm:pr-5 px-[72px] pt-4 pb-2 sm:py-10 rounded-[24px] w-full" />
            <HOMEPAGEAspire className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col relative  gap-5 items-start justify-start max-w-[full] my-0 md:pr-10 sm:pr-5 px-[72px] pt-4 pb-3 sm:py-10 rounded-[24px] w-full" />
            <HOMEPAGEElevate className="bg-white-A700_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col relative gap-5 items-start justify-start max-w-[full] my-0 md:pr-10 sm:pr-5 px-[72px] pt-4 pb-3 sm:py-10 rounded-[24px] w-full" />
          </List>
        </div>
      </div>
      {/* 2nd screen end*/}
      {/* Compliance Pitfalls */}
      {/* ---------------------------------------------------------- */}
      <div className="h-full relative w-full">
        <div className="bg-blue_gray-100_02 pb-5 sm:py-5 flex flex-col h-auto sm:h-full items-center justify-end max-w-full w-full sm:w-full">
          <div className="flex flex-col md:gap-10 gap-[60px] items-end justify-start w-full md:w-full">
            {/* Recent Compliance Stats */}
            <div className="flex flex-col md:gap-10 gap-[10px] items-center justify-start w-full md:w-full ">
              <div className="overflow-hidden flex flex-col gap-10 inset-x-[0] items-center justify-start mx-auto py-[10px] sm:py-[60px] top-[0] w-full md:w-full">
                <div className="flex flex-col items-start justify-start max-w-full md:px-10 px-20 sm:px-5 w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <div className="head">
                      <Text
                        className="sm:text-3xl md:text-[38px] text-[35px] text-gray-900_01 tracking-[0.20px] w-[382px]"
                        size="txtInterBold40"
                      >
                        <span className="text-green-600 font-inter text-left font-bold">
                          Compliance
                        </span>
                        &nbsp;
                        <span>Pitfalls</span>
                      </Text>
                    </div>
                  </Reveal>
                </div>
                <HOMEPAGEChal className="bg-indigo-800_14 flex flex-col  items-center justify-start w-full" />
              </div>
              <div className="flex flex-col items-center justify-start w-full md:w-full">
                <List
                  className="sm:flex-col flex-row md:gap-10 grid sm:px-[10px] sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-between max-w-full md:w-full w-full"
                  orientation="horizontal"
                >
                  <div className="flex flex-col md:gap-10 gap-16 items-start justify-start  w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="101"
                    />
                  </div>
                  <div className="flex flex-col md:gap-10 gap-16 items-start justify-start w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="50+"
                      warningletterstext="Facilities added to FDA import alerts"
                    />
                  </div>
                  <div className="flex flex-col md:flex-col md:gap-100 gap-16 items-start justify-start w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="50+"
                      warningletterstext="Additional warning letters issued by FDA"
                    />
                  </div>{" "}
                  <div className="flex flex-col md:flex-col md:gap-100 gap-16  items-start justify-start mt-[20px] md:mt-[0] w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="343+"
                      warningletterstext="Drug recalls in 1500+ products in U.S."
                    />
                  </div>{" "}
                  <div className="flex flex-col md:flex-col md:gap-100 gap-16 items-start justify-start mt-[20px] md:mt-[0] w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="2+"
                      warningletterstext="Consent decrees of permanent injunction obtained for quality violations"
                    />
                  </div>{" "}
                  <div className="flex flex-col md:flex-col md:gap-100 gap-16 items-start justify-start mt-[20px] md:mt-[0] w-auto">
                    <HOMEPAGEColumnwarningletters
                      className="flex flex-col gap-5 items-center justify-start w-[100%]"
                      onehundredone="62+"
                      warningletterstext="Manufacturers inspected have data integrity deficiencies as per survey by PICS"
                    />
                  </div>
                </List>
              </div>
            </div>
          </div>
          <span className="text-black-900 mt-[20px]">
            Recent Compliance Stats
          </span>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      {/* <div className=" h-[650px] md:h-[1076px] sm:h-auto  relative  w-full sm:w-full  md:w-full">
                <div className="bg-blue_gray-100_02  pb-5 sm:py-5 flex flex-col h-auto sm:h-full items-center justify-end  max-w-full w-full sm:w-full">
                    <div className="flex flex-col md:gap-10 gap-[60px] items-end justify-start w-full md:w-full">
                        <div className="flex flex-col md:gap-10 gap-[10px] items-center justify-start w-full md:w-full ">
                            <div className="overflow-hidden flex flex-col gap-10 inset-x-[0] items-center justify-start mx-auto py-[10px] sm:py-[60px] top-[0] w-full md:w-full">
                                <div className="flex flex-col items-start justify-start max-w-full md:px-10 px-20 sm:px-5 w-full">
                                    <Reveal keyframes={SlideFade} duration="700">
                                        <div className="head">
                                            <Text
                                                className=" sm:text-4xl md:text-[38px] text-[35px] text-gray-900_01 tracking-[0.20px] w-[382px]"
                                                size="txtInterBold40"
                                            >
                                                <span className="text-green-600 font-inter text-left font-bold">
                                                    Compliance
                                                </span>
                                                &nbsp;
                                                <span>Pitfalls</span>
                                            </Text>
                                        </div>
                                    </Reveal>
                                </div>
                                <HOMEPAGEChal className="bg-indigo-800_14 flex flex-col  items-center justify-start w-full" />
                            </div>
                            <div className="flex flex-col  items-center justify-start w-full md:w-full">
                                <List
                                    className="sm:flex-col  flex-row md:gap-10 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-between max-w-full md:w-full w-full"
                                    orientation="horizontal"
                                >
                                    <div className="flex flex-col md:gap-10 gap-16 items-start justify-start w-auto">
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="101"
                                        />
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="343"
                                            warningletterstext="Drug recalls in 1500+ products in U.S. *"
                                        />
                                    </div>
                                    <div className="flex flex-col md:gap-10 gap-16 items-start justify-start w-auto">
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="50+"
                                            warningletterstext="Facilities added to FDA import alerts *"
                                        />
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="2"
                                            warningletterstext="Consent decrees of permanent injunction obtained for quality violations *"
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-col md:gap-100 gap-16 items-start justify-start w-auto">
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="50+"
                                            warningletterstext="Additional warning letters issued by FDA *"
                                        />
                                        <HOMEPAGEColumnwarningletters
                                            className="flex flex-col gap-5 items-center justify-start w-[100%]"
                                            onehundredone="62%"
                                            warningletterstext="Manufacturers inspected have data integrity deficiencies as per survey by PICS"
                                        />
                                    </div>
                                     
                                </List>
                            </div>
                        </div>
                    </div>
                    <span className="text-black-900">Recent Compliance Stats</span>
                </div>
            </div>  */}
      {/* Industry Shortcomings */} {/* Qssence Differentiators */}
      <div className="flex flex-col items-start justify-start w-full sm:w-full md:w-full ">
        {/* Industry Shortcomings */}
        <div className=" flex relative flex-col gap-[-4px] items-center justify-start w-full md:w-full">
          <div className="bg-gray-900_01 flex flex-col items-center justify-center max-w-full plb-[60px] pt-[20px] md:px-10 px-20 sm:pr-5 w-full">
            <img id="indus" src="./indus.png" alt="industry shortcoming" />
            <div className="indus"></div>
            <div className=" flex  flex-col md:gap-10 gap-[20px] items-start justify-start max-w-full  w-full">
              {/* Industry */}
              <Reveal keyframes={SlideFade} duration="700">
                <div className="head w-full sm:w-full">
                  <Text
                    className="leading-[60.00px] md:text-[38px] sm:text-[25px] text-[35px] text-gray-50 tracking-[0.20px]"
                    size="txtInterBold40Gray50"
                  >
                    <span className="text-gray-50 font-inter text-left font-bold ml-[17px]">
                      <>Industry</>
                    </span>
                    <span className="text-green-600 font-inter text-left font-bold ml-[14px]">
                      Shortcomings
                    </span>
                  </Text>
                </div>
              </Reveal>
              <List
                className=" flex flex-col  md:flex-row sm:flex-col gap-8 sm:gap-3 items-start  w-full"
                orientation="vertical"
              >
                <div className=" flex md:flex-col   sm:ml-0  sm:flex-col  md:gap-10 sm:gap-5  items-center justify-between my-0 sm:w-full w-full">
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start  p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[50px] items-center justify-start w-[100px]">
                          <Img
                            className="h-[300px] md:h-auto mt-[-20px]  w-[300px]"
                            src="images/Engage in High Quality.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Long implementation period
                      </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start  p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[60px] items-center justify-start w-[160px]">
                          <Img
                            className="h-[300px] md:h-auto mt-[-50px]  w-[300px]"
                            src="images/Skill Gap Assessment.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Complex Regulatory Framework
                      </Text>
                    </div>
                  </div>

                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start  p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[55px] items-center justify-start w-[200px]">
                          <Img
                            className="h-[500px] md:h-auto mt-[-70px]  w-[500px]"
                            src="images/Automated Training workflow.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Data Integrity Concerns
                      </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start  p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[60px] items-center justify-start w-[100px]">
                          <Img
                            className="h-[300px] md:h-auto mt-[-20px]  w-[300px]"
                            src="images/lackOfintegrationHome.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Lack of Integration
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="  flex md:flex-col sm:ml-0 sm:flex-col  md:gap-10 sm:gap-5  items-center justify-between my-0 sm:w-full w-full">
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[50px] items-center justify-start w-[110px]">
                          <Img
                            className="h-[300px] md:h-auto mt-[-20px]  w-[300px]"
                            src="images/lackOfExpertiseHome.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Lack of expertise & capabilities
                      </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e  flex h-[212px] md:flex-1 flex-col items-center justify-start p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-[27px] items-center justify-start mb-[35px] mt-[15px] sm:px-5 px-[27px] w-full">
                      <div className="flex flex-col items-end justify-start w-30">
                        <div className="flex flex-col h-[55px] items-center justify-start w-[110px]">
                          <Img
                            className="h-[300px] md:h-auto mt-[-20px]  w-[300px]"
                            src="images/Lack Of Frugal Home.png"
                            alt="maskgroup"
                          />
                        </div>
                      </div>
                      <Text
                        className="leading-[24.00px] max-w-[200px] md:max-w-full text-center text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        Lack of frugal solutions
                      </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50_14 h-[212px] hover:bg-gradient-to-r  from-transparent to-green-600_1e flex md:flex-1 flex-col items-center justify-start p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-6 items-center justify-start mb-[35px] mt-[18px] w-auto">
                      <div className="flex flex-col items-end justify-start w-auto">
                        <div className="flex flex-col h-10 items-center justify-start w-30">
                          <Img
                            className="h-25 md:h-auto mt-[-45px]  w-[110px]"
                            src="images/Lack of adoptable.png"
                            alt="maskgroup_Two"
                          />
                        </div>
                      </div>
                      <Text
                        className="text-center text-gray-50 text-lg w-auto"
                        size="txtInterRegular18"
                      >
                        Lack of adoptable & scalable solutions
                      </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50_14 hover:bg-gradient-to-r  from-transparent to-green-600_1e flex h-[212px] md:flex-1 flex-col items-center justify-start p-[28px] sm:px-5 rounded-[12px] w-[24%] md:w-full">
                    <div className="flex flex-col gap-10 items-center justify-start mb-[35px] mt-[18px] w-auto">
                      <div className="flex flex-col items-end justify-start w-auto">
                        <div className="flex flex-col h-10 items-center justify-start w-50">
                          <Img
                            className="h-[100px] mt-[-40px] md:h-auto object-cover w-[100px]"
                            alt="maskgroup"
                            src="images/Lack of data driven08.png"
                          />
                        </div>
                      </div>
                      <Text
                        className="text-center text-gray-50 text-lg w-auto"
                        size="txtInterRegular18"
                      >
                        Lack of data driven decisions
                      </Text>
                    </div>
                  </div>
                </div>
              </List>
            </div>
          </div>
        </div>

        {/* Qssence Differentiators */}
        <div className=" flex flex-col font-dmsans items-center justify-start w-full zIndex-9999">
          <div className="relative bg-gray-900_01 flex flex-col items-end justify-center p-1.5 w-full">
            <span id="diff">
              <i class="ri-arrow-left-right-fill"></i>
            </span>
            <div className="diffHome"></div>
            <div className="flex  md:flex-col sm:flex-col items-center justify-start mt-[14px] sm:mt-[54px] md:px-5 w-[96%] md:w-full">
              <div className="md:h-[719px] sm:h-[719px] h-[700px]  relative w-full">
                {/* Qssence Differentiators */}
                <div className="absolute flex md:flex-col  sm:flex-col  items-center justify-start left-[0]  max-w-[auto]  sm:w-auto sm:justify-center   pr-60  w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <div className="head">
                      <Text
                        className="leading-[52.00px] sm:text-[38px] md:text-[44px] text-[48px] text-white-A700 ml-7"
                        size="txtDMSansBold52"
                      >
                        <span className="text-green-600 font-brunoace text-left font-normal">
                          Qssence
                        </span>
                        <span className="text-white-A700 font-inter text-left font-bold">
                          <> </>
                        </span>
                        <span className="md:text-[38px] sm:text-4xl text-white-A700 tracking-[0.20px] font-inter text-left text-[40px] font-bold">
                          Differentiators
                        </span>
                      </Text>
                    </div>
                  </Reveal>
                </div>
                {/* card  */}
                <div className="absolute flex   font-inter h-full inset-y-[0] sm:items-start items-center sm:justify-start justify-start my-auto right-[0] sm:w-full w-[59%]">
                  <div className="md:h-[700px]   sm:mt-11 h-[600px] sm:h-[700px] relative w-full">
                    <div className="absolute  sm:mt-11   hover:bg-gradient-to-r  from-transparent to-green-600_1e bg-white-A700_0a h-[550px]  inset-[0] justify-center sm:rounded-tr-[20px] m-auto rounded-bl-[35px] sm:rounded-tl-[20px] sm:p-5 rounded-tl-[35px] md:w-full shadow-bs11 w-full">
                      <HOMEPAGEComp className="absolute bg-cover py-11  bg-no-repeat flex flex-col sm:rounded-tr-[20px] sm:w-full sm:rounded-br-[20px]  inset-[0] items-center sm:px-[5px]  justify-center w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Industry Shortcomings */} {/* Qssence Differentiators end */}
      <div className="bg-blue_gray-100_02 flex flex-col items-center justify-end px-[58px] py-[10px] md:px-10 sm:px-5 w-full">
        <div className="flex flex-col gap-[20px] items-center justify-start max-w-[1043px] mt-3 mx-auto w-full">
          <Fade delay={2e2} cascade damping={1e-1} className="head">
            <Text
              className="sm:text-4xl md:text-[38px] text-[35px] text-gray-900 tracking-[0.20px] "
              size="txtInterBold40Gray900"
            >
              <span className="text-gray-900 font-inter text-left font-bold ">
                Pioneering Life-Science{" "}
              </span>
              <span className="text-green-600 font-inter text-left font-bold">
                Advancements
              </span>
            </Text>
          </Fade>

          <div className="flex flex-col gap-3 items-center justify-start w-auto md:w-full">
            <div style={{ width: "70%", height: "40%" }} className="sm:h-40">
              <video
                src="Videos/Control Change 01.mp4" // Specify the path to your video file
                width="100%" // Set video width to 100% of the parent container
                height="100%" // Set video height to 100% of the parent container
                controls={true} // Disable video controls
              />
            </div>
            <Text
              className="text-black-900 text-xl md:text-lg sm:text-sm w-auto"
              size="txtInterRegular20Black900"
            >
              Checkout our Features
            </Text>
          </div>
        </div>
      </div>
      {/* ⚒️⚒️⚒️⚒️⚒️⚒️⚒️⚒️⚒️ dont remove this ⚒️⚒️⚒️⚒️⚒️⚒️⚒️⚒️⚒️ */}
      {/* testimonial  */}
      {/* <div className="flex flex-col items-center justify-start w-full">
        <div className="flex flex-col items-center justify-start w-auto md:w-full">
          <div className="flex  relative w-full">
            <div className="bg-gray-900_01 flex flex-col items-start justify-start   max-w-[1532px] sm:px-2 px-10   pt-[200px] pb-[100px] sm:w-full w-full">
              <Reveal keyframes={SlideFade} duration="700">
                <Text
                  className="sm:text-4xl md:text-[38px] sm:ml-5 text-[40px] text-gray-50 w-auto mt-[-70px] "
                  size="txtInterBold40Gray50"
                >
                  Hear from Our Valued Partners:
                </Text>
              </Reveal>
              card start 
              <div className="container-fluid">
                <div className="scrolling-wrapper sm:w-[380px] sm:ml-[-46px] row flex-row flex-nowrap mt-4 pb-4 pt-2 ">
                  <div className="col-4 mr-5 sm:mr-[380px] sm:h-11 sm:w-11" >
                    <div className="card card-block card-1">
                      <div className="flex pt-[25px] ml-[20px] ">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                      </div>
                      <div className="flex items-center justify-start ">
                        <div className="p-4 w-[250px]">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Provident deserunt optio tempore ab odit culpa
                          ullam illum.
                        </div>
                        <div className="man-logo ml-24">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="2xl"
                            style={{
                              color: "#d0d8e6",
                              height: "60px",
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="card-name ml-5"
                        style={{ fontWeight: "bold" }}
                      >
                        John Doe
                      </div>
                      <div className=" ml-5" style={{ fontSize: "13px" }}>
                        𝘊𝘌𝘖 𝘈𝘡 𝘗𝘩𝘢𝘳𝘮𝘢
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mr-5 sm:mr-[380px]" >
                    <div class="card card-block card-1">
                      <div className="flex pt-[25px] ml-[20px]  ">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                      </div>
                      <div className="flex items-center justify-start ">
                        <div className="p-4 w-[250px]">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Provident deserunt optio tempore ab odit culpa
                          ullam illum.
                        </div>
                        <div className="man-logo ml-24">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="2xl"
                            style={{
                              color: "#d0d8e6",
                              height: "60px",
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="card-name ml-5"
                        style={{ fontWeight: "bold" }}
                      >
                        John Doe
                      </div>
                      <div className=" ml-5" style={{ fontSize: "13px" }}>
                        𝘊𝘌𝘖 𝘈𝘡 𝘗𝘩𝘢𝘳𝘮𝘢
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mr-5 sm:mr-[380px]" >
                    <div class="card card-block card-1">
                      <div className="flex pt-[25px] ml-[20px]  ">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                      </div>
                      <div className="flex items-center justify-start ">
                        <div className="p-4 w-[250px]">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Provident deserunt optio tempore ab odit culpa
                          ullam illum.
                        </div>
                        <div className="man-logo ml-24">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="2xl"
                            style={{
                              color: "#d0d8e6",
                              height: "60px",
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="card-name ml-5"
                        style={{ fontWeight: "bold" }}
                      >
                        John Doe
                      </div>
                      <div className=" ml-5" style={{ fontSize: "13px" }}>
                        𝘊𝘌𝘖 𝘈𝘡 𝘗𝘩𝘢𝘳𝘮𝘢
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mr-5 sm:mr-[380px]" >
                    <div class="card card-block card-1">
                      <div className="flex pt-[25px] ml-[20px]  ">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                      </div>
                      <div className="flex items-center justify-start ">
                        <div className="p-4 w-[250px]">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Provident deserunt optio tempore ab odit culpa
                          ullam illum.
                        </div>
                        <div className="man-logo ml-24">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="2xl"
                            style={{
                              color: "#d0d8e6",
                              height: "60px",
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="card-name ml-5"
                        style={{ fontWeight: "bold" }}
                      >
                        John Doe
                      </div>
                      <div className=" ml-5" style={{ fontSize: "13px" }}>
                        𝘊𝘌𝘖 𝘈𝘡 𝘗𝘩𝘢𝘳𝘮𝘢
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mr-5 sm:mr-[380px]" >
                    <div class="card card-block card-1">
                      <div className="flex pt-[25px] ml-[20px]  ">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#bcbec2", marginRight: "8px" }}
                        />
                      </div>
                      <div className="flex items-center justify-start ">
                        <div className="p-4 w-[250px]">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Provident deserunt optio tempore ab odit culpa
                          ullam illum.
                        </div>
                        <div className="man-logo ml-24">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="2xl"
                            style={{
                              color: "#d0d8e6",
                              height: "60px",
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="card-name ml-5"
                        style={{ fontWeight: "bold" }}
                      >
                        John Doe
                      </div>
                      <div className=" ml-5" style={{ fontSize: "13px" }}>
                        𝘊𝘌𝘖 𝘈𝘡 𝘗𝘩𝘢𝘳𝘮𝘢
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              card end
              <div
                className="scroll-dots"
                style={{ paddingTop: "20px", marginLeft: "10px" }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  spinPulse
                  style={{ color: "#f3f1f1", marginRight: "10px" }}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  spinPulse
                  style={{ color: "#c2c2c2", marginRight: "10px" }}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  spinPulse
                  style={{ color: "#c2c2c2", marginRight: "10px" }}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  spinPulse
                  style={{ color: "#c2c2c2", marginRight: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default HOMEPAGEPage;
