import { Img, List, Text } from "components";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import ProductsQMSC1 from "components/ProductsQMSC1";
import ProductsQMSC2 from "components/ProductsQMSC2";
import ProductsQMSC3 from "components/ProductsQMSC3";
import ProductsQMSC4 from "components/ProductsQMSC4";
import ProductsQMSStackOne from "components/ProductsQMSStackOne";
import ProductsQMSStackcomplaints from "components/ProductsQMSStackcomplaints";
import ProductsQMSStackoosoot from "components/ProductsQMSStackoosoot";
import ProductsQMSStackdocumentmanagemOne from "components/ProductsQMSStackdocumentmanagemOne";
import ProductsQMSStackinvestigationi from "components/ProductsQMSStackinvestigationi";
import ProductsQMSStackchangecontrol from "components/ProductsQMSStackchangecontrol";
import ProductsQMSStackauditmanagementOne from "components/ProductsQMSStackauditmanagementOne";
import ProductsQMSStackvendorquality from "components/ProductsQMSStackvendorquality";
import Qmsfdavision from "components/Fdavisioncard/Qmsfdavision";
import Qmsfdavision1 from "components/Fdavisioncard/Qmsfdavision1";
import Qmsfdavision2 from "components/Fdavisioncard/Qmsfdavision2";
// import React , from 'react';
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
const slideRight = keyframes`
        from {
            opacity: 0;
            transform: translate(90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
// const upFade = keyframes`
//         from {
//             opacity: 0;
//             transform: translate(0, 50%);
//         }

//         to {
//             opacity: 1;
//             transform: translate(0, 0%);
//         }
//     `;
// const slideUp = keyframes`
//     from {
//         opacity: 0;
//         transform: translateY(-90%, 0);
//     }

//     to {
//         opacity: 1;
//         transform: translate(0, 0);
//     }
// `;

export default function QMS() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();

    tl.to(".qmsfeatures", {
      height: 500,
      scrollTrigger: {
        scrub: 5,
        trigger: ".qmsfeatures",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play",
      },
    });
  }, []);
  return (
    <div>
      <div className="sm:h-[0px] md:hidden sm:invisible bg-blue_gray-50  flex flex-col font-dmsans items-center justify-start mx-auto  sm:w-full md:w-full w-full">
        <div className="sm:invisible bg-gray-900_01 font-inter  h-[750px] mt-28  md:h-[750px] sm:h-[1100px] md:px-5 pb-[98px] pt-[70px] relative w-full">
          <div className="px-20 pt-3 pb-8 sm:hidden">
            <Reveal keyframes={SlideFade} duration="700">
              <Text
                className=" left-[5%] sm:text-[38px]  md:text-[44px]   text-[48px] text-white-A700 top-[10%]"
                size="txtInterBold52WhiteA700"
              >
                <span className="text-white-A700 font-brunoace ml-[15px] text-left font-normal">
                  Elevate
                </span>
                <span className="text-green-600 font-brunoace ml-[15px] text-left font-normal">
                  Suite
                </span>
              </Text>
            </Reveal>
          </div>
          <div className=" h-[1024px]  md:h-[2384px] sm:h-[875px] inset-[0] justify-center  sm:mt-28 w-full">
            <div className="absolute  flex flex-col md:gap-10 gap-20 inset-x-[0] items-center justify-start mx-auto w-auto md:w-full">
              <div className="flex sm:invisible sm:hidden flex-col items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-col items-start justify-center w-full mt-0 px-20 md:w-full">
                    <div className="bg-white-A700_14  flex flex-col  items-start justify-start max-w-full md:px-10 sm:px-5 mx-auto   py-15 rounded-[40px] w-full">
                      <Reveal keyframes={SlideFade} duration="700">
                        <Text
                          className="md:text-2xl sm:text-[28px] ml-[25px] text-[28px] text-gray-50 tracking-[0.32px] pt-[14px] w-auto"
                          size="txtInterBold32"
                        >
                          <span className="text-gray-50 font-inter text-left font-bold ml-[25px]">
                            Qssence{" "}
                          </span>
                          <span className="text-green-600 font-inter text-left font-bold">
                            QMS Modules
                          </span>
                        </Text>
                      </Reveal>
                      <div className="flex relative w-full">
                        <div className="md:h-[495px] h-[480px]  w-[50%] sm:w-full">
                          <Img
                            className="absolute h-[480px] inset-[0] justify-center   rounded-[83px] w-[85%]"
                            src="images/QMS_Module1.webp"
                            alt="images/QMS_Module1.png"
                          />
                        </div>
                        <div className="flex flex-col gap-16 mb-[28px] pb-[56px] justify-start  my-auto w-[57%] z-[1]">
                          <List
                            className="flex flex-col gap-10 items-center ml-2 md:ml-[0] w-[99%]"
                            orientation="vertical"
                          >
                            <div className="flex flex-col gap-2.5  justify-start w-[105%] md:w-[110%] ml-[20px] md:w-full">
                              <div className="flex sm:flex-col flex-row gap-10  changeControlQMS md:ml-[0] w-auto sm:w-full">
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Change control
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Deviation
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Out Of Specification
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-[9px] justify-start w-full">
                              <div className="flex sm:flex-col flex-row mb-[9px] sm:gap-10 gap-[45px] outOfTrendQMS items-center ml-[60px] justify-start md:ml-[0] w-auto sm:w-full">
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="leading-[28.00px]  text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                    size="txtInterRegular20"
                                  >
                                    <>
                                      Out Of <br />
                                      Trend
                                    </>
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                    size="txtInterRegular20"
                                  >
                                    <>
                                      Root Cause <br />
                                      Investigation
                                    </>
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                    size="txtInterRegular20"
                                  >
                                    <>
                                      Corrective Actions
                                      <br /> & Preventive Actions
                                    </>
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-[9px] justify-start w-[92%] md:w-full">
                              <div className="flex sm:flex-col flex-row sm:gap-10 gap-[40px] internalAuditQMS items-start ml-[1px] justify-start md:ml-[0] w-auto sm:w-full">
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Internal <br />
                                    Audit
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                    size="txtInterRegular20"
                                  >
                                    <>
                                      Complaint <br />
                                      Management
                                    </>
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Regulatory <br /> Audit
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-2.5 justify-start w-[92%] w-full">
                              <div className="flex sm:flex-col flex-row sm:gap-10 gap-[60px] CustomerNotificationQMS  md:h-auto items-start justify-start  md:ml-[0] w-[559px] sm:w-full">
                                <div className="flex flex-col ml-[40px] items-center justify-center w-auto">
                                  <Text
                                    className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                    size="txtInterRegular20"
                                  >
                                    <>
                                      Customer
                                      <br />
                                      Notification
                                    </>
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Quality <br />
                                    Reviews
                                  </Text>
                                </div>
                                <div className="flex flex-col items-center justify-center w-auto">
                                  <Text
                                    className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                    size="txtInterRegular20"
                                  >
                                    Vendor <br />
                                    Complaints
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </List>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-blue_gray-50 overflow-hidden  flex flex-col font-inter items-center justify-start max-w-full  sm:pb-[60px] pt-[200px] sm:pt-[200px] md:w-full w-full">
        <div className="flex flex-col md:gap-10 gap-[120px] items-center justify-start w-full md:w-full">
          <div className="flex md:flex-col sm:flex-col gap-1 items-center mt-[-250px] md:mt-[-50px] justify-between max-w-full mx-auto md:px-5 sm:px-0 sm:w-full w-full">
            <div className="flex relative md:flex-1 flex-col items-center justify-start w-[50%]  sm:w-full md:w-full">
              <div className="flex flex-col md:gap-10 gap-[60px] ml-16 sm:ml-[0px] items-center justify-start w-full md:w-full">
                <div className="flex  h-[590px] relative  sm:w-[full] w-full">
                  <Img
                    className="h-[500px] sm:h-[500px] md:h-[570px]  inset-y-[0] my-auto object-cover   rounded-sm w-full"
                    src="images/DoQssence_dashboard.webp"
                    alt="images/DoQssence_dashboard.png"
                  />
                </div>
              </div>
            </div>
            <div className="flex  flex-col gap-3  items-start sm:items-center  justify-start w-[472px]  sm:w-full ">
              <Reveal keyframes={slideRight} duration="700">
                <div className="font-brunoace md:h-[73px]  h-[98px] relative  w-[472px]  sm:w-full">
                  <Text
                    className=" left-[0] md:text-5xl text-5xl sm:text-center text-green-600 top-[0] w-auto"
                    size="txtBrunoAceRegular60"
                  >
                    Elevate
                  </Text>
                  <div className="bottom-[0] flex flex-col sm:mx-auto font-inter inset-x-[0] items-center justify-start sm:justify-center mx-auto w-[472px] sm:w-full">
                    <div className="flex flex-col items-start justify-start sm:mx-auto sm:justify-center w-[472px] sm:w-full">
                      <Text
                        className="text-xl md:text-[22px] sm:text-center text-gray-900 sm:text-[22px] sm:w-full w-auto"
                        size="txtInterBold24"
                      >
                        Quality Management System (QMS)
                      </Text>
                    </div>
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col items-start justify-start w-full sm:w-full md:w-full">
        <div className="flex flex-col items-start justify-start w-full sm:w-full md:w-full">
          {/* #eaf3f3 */}
          <div className="h-auto  relative w-full">
            <div className="flex flex-col h-full  justify-start m-auto w-full">
              <div className="flex flex-col items-center pt-[60px] justify-start  sm:w-full w-auto">
                <div className="flex md:flex-col sm:flex-col md:flex-row  md:gap-10 items-center justify-between w-[90%] sm:w-full md:w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <Text
                      className="leading-[60.00px] sm:ml-[20px] sm:text-4xl font-brunoace md:text-[38px] text-[38px] text-gray-900 tracking-[0.20px]"
                      size="txtInterBold40Gray900"
                    >
                      <>
                        <span style={{ color: "green" }}>FDA’s Vision </span>
                        <br />
                        For The Industry
                      </>
                    </Text>
                  </Reveal>
                  <div className="bg-gray-900  flex flex-col h-[400px]  sm:mt-[40px] sm:h-[320px] sm:p-[11] items-center justify-center p-[50px] md:px-10  rounded-[50%]  shadow-2xl sm:w-full w-[400px] ">
                    <Text
                      className="leading-[32.00px] my-[98px] text-lg md:text-base text-center text-gray-50 sm:text-sm w-full"
                      size="txtInterRegular24Gray50"
                    >
                      Become a maximally efficient, agile, flexible
                      manufacturing sector that reliably produces high-quality
                      drug products without extensive regulatory oversight.
                      Qssence QMS system is designed to deliver all these
                      outcomes for its clients.
                    </Text>
                  </div>
                </div>

                <div className="flex flex-col items-end justify-start h-auto relative md:px-10 px-20 sm:px-5 py-[20px]   w-full md:w-full">
                  <List
                    className="sm:flex-col flex-row md:flex-col gap-[30px] sm:gap-0 md:gap-0 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center md:w-full  w-full"
                    orientation="horizontal"
                  >
                    <Qmsfdavision className="  h-[450px] relative ms-4   w-full" />
                    <Qmsfdavision1 className="  h-[450px] relative  ms-4  w-full" />
                    <Qmsfdavision2 className="  h-[450px] relative   ms-4 w-full" />
                 
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Challenges */}
      <div className="bg-gray-900_01 flex flex-col md:gap-10 gap-[20px] items-start  justify-center  md:px-10 px-20 sm:px-5 py-[20px] sm:w-full md:w-full w-full">
        <div className="flex flex-col items-start justify-start w-auto md:w-full">
          <Reveal keyframes={SlideFade} duration="700">
            <Text
              className="leading-[60.00px] sm:text-[28px] font-brunoace md:text-[32px] text-[45px] text-gray-50"
              size="txtInterBold52Gray50"
            >
              <span className="text-green-600  text-left font-bold">
                Challenges
              </span>
              <span className="text-gray-50  text-left font-bold"> </span>
              <span className="md:text-[32px] sm:text-[28px] text-gray-50  text-left text-[40px] font-bold">
                <>
                  In Industry & <br />
                  Technological
                </>
              </span>
              <span className="text-gray-50  text-left font-bold"> </span>
              <span className="text-green-600  text-left font-bold">
                Advantages
              </span>
              <span className="text-gray-50  text-left font-bold"> </span>
              <span className="md:text-[32px] sm:text-[28px] text-gray-50  text-left text-[40px] font-bold">
                With Qssence
              </span>
            </Text>
          </Reveal>
        </div>
        <div className="flex flex-col gap-10 items-start justify-center w-full md:w-full">
          <ProductsQMSC1 className="relative bg-white-A700_0a hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e flex flex-col gap-2 items-start justify-start px-10 pl-12 py-3 sm:px-5 rounded-[24px] sm:w-full w-full md:w-full sm:pl-7" />
          <ProductsQMSC2 className="relative bg-white-A700_0a hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e flex flex-col gap-2 items-start justify-center px-10 pl-12 py-3 sm:px-5 rounded-[24px] sm:w-full w-full md:w-full sm:pl-7" />
          <ProductsQMSC3 className="relative bg-white-A700_0a hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e flex flex-col gap-3 items-start justify-start px-10 pl-12 py-3 sm:px-5 rounded-[24px] sm:w-full w-full md:w-full sm:pl-7" />
          <ProductsQMSC4 className="relative bg-white-A700_0a hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e flex flex-col gap-3 items-start justify-start px-10 pl-12 py-3 sm:px-5 rounded-[24px] sm:w-full w-full md:w-full sm:pl-7" />
        </div>
      </div>
      {/* Illustrative Workflows  */}
      <div className="bg-blue_gray-50 flex flex-col items-start justify-start max-w-full md:px-10 px-20  sm:px-5 py-[60px] md:w-full w-full">
        <div className="flex flex-col md:gap-50 gap-[50px] items-start justify-start w-full md:w-full">
          <Reveal keyframes={SlideFade} duration="700">
            <Text
              className="capitalize sm:text-[38px] font-brunoace md:text-[44px] text-[45px] text-gray-900 w-auto"
              size="txtInterBold52"
            >
              <span className="text-gray-900 text-left font-bold">
                Illustrative{" "}
              </span>
              <span className="text-green-600 text-left font-bold">
                workflows
              </span>
            </Text>
          </Reveal>
          <div className="flex flex-col items-start justify-start w-full md:w-full">
            <div className="gap-10 gap-y-30 gap-x-10 md:gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-center min-h-[250px]   md:w-full w-full">
              <ProductsQMSStackOne className="  h-[250px] md:h-[300px] sm:h-[300px] relative    w-full" />
              <ProductsQMSStackcomplaints className=" h-[250px] md:h-[300px] sm:h-[300px] relative w-full" />
              <ProductsQMSStackoosoot className=" h-[250px] md:h-[300px] sm:h-[300px] relative w-full" />
              <ProductsQMSStackdocumentmanagemOne className=" h-[250px] md:h-[300px] sm:h-[300px]  relative w-full" />

              <ProductsQMSStackinvestigationi className=" h-[250px] md:h-[300px] sm:h-[300px] mt-5 sm:!mt-0  relative w-full" />
              <ProductsQMSStackchangecontrol className=" h-[250px] md:h-[300px] sm:h-[300px] mt-5 sm:!mt-0  relative w-full" />
              <ProductsQMSStackauditmanagementOne className=" h-[250px] md:h-[300px] sm:h-[300px] mt-5 sm:!mt-0 relative w-full" />
              <ProductsQMSStackvendorquality className=" h-[250px] md:h-[300px] mt-5 sm:!mt-0 sm:h-[300px] relative w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900_01 relative flex sm:flex-col items-start justify-start md:px-10 px-20 sm:px-5 py-[10px] w-full sm:w-full md:w-full">
        <span id="qmsFeatures">
          <i class="ri-key-line"></i>
        </span>
        <div className="qmsfeatures"></div>
        <div className="flex flex-col md:gap-10 gap-[20px] items-start justify-start md:w-full w-full">
          <Reveal keyframes={SlideFade} duration="700">
            <Text
              className="capitalize sm:text-[38px] sm:pl-[10px] font-brunoace md:text-[44px] text-[45px] text-green-600 w-[223px]"
              size="txtInterBold52Green600"
            >
              Features
            </Text>
          </Reveal>
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-col gap-[38px] items-center justify-start  w-full">
              <div className="flex md:flex-col sm:flex-col gap-[30px] items-center justify-between sm:w-full w-full">
                <div className="flex md:flex-1 flex-col gap-10 items-center justify-start w-[49%] sm:w-full md:w-full">
                  <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[350px]  gap-10 items-center justify-center  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                    <div className="flex flex-col items-center justify-start w-[177px] ">
                      {/* <div className="vert-move"> */}
                      <Img
                        className="h-[75px] sm:h-[80px] sm:ml-[-80px] sm:mt-[10px] sm:w-[80px] md:h-auto  w-[75px] "
                        src="images/Seamless Data Exchange.gif"
                        alt="clouddataanalys"
                      />
                      {/* </div> */}
                    </div>
                    <div className="flex flex-col gap-3 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[22px] text-gray-50 sm:text-xl tracking-[0.48px] w-full"
                        size="txtInterBold24Gray50"
                      >
                        Seamless Data Exchange
                      </Text>
                      <Text
                        className="leading-[28.00px] text-gray-50 sm:text-[16px] text-xl"
                        size="txtInterRegular20"
                      >
                        <>
                          <li>Real-time data synchronization</li>
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;  */}
                          <li>
                            Data validation and error handling{" "}
                            {/* <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          </li>
                          <li>Bi-directional data exchange</li>{" "}
                          {/*  <br />&nbsp;&nbsp;&nbsp;&nbsp; */}
                          <li> Ease of integration</li>
                        </>
                      </Text>
                    </div>
                  </div>
                  <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[400px]  gap-10 items-center justify-center  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                    <Img
                      className="analytics sm:h-[150px] sm:ml-[-10px] sm:mt-[5px] sm:w-[150px] sm:flex-1 h-[150px] md:h-auto object-cover  w-[150px]"
                      src="images/Analytics (1).png"
                      alt="dataanalysis"
                    />
                    <div className="flex flex-col gap-6 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[22px] text-gray-50 sm:text-xl tracking-[0.48px] w-full"
                        size="txtInterBold24Gray50"
                      >
                        Analytics
                      </Text>
                      <Text
                        className="leading-[24.00px] sm:text-[16px] text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        <>
                          <li>Site Health & Site comparison </li>
                          {/* <br />&nbsp;&nbsp;&nbsp;&nbsp;  */}
                          <li>
                            System health (eg.,Repeat root cause, System
                            specific trend{" "}
                            {/* <br />
                                                                &nbsp;&nbsp;&nbsp;&nbsp; */}
                            analysis){" "}
                            {/* <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          </li>
                          <li>Individual / Team Performance </li>{" "}
                          {/* <br />&nbsp;&nbsp;&nbsp;&nbsp; */}
                          <li>System efficiency </li>
                        </>
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-1 flex-col gap-10 items-center justify-start w-[49%] sm:w-full  md:w-full">
                  <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[400px]  gap-10 items-center justify-start  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                    <div className="vert-move">
                      <Img
                        className="sm:flex-1 h-[75px] sm:h-[100px] sm:ml-[-10px] sm:mt-[10px] sm:w-[100px] md:h-auto  w-[150px] "
                        src="images/User Management Control.gif"
                        alt="clouddataanalys_One"
                      />
                    </div>

                    <div className=" flex flex-col gap-3 items-start justify-start w-full">
                      <Text
                        className="leading-[40.00px] text-2xl md:text-[22px] text-gray-50 sm:text-base tracking-[0.48px]"
                        size="txtInterBold24Gray50"
                      >
                        <>User Management & User Control</>
                      </Text>
                      <Text
                        className="leading-[24.00px] sm:w-[auto] sm:text-[16px] text-gray-50 text-lg"
                        size="txtInterRegular18"
                      >
                        <>
                          <li>
                            {" "}
                            User Activity Monitoring and Audit{" "}
                            {/*  <br />&nbsp;&nbsp; &nbsp;&nbsp; */}
                            Trails{" "}
                          </li>
                          <li> User Access Reports and Analytics</li>{" "}
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          <li>
                            User Self-Service & Help Desk integration{" "}
                            {/* <br />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          </li>
                          <li>User creation and Role Management</li>{" "}
                          {/* <br />&nbsp;&nbsp;&nbsp;&nbsp; */}
                        </>
                      </Text>
                    </div>
                  </div>
                  <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[400px]  gap-10 items-center justify-center  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                    <div className="flex flex-col items-center justify-start w-[177px] ">
                      <Img
                        className="sm:flex-1 h-[150px] md:h-auto w-[150px] sm:h-[120px] sm:ml-[-10px] sm:mt-[0px] sm:w-[150px]   "
                        src="images/Audit Trail.png"
                        alt="confusinganalys"
                      />
                    </div>
                    <div className="flex flex-col gap-3 items-start justify-start w-full sm:w-[auto]">
                      <Text
                        className="text-2xl md:text-[22px] text-gray-50 sm:text-xl tracking-[0.48px] w-auto"
                        size="txtInterBold24Gray50"
                      >
                        Audit Trail Capabilities
                      </Text>
                      <div className="flex flex-col items-start justify-start w-[350px] sm:w-[auto]">
                        <Text
                          className="leading-[24.00px] sm:text-[16px] text-gray-50 text-lg"
                          size="txtInterRegular18"
                        >
                          <>
                            <li>User Activity Logging</li>

                            <li>Document Version History</li>

                            <li> System configuration changes</li>
                            {/* <br /> &nbsp; &nbsp; &nbsp;*/}

                            <li>Event notifications & alerts</li>
                            {/* <br />&nbsp; &nbsp; &nbsp;*/}
                          </>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-col sm:flex-col  items-center justify-evenly gap-[40px] sm:w-full w-full">
                <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[429px]  gap-10 items-center justify-center  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                  {/* <div className="vert-move"> */}
                  <Img
                    className="sm:flex-1 h-[75px] md:h-auto object-fit  w-[150px] sm:h-[80px] sm:ml-[10px] sm:mt-[10px] sm:w-[100px]"
                    src="images/Architecture & Scalability.gif"
                    alt="clouddataanalys_Two"
                  />
                  {/* </div> */}
                  <div className="flex flex-col gap-6 items-start justify-start w-full">
                    <Text
                      className="text-2xl md:text-[22px] text-gray-50 sm:text-[18px] tracking-[0.48px] w-full"
                      size="txtInterBold24Gray50"
                    >
                      Architecture And Scalability
                    </Text>
                    <Text
                      className="leading-[24.00px] text-gray-50 sm:text-[16px] w-full sm:w-[auto] text-lg"
                      size="txtInterRegular18"
                    >
                      <>
                        <li>
                          Open APIs, integrates with other{" "}
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */} software and
                          {/*<br /> &nbsp;&nbsp;&nbsp;&nbsp;*/} systems
                        </li>
                        <li>Cloud based deployment option</li>
                        {/* <br />&nbsp;&nbsp;&nbsp;&nbsp;  */}
                        <li> Modular architecture, scalable</li>{" "}
                        {/* <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        <li>
                          Interoperability with other{" "}
                          {/* <br /> &nbsp;&nbsp;&nbsp;&nbsp; */} systems
                          {/*  &nbsp;&nbsp;&nbsp;&nbsp; */}(e.g. docx, pdf,
                          xlsx,pptx)
                        </li>
                      </>
                    </Text>
                  </div>
                </div>
                <div className="cards-border bg-white-A700_0a hover:bg-gradient-to-r  from-black-900_01 to-green-600_1e border-solid flex sm:flex-col h-[300px] sm:h-[400px]  gap-10 items-center justify-center  max-w-[full] sm:w-[320px]  p-10 sm:p-5 rounded-[24px] md:w-full w-full">
                  {/* <div className="vert-move"> */}
                  <div className="flex flex-col items-center justify-start w-[177px] ">
                    <Img
                      className="sm:flex-1 h-[75px] md:h-auto   w-[75px] sm:h-[100px] sm:ml-[10px] sm:mt-[0px] sm:w-[100px]"
                      src="images/Product Maturity & Configuration.gif"
                      alt="clouddataanalys_Three"
                    />
                  </div>
                  <div className="flex flex-col gap-3 items-start justify-start w-full">
                    <Text
                      className=" text-xl md:text-[22px] text-gray-50  sm:text-[18px] tracking-[0.48px]"
                      size="txtInterBold24Gray50"
                    >
                      <>Product Maturity & Configuration Management</>
                    </Text>
                    <Text
                      className="leading-[24.00px] text-gray-50 sm:w-[auto] sm:text-[16px] sm:mb-[40px] text-lg"
                      size="txtInterRegular18"
                    >
                      <>
                        <li> Workflow configuration</li>

                        <li>Notifications and Alerts configuration</li>
                        {/*<br />&nbsp;&nbsp;&nbsp;&nbsp;*/}

                        <li>Search and Indexing Setting configuration</li>
                        {/*<br /> &nbsp;&nbsp;&nbsp;&nbsp;*/}

                        <li>
                          Reporting and Analytics{/* <br />&nbsp;&nbsp;&nbsp;*/}{" "}
                          Configuration
                        </li>
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
