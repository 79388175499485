import React from "react";
const baseImageUrl = () =>{
  const baseUrl = window.location.origin;
  return baseUrl;
}

const Img = ({
  className,
  src = "defaultNoData.png",
  alt = "testImg",
  ...restProps
}) => {
  const imageUrl = src.includes("http") ? src : `${baseImageUrl()}/${src}`;
  return (
    <img
      className={className}
      src={imageUrl}
      alt={`${baseImageUrl()}/${alt}`}
      {...restProps}
      loading={"lazy"}
    />
  );
};
export { Img };
