import React from "react";

import { Img, Text } from "components";

const ProductsDMSColumnOne = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-8 items-center mt-[30px] justify-start w-auto">
          <Img
            className="h-[114px] md:h-auto object-cover w-[114px]"
            src="images/intuitive.png"
            alt="maskgroup_One"
          />
          <Text
            className="text-2xl md:text-[22px] text-gray-50 sm:text-xl w-auto"
            size="txtInterRegular24Gray50"
          >
            {props?.username}
          </Text>
        </div>
      </div>
    </>
  );
};

ProductsDMSColumnOne.defaultProps = { username: "Intuitive" };

export default ProductsDMSColumnOne;
