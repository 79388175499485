import React from "react";
// import { Link } from "react-router-dom";
import { Button } from "components";

const ProductsQMSColumnqualityManagementSystemQms = (props) => {
  return (
    <>
      <div className={props.className} >{/* style={{position:"fixed",top:"120px",zIndex:"9",overflow:"hidden"}} */}
        <div className="bg-gray-900_01 flex sm:flex-col md:flex-col font-inter gap-3 items-center justify-center  max-w-full  sm:px-5 py-3 sm:w-full md:w-full w-full " >
          {props.type === 0 ? (
            <Button
              className="cursor-pointer hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e font-bold font-inter h-10 min-w-[374px] sm:min-w-full rounded-lg text-center text-lg md:text-sm sm:text-lg"
              color="green_600"
              size="md"
              variant="fill"
              onClick={()=>props?.setType(0)}
            >
              <p>{props?.qualitysystem}</p>
            </Button>
          ) : (
            <Button
              className="bg-transparent hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e cursor-pointer font-inter  h-10 min-w-[360px] text-center text-gray-50 text-lg md:text-sm sm:text-lg"
              size="md"
              onClick={()=>props?.setType(0)}
            >
              <p>{props?.qualitysystem }</p>
            </Button>
          )}

          {props.type === 1 ? (
            <Button
              className="cursor-pointer hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e font-bold font-inter h-10 min-w-[374px] sm:min-w-full rounded-lg text-center text-lg md:text-sm sm:text-lg"
              color="green_600"
              size="md"
              variant="fill"
              onClick={()=>props?.setType(1)}
            >
              <p>{props?.learningsystem}</p>
            </Button>
          ) : (
            <Button
              className="bg-transparent hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e cursor-pointer font-inter  h-10 min-w-[360px] text-center text-gray-50 text-lg"
              size="md"
              onClick={()=>props?.setType(1)}
            >
              <p>{props?.learningsystem}</p>
            </Button>
          )}

          {props.type === 2 ? (
            <Button
              className="cursor-pointer hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e font-bold font-inter h-10 min-w-[374px] sm:min-w-full rounded-lg text-center text-lg"
              color="green_600"
              size="md"
              variant="fill"
              onClick={()=>props?.setType(2)}
            >
              <p>{props?.documentsystem}</p>
            </Button>
          ) : (
            <Button
              className="bg-transparent hover:bg-gradient-to-r  from-white-A700_0a to-green-600_1e  cursor-pointer font-inter  h-10 min-w-[360px] text-center text-gray-50 text-lg"
              size="md"
              onClick={()=>props?.setType(2)}
            >
              <p>{props?.documentsystem}</p>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

ProductsQMSColumnqualityManagementSystemQms.defaultProps = {
  qualitysystem: "Elevate QMS ",
  learningsystem: "Aspire LMS",
  documentsystem: "DoQssence DMS",
};

export default ProductsQMSColumnqualityManagementSystemQms;
