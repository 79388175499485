import React from "react";
import { Link } from "react-router-dom";
import { Button, Img, Input, Text } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const RequestdemoPage = () => {
  return (
    <>
      <div className="bg-blue_gray-50 flex flex-col font-inter items-center justify-start mx-auto pb-[73px] w-full">
        <div className="flex flex-col gap-8 items-center justify-start w-full">
          <div className="h-[110px] md:h-[111px] md:px-5 relative w-full">
            <div className="flex flex-col h-full items-center justify-center ml-20 my-auto py-3 w-auto">
              <div className="flex flex-row gap-3 items-end justify-between w-full">
                <div className="flex flex-row items-start justify-evenly mt-[7px]">

                </div>
              </div>
            </div>
            <div className="absolute bg-gradient3 no-repeat flex flex-col h-full inset-[0] items-start justify-center m-auto p-[21px] sm:px-5 w-full">
              <div class="flex flex-col items-center justify-center md:ml-[0] ml-[59px] py-3 w-auto">
                <div class="flex flex-row gap-3 items-end justify-between w-full">
                  <Img
                    className="h-[43px] mb-0.5"
                    src="images/img_folder.svg"
                    alt="folder"

                  />
                  <div class="flex flex-row items-start justify-evenly mt-[7px]">
                    <div class="flex flex-col items-center justify-start mt-[3px] w-[92%]">
                      <div class="flex flex-col items-start justify-start w-full">
                        <div class="flex flex-col font-brunoace items-center justify-start w-full">
                          <p class="text-left text-base text-gray-900 tracking-[3.84px] font-brunoace font-normal">
                            QSSENCE
                          </p>
                        </div>
                        <p class="text-left ml-0.5 md:ml-[0] text-gray-900 text-xs tracking-[0.96px] font-avenir font-normal">
                          <span class="text-gray-900 font-avenir text-left font-extrabold">
                            T
                          </span>
                          <span class="text-gray-900_cc font-avenir text-left font-normal">
                            ech
                          </span>
                          <span class="text-gray-900 font-avenir text-left font-extrabold">
                            V
                          </span>
                          <span class="text-gray-900_cc font-avenir text-left font-normal">
                            antage
                          </span>
                        </p>
                      </div>
                    </div>
                    <Img
                      className="h-2.5 w-2.5"
                      src="images/img_phtrademarkregistered.svg"
                      alt="phtrademarkregi"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="flex md:flex-col flex-row gap-[30px] items-center justify-center max-w-[1352px] mx-auto md:px-5 w-full">
            <div className="flex md:flex-1 flex-col md:gap-10 gap-5 items-start justify-start w-[49%] md:w-full">
              <Button
                className="bg-transparent cursor-pointer flex items-center justify-center min-w-[123px]"

              >
                <Link to="/"><div cl><FontAwesomeIcon icon={faArrowRight} rotation={180} size="2xl" /></div></Link>
                <div className="text-gray-800 text-left text-lg ml-[30px]" style={{ fontWeight: "bold" }}>Go back</div>
              </Button>
              <div className="flex flex-col md:gap-10 gap-[30px] items-start justify-start w-auto md:w-full">
                <div className="flex flex-col gap-10 items-start justify-start w-auto md:w-full">
                  <Text
                    className="leading-[18.00px] text-black-900 text-lg"
                    size="txtInterRegular18Black900"
                  >
                    <span className="md:text-3xl sm:text-[28px] text-green-600 tracking-[0.32px] font-inter text-left text-[32px] font-bold">
                      <>
                        See it in Action: <br />
                      </>
                    </span>
                    <span className="text-gray-900 font-inter text-left text-xl font-bold">
                      Request a Demo for a Peek Behind the Curtain
                    </span>
                  </Text>
                  <div className="flex flex-col gap-6 items-start justify-start w-auto md:w-full">
                    <Text
                      className="text-gray-900 text-xl w-auto"
                      size="txtInterRegular20Gray900"
                    >
                      Get started now! Drop your info for an effortless, free
                      demo.
                    </Text>
                    <div className="flex flex-col gap-10 items-start justify-start w-full md:w-full">
                      <div className="flex flex-col gap-6 items-start justify-start w-full md:w-full">
                        <Input
                          name="firstname"
                          placeholder="First name*"
                          className="p-0 placeholder:text-gray-800 text-left text-xl w-full "
                          wrapClassName="border border-gray-400_01 border-solid w-full"
                          type="text"
                          shape="round"
                          color="blue_gray_100_66"
                          size="xs"
                          variant="fill"
                        ></Input>
                        <Input
                          name="lastname"
                          placeholder="Last name*"
                          className="p-0 placeholder:text-gray-800 text-left text-xl w-full "
                          wrapClassName="border border-gray-400_01 border-solid w-full"
                          type="text"
                          shape="round"
                          color="blue_gray_100_66"
                          size="xs"
                          variant="fill"
                        ></Input>
                        <Input
                          name="organizationname"
                          placeholder="Organization name*"
                          className="p-0 placeholder:text-gray-800 text-left text-xl w-full"
                          wrapClassName="border border-gray-400_01 border-solid w-full "
                          type="text"
                          shape="round"
                          color="blue_gray_100_66"
                          size="xs"
                          variant="fill"
                        ></Input>
                        <Input
                          name="emailid"
                          placeholder="Email Id*"
                          className="p-0 placeholder:text-gray-800 text-left text-xl w-full"
                          wrapClassName="border border-gray-400_01 border-solid w-full"
                          type="email"
                          shape="round"
                          color="blue_gray_100_66"
                          size="xs"
                          variant="fill"
                        ></Input>
                      </div>
                      <div className="flex flex-col gap-3 items-start justify-start w-full md:w-full">
                        {/* <Text
                          className="text-gray-800 text-lg w-auto"
                          size="txtInterRegular18Gray800"
                        >
                          Optional
                        </Text> */}
                        <Input
                          name="phonenumber"
                          placeholder="Phone number"
                          className="p-0 placeholder:text-gray-800 text-left text-xl w-full"
                          wrapClassName="border border-gray-400_01 border-solid w-full"
                          type="number"
                          shape="round"
                          color="blue_gray_100_66"
                          size="xs"
                          variant="fill"
                        ></Input>
                      </div>
                      <div className="flex flex-row gap-3 items-start justify-start w-full md:w-full">
                        <input type="checkbox" className="rounded-md" />
                        <span>I agree privacy policy & terms conditions</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-auto">
                  <Button
                    className="cursor-pointer flex items-center justify-center min-w-[205px] rounded-[36px]"
                    rightIcon={
                      <Img
                        className="h-6 ml-2"
                        src="images/img_arrowright.svg"
                        alt="arrow_right"
                      />
                    }
                    size="xl"
                    variant="gradient"
                    color="indigo_800_green_600"
                  >
                    <div className="font-bold text-center text-lg">
                      Submit
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="bg-blue_gray-100_05 flex md:flex-1 flex-col items-center justify-center p-[222px] md:px-10 sm:px-5 w-[49%] md:w-full">
              <Img
                className="h-[60px] my-[53px] w-[60px]"
                src="images/img_play_gray_800.svg"
                alt="play"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestdemoPage;
