import React from "react";
import { Link } from "react-router-dom";
import { Img, Line, Text } from "components";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;

const HOMEPAGEElevate = (props) => {
  return (
    <>
      <div className={props.className}>
        <img id="qms" src="./qms.png" alt="elevateqms" />
        <div className="qmsLine"></div>
        <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between max-w-[full] w-full">
          <div className="flex flex-1 flex-col items-start justify-start max-w-[721px] w-full">
            <div className="flex flex-col items-start justify-start max-w-[721px] w-full">
              <div className="flex flex-col gap-1.5 items-start justify-start w-auto md:w-full">
                <div className="flex flex-col items-start justify-start max-w-[661px] w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <div className="head">
                      <Text
                        className="sm:text-[38px] md:text-[44px] text-[48px] text-green-600 w-auto"
                        size="txtBrunoAceRegular52"
                      >
                        {props?.elevate}
                      </Text>
                    </div>
                  </Reveal>
                </div>
                <Reveal keyframes={SlideFade} duration="700">
                  <Text
                    className="text-xl md:text-[22px] text-gray-50 sm:text-xl tracking-[0.48px] w-auto"
                    size="txtInterBold24Gray50"
                  >
                    {props?.quaitymanagemenOne}
                  </Text>
                </Reveal>
              </div>
            </div>
          </div>
          <Reveal keyframes={SlideFade} delay="70">
            <Link to="/QMS">
              <Img
                className="h-12 w-12"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
            </Link>
          </Reveal>
        </div>
        <Line className="bg-white-A700_99 h-px w-full" />
        <Text
          className="leading-[28.00px] max-w-[1206px] md:max-w-full text-gray-50 text-lg"
          size="txtInterRegular20"
        >
          {props?.description}
        </Text>
        <div className="flex flex-col  gap-2 items-start justify-start w-full md:w-full">
          <Text
            className="text-lg text-white-A700 w-auto"
            size="txtInterRegular18WhiteA700"
          >
            {props?.features}
          </Text>
          <div
            className="flex sm:flex-col  md:flex-col  md:gap-10 items-center justify-between max-w-[full] w-full"
            // style={{ gap: 95 }}
          >
            <div className="flex md:flex-1  items-start justify-start w-full md:w-full">
              <div className="flex  sm:flex-col gap-[30px] items-start justify-start w-full">
                <div className="flex flex-col gap-3 items-start justify-start w-full">
                  <Reveal keyframes={slideUp} delay="20">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopm}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="30">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmOne}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="40">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmTwo}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="50">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmThree}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="60">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmFour}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="70">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmFive}
                      </Text>
                    </div>
                  </Reveal>
                </div>
                <div className="flex flex-col gap-3 items-start justify-start w-full">
                  <Reveal keyframes={slideUp} delay="20">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmSix}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="30">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmSeven}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="40">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmEight}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="50">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmNine}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="60">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmTen}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="70">
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmEleven}
                      </Text>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            <Reveal keyframes={slideUp} delay="20">
              <Img
                className="md:flex-1 h-[250px] sm:h-auto object-cover sm:ml-[-25px] md:ml-[-30px] md:w-[340px] w-[340px] "
                src="images/Elevate final.png"
                alt="imagesremovebgp"
              />
            </Reveal>
          </div>
        </div>
      </div>
    </>
  );
};

HOMEPAGEElevate.defaultProps = {
  elevate: "Elevate",
  quaitymanagemenOne: "Quaity Management System (QMS)",
  description:
    "Demonstrate your excellence in quality governance using user-friendly, integrated and mature processes for fostering culture of quality",
  features: "Features:",
  contentdevelopm: "Automated Workflows",
  contentdevelopmOne: "Real Time Monitoring & Control",
  contentdevelopmTwo: "Integrated Data Management",
  contentdevelopmThree: "High Compliance",
  contentdevelopmFour: "High Responsiveness",
  contentdevelopmFive: "High Responsiveness",
  contentdevelopmSix: "High Transparency",
  contentdevelopmSeven: "High Reliablity",
  contentdevelopmEight: "Robust Escalation Mechanism",
  contentdevelopmNine: " Ready Configuration",
  contentdevelopmTen: "Speedy Deployment",
  contentdevelopmEleven: "Efficient Data Retrieval & Audit Ready",
};

export default HOMEPAGEElevate;
