import React, {useEffect} from "react";
import { gsap } from "gsap";
import { Button, Img, Text } from "components";
import "./QMSC3.css";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const ProductsQMSC3 = (props) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(".line3", {
      height: 200,
      scrollTrigger: {
        scrub: 2,
        trigger: ".line3",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);
  return (
    <>
      <div className={props.className}>
        <span id="qc">
        <i class="ri-empathize-line"></i>
        </span>
        <div className="line3"></div>
        <div className="flex flex-col gap-2 items-start justify-start w-[416px] sm:w-full">
          <div className="flex flex-col items-center justify-start w-16">
            <div className="flex flex-col items-center justify-start w-16">
              <div className="flex flex-col h-16 items-center justify-start w-16">
                <Img
                  className="h-16 md:h-auto object-cover w-16"
                  src="images/img_maskgroup_8.png"
                  alt="maskgroup_Two"
                />
              </div>
            </div>
          </div>
          <Text
            className="sm:text-2xl md:text-[26px] text-[28px] text-gray-50 w-auto"
            size="txtInterBold28"
          >
            {props?.qualityculture}
          </Text>
        </div>
        <div className="flex md:flex-col   sm:flex-col  md:gap-[49px] sm:gap-0 items-center  justify-between max-w-[full] w-full">
          <div className="flex sm:flex-1 flex-col gap-6 sm:gap-0 items-start justify-center w-auto sm:w-full">
          <Reveal keyframes={slideUp} delay="50">

            <Text
              className="leading-[20.00px] max-w-[590px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
              size="txtInterRegular18"
            >
              {props?.descriptiontext}
            </Text>
            </Reveal>
          <Reveal keyframes={slideUp} delay="70">

            <Button
              className="cursor-pointer sm:mt-[50px] font-inter min-w-[480px] sm:min-w-full text-base  md:text-sm sm:text-sm text-center"
              shape="round"
              color="white_A700_14"
              size="md"
              variant="fill"
            >
              {props?.buttontext}
            </Button>
            </Reveal>
          </div>
          <Reveal keyframes={slideUp} delay="90">

          <Img
            className="h-10 sm:invisible w-10"
            src="images/img_phflowarrow.svg"
            alt="phflowarrow_Two"
          />
          </Reveal>
          <div className="flex flex-col items-center justify-start ml-[20px] rounded-[12px] shadow-bs8">
          <Reveal keyframes={slideUp} delay="120">

            <div className="bg-gradient3  flex flex-col items-center  justify-center p-6 sm:px-5 rounded-[12px] w-auto sm:w-full">
              <Text
                className="leading-[20.00px] max-w-[537px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
                size="txtInterRegular18"
              >
                {props?.descriptiontexttwo}
              </Text>
            </div>
            </Reveal>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSC3.defaultProps = {
  qualityculture: "Quality Culture",
  descriptiontext:
    "Cultural resistance can lead to disruption of existing of workflows and processes, employees may be hesitant to adapt change which may lead to non-compliance",
  buttontext: "Absence of self regulation, lack in consistent product quality",
  descriptiontexttwo: (
    <>
      Qssence products fosters culture of quality by bringing transparency,
      awareness, and accountability.
      <br />
      The advance analytics constantly helps the organizations with greater
      insights for data driven decision making & continuous improvements.
    </>
  ),
};

export default ProductsQMSC3;
