import React, { useEffect } from 'react'
import { keyframes } from "@emotion/react";
import Reveal from 'react-awesome-reveal';
import { Img, Button, List, Text } from "components";
import './lms.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
// const slideRight = keyframes`
//         from {
//             opacity: 0;
//             transform: translate(90%, 0);
//         }

//         to {
//             opacity: 1;
//             transform: translate(0, 0);
//         }
//     `;
// const upFade = keyframes`
//         from {
//             opacity: 0;
//             transform: translate(0, 50%);
//         }

//         to {
//             opacity: 1;
//             transform: translate(0, 0%);
//         }
//     `;
const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;

const rightFade = keyframes`
        from {
            opacity: 0;
            transform: translate(90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;


export default function LMS() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline();
        tl.to(".lmsbenifits", {
            height: 370,
            scrollTrigger: {
                scrub: 2,
                trigger: ".line1",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play",
            },
        })
    }, []);
    return (
        <div >
            <div className="sm:h-[0px] md:h-[0px] md:invisible sm:invisible bg-blue_gray-50  flex flex-col font-dmsans items-center justify-start mx-auto  sm:w-full md:w-full w-full">

                <div className="sm:invisible md:invisible bg-gray-900_01 font-inter  h-[650px] mt-28  md:h-[800px] sm:h-[1100px] md:px-5 pb-[98px] pt-[70px] relative w-full">
                    <div className="ml-20 pt-3 pb-8 sm:hidden">
                        <Reveal keyframes={SlideFade} duration="700">
                            <Text
                                className=" left-[5%] sm:text-[38px]  md:text-[44px]   text-[48px] text-white-A700 top-[10%]"
                                size="txtInterBold52WhiteA700"
                            >
                                <span className="text-white-A700 font-brunoace ml-[15px] text-left font-normal">
                                    Aspire
                                </span>
                                <span className="text-green-600 font-brunoace ml-[15px] text-left font-normal">
                                    Suite
                                </span>
                            </Text>
                        </Reveal>
                    </div>
                    <div className=" h-[1024px]  md:h-[900px] sm:h-[875px] inset-[0] justify-center  sm:mt-28 w-full">
                        <div className="absolute  flex flex-col md:gap-10 gap-20 inset-x-[0] items-center justify-start mx-auto w-auto md:w-full">
                            <div className="flex sm:invisible sm:hidden flex-col items-center justify-start md:w-full w-full">
                                <div className="flex flex-col items-center justify-start md:w-full w-full">
                                    <div className="flex flex-col items-start justify-center w-full mt-0 px-20 md:w-full">
                                        <div className="bg-white-A700_14  flex flex-col  items-start justify-start max-w-full  sm:px-5 mx-auto   py-15 rounded-[40px] md:w-full w-full">
                                            <Reveal keyframes={SlideFade} duration="700">
                                                <Text
                                                    className="md:text-2xl sm:text-[28px] ml-[25px] text-[28px] text-gray-50 tracking-[0.32px] pt-[14px] w-auto"
                                                    size="txtInterBold32"
                                                >
                                                    <span className="text-gray-50 font-inter text-left font-bold ml-[25px]">
                                                        Qssence{" "}
                                                    </span>
                                                    <span className="text-green-600 font-inter text-left font-bold">
                                                        LMS Modules
                                                    </span>
                                                </Text>
                                            </Reveal>
                                            <div className="flex relative w-full">
                                                <div className="md:h-[495px] h-[480px]  w-[50%] sm:w-full">
                                                    <Img
                                                        className="absolute h-[480px] inset-[0] justify-center   rounded-[83px] w-[85%]"
                                                        src="images/QMS_Module1.webp"
                                                        alt="images/QMS_Module1.png"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-16 mb-[28px] pb-[56px] justify-start  my-auto w-[57%] z-[1]">
                                                    <List
                                                        className="flex flex-col gap-10 items-center ml-2 md:ml-[0] w-[99%]"
                                                        orientation="vertical"
                                                    >
                                                        <div className="flex flex-col gap-2.5  justify-start w-[105%] md:w-[110%] ml-[20px]  md:w-full">
                                                            <div className="flex sm:flex-col flex-row gap-10  changeControlQMS md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Change control
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Deviation
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Out Of Specification
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-[9px] justify-start w-full">
                                                            <div className="flex sm:flex-col flex-row mb-[9px] sm:gap-10 gap-[45px] outOfTrendQMS items-center ml-[60px] justify-start md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px]  text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Out Of <br />
                                                                            Trend
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Root Cause <br />
                                                                            Investigation
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Corrective Actions
                                                                            <br /> & Preventive Actions
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-[9px] justify-start w-[92%] md:w-full">
                                                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[40px] internalAuditQMS items-start ml-[1px] justify-start md:ml-[0] w-auto sm:w-full">
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Internal <br />
                                                                        Audit
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Complaint <br />
                                                                            Management
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Regulatory <br /> Audit
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-2.5 justify-start w-[92%] w-full">
                                                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[60px] CustomerNotificationQMS  md:h-auto items-start justify-start  md:ml-[0] w-[559px] sm:w-full">
                                                                <div className="flex flex-col ml-[40px] items-center justify-center w-auto">
                                                                    <Text
                                                                        className="leading-[28.00px] text-center text-gray-50 text-lg md:text-base sm:text-sm"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        <>
                                                                            Customer
                                                                            <br />
                                                                            Notification
                                                                        </>
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Quality <br />
                                                                        Reviews
                                                                    </Text>
                                                                </div>
                                                                <div className="flex flex-col items-center justify-center w-auto">
                                                                    <Text
                                                                        className="text-center text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                                                                        size="txtInterRegular20"
                                                                    >
                                                                        Vendor <br />
                                                                        Complaints
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-blue_gray-50 overflow-hidden sm:mt-[290px]  flex flex-col font-inter items-center justify-start max-w-full  pt-[110px] sm:pt-0 md:w-full  w-full">
                <div className=" flex flex-col md:gap-10 gap-[30px] items-center justify-start w-full md:w-full">
                    <div className=" flex md:flex-col sm:flex-col gap-1  mt-[-200px]  md:mt-[0px] sm:mt-[-120px] items-center justify-between  max-w-full mx-auto md:px-5 md:w-full w-full">
                        {/* img start  */}
                        <div className="flex relative md:flex-1 flex-col items-center  justify-start w-[60%]  sm:w-auto md:w-full">
                            <div className="flex flex-col md:gap-10 gap-[60px] ml-16 sm:ml-0 items-center justify-start w-full md:w-full">
                                <Reveal keyframes={SlideFade} duration="700">
                                    <div className="flex  h-[520px] w-[800px] md:w-[600px] relative sm:right-0 right-[160px]  sm:w-full w-full">
                                        <Img
                                            className=" h-[600px] sm:h-[500px] md:h-[550px] sm:w-full   inset-y-[0] my-auto  right-[0] sm:right-10 rounded-sm md:w-full w-full"
                                            src="images/Aspire dashboard.png"
                                            alt="imageEleven"
                                        />
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                        {/* img end  */}
                        <div className="flex  flex-col gap-3 items-start justify-start w-[472px] sm:w-full">
                            <Reveal keyframes={rightFade} duration="700">

                                <div className="font-brunoace md:h-[73px] h-[98px] relative  w-[472px] sm:w-full">
                                    <Text
                                        className=" left-[0] md:text-5xl text-5xl text-green-600 top-[0] w-auto"
                                        size="txtBrunoAceRegular60"
                                    >
                                        Aspire
                                    </Text>
                                    <div className="bottom-[0] flex flex-col font-inter inset-x-[0] items-center justify-start mx-auto w-[472px] sm:w-full">
                                        <div className="flex flex-col items-start justify-start w-[472px] sm:w-full">
                                            <Text
                                                className="text-xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                                                size="txtInterBold24"
                                            >
                                                Learning Management System (LMS)
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                    <div className="bg-gray-900_01 flex  flex-col gap-10 items-start justify-start  md:px-10 px-20 sm:px-5 py-[20px] w-full ">

                        <div className="flex sm:flex-col  relative md:gap-10 gap-[63px] items-center justify-between max-w-full w-full">
                            <Text
                                className="leading-[24.00px] text-gray-50 text-base w-[60%] sm:w-full "
                                size="txtInterRegular18"
                            >
                                <>
                                    The life science sector , being inherently knowledge-driven,
                                    requires a substantial level of expertise  and competence , which,
                                    in its own right , nurtures creative capabilities . Conse-quently,
                                    the industry prioritizes both individual growth and
                                    comprehensive training.Evaluating proficiency and skillsets
                                    should encompass more than just compliance-related aspects.
                                    <br />
                                    <br />
                                    In the life science industry, there is a significant focus on
                                    adopting advanced practices that directly enhance the
                                    effectiveness of pharmaceutical quality systems.Therefore,continuous
                                    improvement involves the repetitive execution of
                                    tasks and activities to enhance one&#39;s skills knowledge, and
                                    proficiency. It necessitates consistent and persistent efforts
                                    over time to achieve mastery in a particular area.{" "}
                                    <br />
                                    <br />
                                    In support of the aforementioned goal,Qssence has developed a
                                    Learning Management  System (LMS) that employs diverse learning
                                    methods tailored to individual learning preferences and what
                                    proves most effective for specific tasks or subjects.
                                </>
                            </Text>


                            {/* <div className="bg-blue_gray-100_05 h-40 w-[66%]"></div> */}
                            <div className="w-[400px] sm:w-full mb-[140px] h-[200px] mx-auto">
                                <Img
                                    className="md:flex-1 h-[405px]  sm:mb-0 sm:h-auto  sm:ml-0  w-[380px] sm:w-[350px] md:w-full"
                                    src="images/DoQssence.webp"
                                    alt="images/DoQssence.png"
                                />
                            </div>
                        </div>
                    </div>
                    {/* challenges  */}
                    <div className="flex flex-col items-start justify-start max-w-[full] mx-auto px-[60px] md:px-5 w-full">
                        <div className="flex flex-col gap-10 items-center justify-start w-full">
                            <div className="flex flex-col items-start px-11 sm:px-0 justify-start max-w-full w-full">
                                <Reveal keyframes={SlideFade} duration="700">
                                    <Text
                                        className="sm:text-[38px] md:text-[44px] font-brunoace text-[47px] text-gray-900_01 w-auto"
                                        size="txtInterBold52Gray90001"
                                    >
                                        <span className="text-green-600  text-left font-bold">
                                            Challenges
                                        </span>
                                        <span className="md:text-[38px] sm:text-4xl text-gray-900_01  text-left text-[40px] font-bold">
                                            {" "}
                                            In Learning Management Process
                                        </span>
                                    </Text>
                                </Reveal>
                            </div>
                            <div className="flex md:flex-col sm:flex-col  gap-2.5 items-start px-9 sm:px-5 justify-between max-w-full  w-full">
                                <Text
                                    className="leading-[24.00px] text-gray-900 text-lg md:text-base sm:text-sm mt-10 "
                                    size="txtInterRegular18Gray900"
                                >
                                    <>
                                        <Reveal keyframes={slideUp} delay="50">

                                            <li>Poor compliance in training management</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="70">

                                            <li>Fragmented training data and documentation</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="90">

                                            <li>Manual tracking and coordination</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="110">

                                            <li>Lack of centralized training management system</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="130">

                                            <li>Inefficient reporting and evaluation processes</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="150">
                                            <li>Unorganized curricula for various roles</li>

                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="170">

                                            <li>Poor governance</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="190">

                                            <li>Lack of analytics for continuous improvements</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="210">

                                            <li>Poor training assessment</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="230">

                                            <li>Ineffective training</li>
                                        </Reveal>
                                        <Reveal keyframes={slideUp} delay="250">

                                            <li>
                                                Level of capability building is not attained to a
                                                satisfactory level
                                            </li>
                                        </Reveal>
                                    </>
                                </Text>
                                <div className="h-[350px] relative w-[39%] md:w-full">
                                    <div className="backdrop-opacity-[0.5] bg-green-600_28 blur-[208.00px] h-[348px] m-auto rounded-[50%] w-full"></div>
                                    <Img
                                        className="absolute  h-[350px] inset-[0] justify-center object-contain rounded-[40px] w-full"
                                        src="images/img_whitepuzzle14.png"
                                        alt="whitepuzzleFourteen"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Features  */}
            <div className="bg-blue_gray-50 flex flex-col font-inter items-start justify-start max-w-ful pb-[30px] pt-[20px] md:px-10 px-20 sm:px-5 w-full">
                <div className="flex flex-col md:gap-10 gap-[20px] items-center justify-start max-w-[full] mx-auto w-full">
                    <div className="flex flex-col items-start sm:px-0 justify-start max-w-full w-full">
                        <Reveal keyframes={SlideFade} duration="700">
                            <Text
                                className="sm:text-[38px] text-green-600 md:text-[44px] font-brunoace text-[47px]  w-auto"
                                size="txtInterBold52Gray90001"
                            >
                                Features

                            </Text>
                        </Reveal>
                    </div>
                    <List
                        className="sm:flex-col flex-row md:gap-2 grid md:grid-cols-1 grid-cols-2 justify-between max-w-full w-full"
                        orientation="horizontal"
                    >
                        <div className=" flex md:flex-1 flex-col gap-2 items-start justify-start w-auto md:w-full">
                            <Reveal keyframes={slideUp} delay="50">

                                <div className=" flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full ">
                                    <div className="name w-[40px]">
                                        <Button
                                            className=" flex h-10 items-center justify-center rounded-[50%] w-10"
                                            shape="circle"
                                            color="green_600"
                                            size="sm"
                                            variant="outline"
                                        >
                                            <div className="name1 ">
                                                <Img
                                                    className="h-11 w-auto "
                                                    src="images/Centralized Repository.png"
                                                    alt="group39773"
                                                />
                                            </div>
                                        </Button>
                                    </div>

                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Centralized repository of Learning Content
                                    </Text>

                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="60">

                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Automated Training workflow (1).png"
                                            alt="group39775"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Automated training workflows
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="70">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className=" name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Schedule, Track And Manage.png"
                                            alt="group39776"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Schedule, track and manage training sessions
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="80">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Computer Based, Classroom.png"
                                            alt="group39777"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Computer Based, Classroom, On the job training
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="90">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Registration And Enrolment.png"
                                            alt="group39778"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Registration and enrolment management
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="100">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Interactive Online Modules.png"
                                            alt="group39779"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Interactive online modules and remote learning
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="110">

                                <div className="flex md:flex-col flex-row gap-4 items-center justify-start w-auto md:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Skill Gap Assessment (1).png"
                                            alt="group39781"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Skill gap assessment , role-based courses and curriculum
                                    </Text>
                                </div>
                            </Reveal>
                        </div>
                        <div className=" flex md:flex-1 flex-col gap-2 items-start  sm:ml-0 justify-start w-auto md:w-full">
                            <Reveal keyframes={slideUp} delay="50">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <div className="name1">
                                            <Img src="images/Tailored Training To Individuals.png" alt="group39782" />
                                        </div>
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Tailored training to individuals and group
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="60">

                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <div className="name1">
                                            <Img src="images/Certificate of compliance.png" alt="group39783" />
                                        </div>
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Certification of compliance
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="70">

                                <div className=" flex md:flex-col flex-row gap-4 items-center justify-start w-auto md:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <div className="name1">
                                            <Img src="images/Ensure Regulatory Compliance.png" alt="group39784" />
                                        </div>
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Ensure regulatory compliance and track expiration dates
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="80">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <div className="name1">
                                            <Img src="images/Scalability And Customization.png" alt="group39785" />
                                        </div>
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Scalability and customization, mobility features
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="90">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Lack of adoptable.png"
                                            alt="group39786"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Integration capability and interoperability
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="100">

                                <div className="flex sm:flex-col flex-row gap-4 items-center justify-start w-auto sm:w-full">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%]  w-[40px]"
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/img_group39787.png"
                                            alt="group39787"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Advance analytics and audit readiness
                                    </Text>
                                </div>
                            </Reveal>
                            <Reveal keyframes={slideUp} delay="110">

                                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                                    <Button
                                        className="name flex h-10 items-center justify-center rounded-[50%] "
                                        shape="circle"
                                        color="green_600"
                                        size="sm"
                                        variant="outline"
                                    >
                                        <Img
                                            className="h-7 name1"
                                            src="images/Track Attendance And Waitlist.png"
                                            alt="group39788"
                                        />
                                    </Button>
                                    <Text
                                        className="capitalize text-gray-900 text-xl md:text-base sm:text-sm w-auto"
                                        size="txtInterRegular20Gray900"
                                    >
                                        Track Attendance and waitlist
                                    </Text>
                                </div>
                            </Reveal>
                        </div>
                    </List>
                </div>
            </div>
            {/* benifits  */}
            <div className="bg-gray-900_01 flex flex-col  font-inter md:gap-10 gap-[20px] relative items-start justify-start max-w-full md:px-10 px-20 sm:px-5 py-[20px] w-full">
                <span id="lmsBenifits">
                    <i class="ri-open-arm-line"></i>
                </span>
                <div className="lmsbenifits"></div>
                <div className="flex flex-col items-center justify-start">
                    <Reveal keyframes={SlideFade} duration="700">
                        <Text
                            className="capitalize sm:text-[38px] sm:pl-[10px] font-brunoace md:text-[44px] text-[48px] text-green-600"
                            size="txtInterBold52Green600"
                        >
                            Benefits
                        </Text>
                    </Reveal>
                </div>
                {/* <List
                    className="flex flex-col gap-[20px] items-center max-w-[1356px] mx-auto w-full"
                    orientation="vertical"
                >
                    <div className="md:flex-1 gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-center w-full md:w-full">
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-1.5 items-center justify-start w-[92%] md:w-full">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[47%] sm:w-full"
                                    src="images/Comp 2.gif"
                                    alt="settingresearch"
                                />
                                <Text className="text-gray-50 text-xl" size="txtInterRegular20">
                                    Quick To Deploy
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Advance Analytics (2).png"
                                    alt="settingresearch_One"
                                />
                                <Text
                                    className="text-gray-50 text-center text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Advanced Analytics & Governance
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col  gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 1.gif"
                                    alt="settingresearch_Two"
                                />
                                <Text
                                    className="text-gray-50 text-center text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Improved Efficiency & Effectiveness
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex-1 gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-center w-full md:w-full">
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-1.5 items-center justify-start w-[92%] md:w-full">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[47%] sm:w-full"
                                    src="images/Comp 4.gif"
                                    alt="settingresearch"
                                />
                                <Text className="text-gray-50 text-center text-xl" size="txtInterRegular20">
                                    Simple & Less Complex Learning Paths
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 5.gif"
                                    alt="settingresearch_One"
                                />
                                <Text
                                    className="text-gray-50 text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Distraction Free Learning
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 3.gif"
                                    alt="settingresearch_Two"
                                />
                                <Text
                                    className="text-gray-50 text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Goal Based Assessment
                                </Text>
                            </div>
                        </div>
                    </div>
                </List>  */}
                <List className="flex flex-col gap-[20px] items-center max-w-[full] mx-auto w-full" orientation="vertical">
                    <div className="md:flex-1 gap-[30px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-center w-full md:w-full">
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-1.5 items-center justify-start w-[92%] md:w-full">
                                <Img className="h-[168px] md:h-auto object-cover w-[47%] sm:w-full" src="images/Comp 2.gif" alt="settingresearch" />
                                <Text className="text-gray-50 text-xl" size="txtInterRegular20">
                                    Quick To Deploy
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full" src="images/Advance Analytics (2).png" alt="settingresearch_One" />
                                <Text className="text-gray-50 text-center text-xl w-auto" size="txtInterRegular20">
                                    Advanced Analytics & Governance
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col  gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 1.gif"
                                    alt="settingresearch_Two"
                                />
                                <Text
                                    className="text-gray-50 text-center text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Improved Efficiency & Effectiveness
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-1.5 items-center justify-start w-[92%] md:w-full">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[47%] sm:w-full"
                                    src="images/Comp 4.gif"
                                    alt="settingresearch"
                                />
                                <Text className="text-gray-50 text-center text-xl" size="txtInterRegular20">
                                    Simple & Less Complex Learning Paths
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 5.gif"
                                    alt="settingresearch_One"
                                />
                                <Text
                                    className="text-gray-50 text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Distraction Free Learning
                                </Text>
                            </div>
                        </div>
                        <div className="bg-white-A700_0a cursor-pointer hover:bg-gradient-to-r  from-transparent to-green-600_1e flex flex-col h-[267px] md:h-auto items-center justify-start p-6 sm:px-5 rounded-[12px] w-full">
                            <div className="flex flex-col gap-[3px] items-center justify-start w-auto">
                                <Img
                                    className="h-[168px] md:h-auto object-cover w-[164px] sm:w-full"
                                    src="images/Comp 3.gif"
                                    alt="settingresearch_Two"
                                />
                                <Text
                                    className="text-gray-50 text-xl w-auto"
                                    size="txtInterRegular20"
                                >
                                    Goal Based Assessment
                                </Text>
                            </div>
                        </div>
                    </div>

                    {/* Repeat the above structure for the remaining items */}
                </List>

            </div>
            {/* responsve design laptop  */}
            <div className="flex bg-white-A700_01 flex-col font-inter items-end  md:px-10 sm:px-5 sm:py-[50px] px-[127px] w-full">
                <div className="h-[611px]  relative w-[93%]  sm:h-[500px] md:w-full sm:w-full">
                    <Img
                        className="h-[611px] sm:h-[300px] mx-auto sm:w-auto sm:object-cover  w-[60%]"
                        src="images/final 1.png"
                        alt="istockphoto485"
                    />
                    <Text
                        className="absolute bottom-[5%] mx-auto leading-[32.00px] left-[20%] md:left-[8%] sm:text-2xl md:text-[26px] text-[28px] text-center text-gray-900"
                        size="txtInterRegular28"
                    >
                        <span className="md:text-[38px] sm:text-4xl text-gray-900 font-inter text-[38px] font-bold">
                            Multi-Platform Compatibility
                        </span>
                        <span className="text-gray-900 font-inter font-normal">
                            <>
                                {" "}
                                <br />
                            </>
                        </span>
                        <span className="md:text-[22px] text-gray-900 font-inter text-xl md:text-lg sm:text-base font-normal">
                            with different operating systems such as iOS, Android, Windows, or
                            macOS
                        </span>
                    </Text>
                </div>
            </div>
        </div>
    )
}
