import React from "react";

import { Img} from "components";
import './homepagecomp.css';
const HOMEPAGEComp = (props) => {
  return (
    <>
      <div
        className={props.className}
        // style={{ backgroundImage: "url('images/img_comp.png')" ,opacity:0.7}}
        >
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-5 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent mb-3 cursor:pointer md:w-full  sm:w-[full] w-[600px]"
          >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Backed by Industry Experts
          </div>
        </div>
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 mb-3 hover:bg-transparent md:w-full  sm:w-[full] w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Frugal
          </div>
        </div>
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent mb-3 md:w-full sm:w-[full]  w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Rapid Deployment
          </div>
          
        </div>
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent mb-3 md:w-full  sm:w-[full] w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Reliablity
          </div>
        </div>
        <div
          className="bg-white-A700_14 border  flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent mb-3 md:w-full  sm:w-[full] w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Early Adoption
          </div>
        </div>
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent mb-3 md:w-full sm:w-[full] w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Maximally Efficient
          </div>
        </div>
        <div
          className="bg-white-A700_14 border flex border-gray-50_66 border-solid h-0  gap-6 items-center justify-start px-4 py-7 rounded-[12px] shadow-bs3 hover:bg-transparent pointer md:w-full  sm:w-[full] w-[600px]"
        >
          <div>
            <Img
              className="h-8 mr-5 my-auto"
              src="images/img_checkmark.svg"
              alt="checkmark"
            />
          </div>
          <div
            className="heading-name bg-light"
            style={{ color: "#FFFFFF", fontSize: 19 }}
          >
            Agility / Flexiblity
          </div>
        </div>

         
        </div>

    </>
  );
};


export default HOMEPAGEComp;


// {/* // HOMEPAGEComp.defaultProps = { noise: "images/img_noise.png" }; */}
