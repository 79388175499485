import React, { useState, useEffect } from "react";
import { Text } from "components";
import { useInView } from "react-intersection-observer";

const HOMEPAGEColumnwarningletters = (props) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    let currVal = 0;
    if (inView) {
      const targetValue = parseInt(props.onehundredone);
      const interval = setInterval(() => {
        if (currVal <= targetValue) {
          setAnimatedValue(currVal);
          currVal++;
        } else {
          clearInterval(interval);
          setAnimationCompleted(true);
        }
      }, 20);
      return () => {
        clearInterval(interval);
      };
    } else {
      // If not in view, set the number to 0
      setAnimatedValue(0);
    }
  }, [inView, props.onehundredone]);

  return (
    <div className={props.className} ref={ref}>
      {/* Rest of your component */}
      <div className="border-[10px] border-indigo-800 border-solid flex flex-col h-[120px] items-center justify-start p-[25px] sm:px-5 rounded-[50%] shadow-bs4 w-[120px]">
            <Text
            className={` sm:text-[38px] md:text-[44px] text-[40px] text-center ${
              animationCompleted ?"text-green-600": "text-black-900" 
            } value`}
            size="txtInterBold52Gray800"
          >
            {animatedValue}
          </Text>
        </div>
        <Text
          className="text-center text-gray-900_01 text-base w-auto"
          size="txtInterRegular18Gray90001"
        >
          {props?.warningletterstext}
        </Text>
    </div>
  );
};

HOMEPAGEColumnwarningletters.defaultProps = {
  onehundredone: "101",
  warningletterstext: "Warning letters issued by FDA",
};

export default HOMEPAGEColumnwarningletters;
