import React, { useEffect } from "react";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from '@emotion/react';
import { Img, Text } from "components";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./vision.css";
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;


const ABOUTUSVision = (props) => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    tl.to(".ourVision", {
      height: 140,
      scrollTrigger: {
        scrub: 5,
        trigger: ".ourVision",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);

  return (
    <>
      <div className={props.className}
      >
        <span id="vision">
        <i class="ri-focus-3-line"></i>
        </span>
        <div className="ourVision"></div>
        <div className="flex  flex-col gap-5 items-start justify-start w-auto md:w-full ">
          <Reveal keyframes={SlideFade} duration="700">

            <div className="flex flex-row gap-6 sm:gap-3 items-center justify-start w-[550px] md:w-[500px] sm:w-full ">
              {/* <Img
                className="h-16 w-[61px]"
                src="images/img_mdieyeoutline.svg"
                alt="mdieyeoutline"
              /> */}
              {props?.visionlabel}
            </div>
          </Reveal>
          <div className="flex flex-col items-center justify-start w-full">
            <div className="w-full">
              <Text
                className="leading-[28.00px] text-gray-50 text-lg md:text-base sm:text-sm w-full"
                size="txtInterRegular20"
              >
                {props?.userdescription}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ABOUTUSVision.defaultProps = {
  visionlabel: (
    <Text
      className="sm:text-4xl md:text-[38px] text-[40px] text-gray-50 tracking-[0.20px] w-[215px] "
      size="txtInterBold40Gray50"
    >
      <span className="text-gray-50 font-inter text-left font-bold">Our </span>
      <span className="text-gray-50 font-inter text-left font-bold">
        Vision
      </span>
    </Text>
  ),
  userdescription: (
    <>
      To empower pharmaceutical and life sciences companies with cutting-edge,
      intuitive, scalable, and robust software solutions compounded with rapid
      deployment to revolutionise quality management processes.
      <br />
      <br />
      Dedicate to staying at the forefront of technological advancements,
      continuously improving our products to address the evolving needs and
      challenges of the industry.
    </>
  ),
};

export default ABOUTUSVision;
