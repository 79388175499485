import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackdocumentmanagemOne = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="card md:h-[300px]">
          <div className="content">
            <div className="front border border-green-600 border-solid flex flex-col md:h-full h-full items-center justify-center mx-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col gap-[11px] justify-start w-[full] bg-transparent rounded-[24px] ">
                <Img
                  className="h-20 md:h-auto md:ml-[0] ml-[19px] object-cover w-20"
                  src="images/img_image14.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] text-center text-gray-900 sm:text-lg"
                  size="txtInterRegular24"
                >
                  {props?.documentmanagemOne}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] rounded-[24px]  sm:text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-base md:text-sm"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    Template lifecycle managment
                  </li>
                  <li style={{ lineHeight: "1.5" }}>Contect creation</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Workflow for Review Coilabration and document finalization
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Approval using electronic signatures
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Verion control and audit trail capability
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Advance search and document retrieval
                  </li>
                  <li style={{ lineHeight: "1.5" }}>Print controls</li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackdocumentmanagemOne.defaultProps = {
  // description: (
  //   <>
  //     Deviation reporting and immediate actions
  //     <br />
  //     Correction, QA review & approval
  //     <br />
  //     Initial risk classification and escalation
  //     <br />
  //     Investigation and root cause determination
  //     <br />
  //     Final classification and escalation <br />
  //     QA review and final disposition
  //   </>
  // ),
  documentmanagemOne: (
    <>
      Document
      <br />
      Management
    </>
  ),
};

export default ProductsQMSStackdocumentmanagemOne;
