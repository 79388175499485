import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackauditmanagementOne = (props) => {
  return (
    <>
      <div className={props.className}>
        {/* <div className="card">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col gap-[19px] justify-start w-[full] bg-transparent">
                <Img
                  className="h-20 md:h-auto md:ml-[0] ml-[19px] mx-auto object-cover w-20"
                  src="images/img_investigationi.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] text-gray-900 sm:text-xl"
                  size="txtInterRegular24"
                >
                  {props?.auditmanagementOne}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                size="txtInterRegular18"
              >
                <li>Audit scheduler</li>
                <li>Integrated for internal  , <br />&nbsp;&nbsp;&nbsp;&nbsp;external, regulatory audit</li>
                <li>compliance report workflow<br />&nbsp;&nbsp;&nbsp;&nbsp; & traiking</li>
                <li>integrated with other QMS <br />&nbsp;&nbsp;&nbsp;&nbsp;modules (change <br />&nbsp;&nbsp;&nbsp;&nbsp;control,training,etc.)</li>
                <li>compliance task tracker</li>
                <li>Escalation of task delays</li>
                <li>Effectiveness plan & its<br />&nbsp;&nbsp;&nbsp;&nbsp; execution</li>

              </Text>
            </div>
          </div>
        </div> */}
        <div className="card md:h-[300px]">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col justify-start w-[50%] bg-transparent rounded-[24px] ">
                <Img
                  className="h-20 md:h-auto md:ml-[0] ml-[19px] mx-auto object-cover w-20"
                  src="images/img_investigationi.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] mb-[20px] ml-[18px] text-gray-900 sm:text-lg"
                  size="txtInterRegular24"
                >
                  {props?.auditmanagementOne}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-full h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] rounded-[24px] sm:text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-base md:text-sm"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>Audit scheduler</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated for internal, external, regulatory audit
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Compliance report workflow & tracking
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated with other QMS modules (change control, training,
                    etc.)
                  </li>
                  <li style={{ lineHeight: "1.5" }}>Compliance task tracker</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Escalation of task delays
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Effectiveness plan & its execution
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackauditmanagementOne.defaultProps = {
  auditmanagementOne: <>Audit Management</>,
};

export default ProductsQMSStackauditmanagementOne;
