import React from "react";

import { Button, Img, Text } from "components";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "./style.css";

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;

const ProductsDMSChal3 = (props) => {
  return (
    <>
      <div className={props.className}>
        <span id="ic">
          <i class="ri-box-1-line"></i>
        </span>
        <div className="dmsindustry" ></div>
        <div className="flex flex-col md:gap-10 gap-[10px] items-start justify-start max-w-full w-full">
          <Reveal keyframes={SlideFade} duration="700">
            <div className="flex flex-col items-start justify-start w-auto sm:w-full">
              {props?.industrychallenOne}
            </div>
          </Reveal>
          <div className="flex flex-col items-start justify-center w-full md:w-full">
            <div className="md:gap-5 gap-[10px] grid md:grid-cols-1 grid-cols-2 justify-between min-h-[auto] w-full">
              <Reveal keyframes={slideUp} delay="50">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">
                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                      >
                      <div className="name1">
                        <Img
                          className="h-6"
                          src="images/img_group39797.png"
                          alt="group39797"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthroughOne}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="60">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    ><div className="name1">
                        <Img
                          className="h-6"
                          src="images/img_group39802.png"
                          alt="group39802"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="70">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">
                        <Img
                          className="h-6"
                          src="images/img_group39805.png"
                          alt="group39805"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough1}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="80">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39803.png"
                          alt="group39803"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough2}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="90">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39804.png"
                          alt="group39804"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough3}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="100">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39799.png"
                          alt="group39799"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough4}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="100">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39800.png"
                          alt="group39800"
                        />
                      </div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.timezone}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="110">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39798.png"
                          alt="group39798"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough5}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="120">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >
                      <div className="name1">

                        <Img
                          className="h-6"
                          src="images/img_group39801.png"
                          alt="group39801"
                        />
                      </div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough6}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="130">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    ><div className="name1">
                        <Img
                          className="h-6 md:h-auto object-cover w-6"
                          src="images/img_maskgroup_24x24.png"
                          alt="maskgroup"
                        />
                      </div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough7}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="140">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    ><div className="name1">
                        <Img
                          className="h-6"
                          src="images/img_group39806.png"
                          alt="group39806"
                        />
                      </div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough8}
                  </Text>
                </div>
              </Reveal>
              <Reveal keyframes={slideUp} delay="150">

                <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                  <div className="name">

                    <Button
                      className="flex h-11 items-center justify-center rounded-[50%] w-11"
                      shape="circle"
                      color="gray_50"
                      size="md"
                      variant="outline"
                    >

                      <div className="name1">
                        <Img
                          className="h-6"
                          src="images/img_group39793.png"
                          alt="group39793"
                        /></div>
                    </Button>
                  </div>
                  <Text
                    className="text-gray-50 text-lg md:text-base sm:text-sm w-auto"
                    size="txtInterRegular18"
                  >
                    {props?.datalossthrough9}
                  </Text>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsDMSChal3.defaultProps = {
  industrychallenOne: (
    <Text
      className="sm:text-[38px] md:text-[44px] font-brunoace text-[52px] text-gray-50 w-auto"
      size="txtInterBold52Gray50"
    >
      <span className="text-gray-50  text-left font-bold">
        Industry{" "}
      </span>
      <span className="text-green-600  text-left font-bold">
        Challenges
      </span>
    </Text>
  ),
  datalossthroughOne: "Data loss through loss / damage of paperwork",
  datalossthrough: "Tracking paper documents and storing them",
  datalossthrough1: "Poor audit trail while handling paper documents",
  datalossthrough2: "High cost of maintenance of paper documents",
  datalossthrough3: "Not knowing who is revising what and when",
  datalossthrough4: "Usage of dated documents",
  timezone: "Tedious method of document sign-off",
  datalossthrough5: "Lack of proper tracking for document revision",
  datalossthrough6: "Errors in documents and its lifecycle management",
  datalossthrough7: "Knowledge sharing using paper document is time consuming",
  datalossthrough8: "Not environment friendly",
  datalossthrough9: "Lack of Transparency",
};

export default ProductsDMSChal3;
