import React from "react";

import { Img, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const slideUp = keyframes`
from {
    opacity: 0;
    transform: translateY(-90%, 0);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
const ServicesSd = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-6 items-center mt-[-27px] justify-center sm:w-full w-full">
          <Img
            className="h-[60px] w-[60px]"
            src="images/groupChat.png"
            alt="car"
          />
          <div className="flex flex-col gap-6 items-center justify-start w-full">
          <Reveal keyframes={slideUp} delay="70">

            <Text
              className="leading-[32.00px] text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl w-full"
              size="txtInterRegular24Gray50"
            >
              {props?.customizedconsultingtext}
            </Text>
            </Reveal>
            <Text
              className="leading-[24.00px] text-base text-center text-gray-50 w-full"
              size="txtInterRegular16"
            >
              {props?.descriptiontext}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

ServicesSd.defaultProps = {
  customizedconsultingtext: "Customized Consulting ",
  descriptiontext:
    "Customized consulting should be pruned to: Tailor consulting services to address the unique needs of each client.",
};

export default ServicesSd;
