import React from "react";

import { Img, Text } from "components";

const ProductsDMSColumnTwo = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-8 items-center deploymentParent justify-center w-[244px]">
          <div className="flex flex-col h-[114px] items-center justify-start w-[114px]">
            <Img
              className="h-[114px] md:h-auto object-cover w-[114px]"
              src={props.imageUrl}
              alt="maskgroup_Two"
            />
          </div>
          <Text
            className="text-2xl md:text-[22px] deployment text-center  md:mb-[15px] text-gray-50 sm:text-xl w-full"
            size="txtInterRegular24Gray50"
          >
            {props?.speedofdeployme}
          </Text>
        </div>
      </div>
    </>
  );
};

ProductsDMSColumnTwo.defaultProps = { speedofdeployme: "Audit Readiness" };

export default ProductsDMSColumnTwo;
