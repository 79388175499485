import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackchangecontrol = (props) => {
  return (
    <>
      <div className={props.className}>
        {/* <div className="card">
                    <div className="content">
                        <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
                            <div className="flex flex-col gap-[20px] sm:gap-y-5 justify-start w-[auto] bg-transparent">
                                <Img
                                    className="h-20 md:h-auto md:ml-[0] mx-auto ml-[19px] object-cover w-20"
                                    src="images\img_maskgroup_14.png"
                                    alt="imageTwelve"
                                />
                                <Text
                                    className="text-2xl md:text-[22px] text-center mt-[10px] text-gray-900 sm:text-xl"
                                    size="txtInterRegular24"
                                >
                                    {props?.changecontrol}
                                </Text>
                            </div>
                        </div>
                        <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                            <Text
                                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                size="txtInterRegular18"
                            >
                                <li>
                                    Forms and template creation and
                                    <br />
                                    &nbsp;&nbsp;&nbsp; version control
                                </li>
                                <li>
                                    Forms and template approval
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp; workflow
                                </li>
                                <li>Forms repository</li>
                                <li>Template repository</li>
                                <li>Integrated usage control</li>
                                <li>Forms issuance and control</li>
                            </Text>
                        </div>
                    </div>
                </div> */}
        <div className="card h-full">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col gap-6 justify-start w-[full] bg-transparent">
                <Img
                  className="h-20 md:h-auto md:ml-[0] mx-auto ml-[19px] object-cover w-20"
                  src="images\img_maskgroup_14.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] text-center mb-[20px] ml-[18px] text-gray-900 sm:text-xl"
                  size="txtInterRegular24"
                >
                  {props?.changecontrol}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    Forms and template creation and version control
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Forms and template approval workflow
                  </li>
                  <li style={{ lineHeight: "1.5" }}>Forms repository</li>
                  <li style={{ lineHeight: "1.5" }}>Template repository</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated usage control
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Forms issuance and control
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackchangecontrol.defaultProps = {
  changecontrol: "Forms & Templates",
};

export default ProductsQMSStackchangecontrol;
