import React from "react";
// import "./QMS.css"
import { Img, Text } from "components";

const ProductsQMSStackOne = (props) => {
  return (
    <>
      <div className={`${props.className} `}>
        <div className="card md:h-[300px]">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full  items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full]  md:w-full">
              <div className="flex flex-col  justify-start w-[50%] bg-transparent rounded-[24px] ">
                <Img
                  className="h-30 md:h-auto md:ml-[0]  object-cover w-50"
                  src="images/Deviation.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] mb-[15px] ml-[18px] text-gray-900 sm:text-lg"
                  size="txtInterRegular24"
                >
                  {props?.deviationtext}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] rounded-[24px]  sm:text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-base md:text-sm"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    Deviation reporting and immediate actions
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Correction, QA review & approval
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Initial risk classification and escalation
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Investigation and root cause determination
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Final classification and escalation
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    QA review and final disposition
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackOne.defaultProps = {
  deviationtext: "Deviation",
};

export default ProductsQMSStackOne;
