import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Text } from "components";
gsap.registerPlugin(ScrollTrigger);

const HOMEPAGEChal = (props) => {

  useEffect(() => {

    const rows = document.querySelectorAll(".cb-tagreel-row");
    rows.forEach(function (e, i) {
      let row_width = e.getBoundingClientRect().width;
      let row_item_width = e.children[0].getBoundingClientRect().width;
      let initial_offset = ((2 * row_item_width) / row_width) * 100 * -1;
      // let x_translation = initial_offset * -1;

      gsap.set(e, {
        xPercent: `${initial_offset}`
      });

      let duration = 4 * (i + 1);

      var tl = gsap.timeline();

      tl.to(e, {
        ease: "none",
        duration: duration,
        xPercent: 0,
        repeat: -1
      });
    });
  },[])
  return (
    <>
      <div className={props.className}>
      <div className=" p-6 font-archivo-black w-full" style={{color:"#225479"}}>
        <section className="cb-tagreel">
          <div className="cb-tagreel-content" style={{ padding: '10px 0' }}>
            <div className="cb-tagreel-items   " role="marquee" style={{ margin: '-58px 0', overflow: 'hidden', cursor: 'default' }}>
              {/* First Row */}
              <div className="cb-tagreel-row" style={{ display: 'flex', position: 'relative', textAlign: 'center', whiteSpace: 'nowrap' }}>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Warning letters </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Consent decrees </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Drug recalls </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Import alerts</span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Additional warning</span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Deficiency letters</span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Warning letters </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Consent decrees </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Drug recalls </span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Import alerts</span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Additional warning</span></div>
                <div className="cb-tagreel-item sm:mr-[50px]" style={{ position: 'relative',   padding: "50px 0px", flex: '0 0 22%', textTransform: 'uppercase' ,fontSize:'20px',fontWeight:'bold' }}><span className="text-white">Deficiency letters</span></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </>
  );
};

HOMEPAGEChal.defaultProps = {
  warningletters: "Warning letters",
  consentdecrees: "Consent decrees",
  drugrecalls: "Drug recalls",
  importalerts: "Import alerts",
  additionalwarningletters: "Additional warning",
  deficiencyletters: "Deficiency letters",
  warningletters1: "Warning letters",
  consentdecrees1: "Consent decrees",
  drugcallevents: "Drug call events",
  fdaimportalerts: "FDA import alerts",
  deficiencyletters1: "Deficiency letters",
};

export default HOMEPAGEChal;
