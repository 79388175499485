import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Img, Line, Text } from "components";
import {  Reveal, } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;

const slideUp = keyframes`
        from {
            opacity: 0;
            transform: translateY(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;


gsap.registerPlugin(ScrollTrigger);


const HOMEPAGEDoqssence = (props) => {
  return (
    <>
      {/* <div className="relative"> */}
        <div className={props.className}>
          <img id="dms" src="./dms.png" alt="Document management system" />
          <div className="dmsLine "></div>
          <div className=" flex md:flex-col  flex-row sm:gap-2 items-center justify-between max-w-full w-full">
            <div className="flex flex-1 flex-col items-start justify-start max-w-[721px] sm:w-[50%] w-full">
              <div className="flex flex-col items-start justify-start max-w-[721px] w-full">
                <div id="DocumentManagementSystem" className="flex flex-col items-start justify-start w-auto md:w-full">
                  <Reveal keyframes={SlideFade} delay="10" >
                    <div className="flex flex-col items-start justify-start max-w-[661px] w-full">

                      <div className="head">
                        <Text
                          className="sm:text-[38px] md:text-[44px] text-[48px] text-green-600 w-auto"
                          size="txtBrunoAceRegular52"
                        >
                          {props?.doqssence}
                        </Text>
                      </div>

                    </div>
                    <Text
                      className="text-xl md:text-lg text-gray-50 sm:text-sm tracking-[0.48px] w-auto"
                      size="txtInterBold24Gray50"
                    >
                      {props?.documentmanagemOne}
                    </Text>
                  </Reveal>

                </div>
              </div>
            </div>
            <Reveal keyframes={SlideFade} duration="700">
              <Link to="DMS">
              <Img
                className="h-12 w-12 sm:mt-9"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
              </Link>
            </Reveal>
          </div>
          <Line className="bg-white-A700_99 h-px w-full" />
          <Text
            className="leading-[28.00px] max-w-[1175px] md:max-w-full text-gray-50 lg:text-lg md:text-md sm:text-sm"
            size="txtInterRegular20"
          >
            {props?.description}
          </Text>
          <div className="flex flex-col sm:flex-col gap-0 items-start justify-start w-full sm:h-auto md:w-full">
            <Text
              className="text-lg text-white-A700 w-auto"
              size="txtInterRegular18WhiteA700"
            >
              {props?.features}
            </Text>
            <div className="flex  sm:flex-col md:flex-col  items-center justify-between max-w-[full] w-full">
              <div className="flex md:flex-1  md:flex-row sm:flex-col  gap-x-[47px] gap-y-0 items-start justify-start w-full md:w-full">
                <div className="flex flex-col gap-3 items-start justify-start w-full">
                  <Reveal keyframes={slideUp} delay="50" >
                    <div className="flex flex-row gap-2 justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.language}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="70" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopm}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="90" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmOne}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="110" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.languageOne}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="130" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmTwo}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="150" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmThree}
                      </Text>
                    </div>
                  </Reveal>
                </div>
                <div className="flex flex-col gap-3 items-start justify-start w-full">
                  <Reveal keyframes={slideUp} delay="50" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmFour}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="70" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.languageTwo}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="90" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmFive}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="110" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmSix}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="130" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.languageThree}
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={slideUp} delay="150" >
                    <div className="flex flex-row gap-2  justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_iconparkoutlinecheckone.svg"
                        alt="iconparkoutline_Eleven"
                      />
                      <Text
                        className="text-gray-50 text-base w-auto"
                        size="txtInterRegular18"
                      >
                        {props?.contentdevelopmSeven}
                      </Text>
                    </div>
                  </Reveal>
                </div>
              </div>
              <Reveal keyframes={slideUp} delay="70" >
                <Img
                  className="md:flex-1 h-[250px] sm:h-auto object-cover sm:ml-[-25px] md:ml-[-33px] md:w-[340px] w-[340px]"
                  src="images/DoQssence.webp"
                  alt="images/DoQssence.png"
                />
              </Reveal>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

HOMEPAGEDoqssence.defaultProps = {
  doqssence: "DoQssence",
  documentmanagemOne: "Document Management System (DMS)",
  description:
    "Streamline and secure your document management with our advanced electronic DMS, ensuring seamless compliance and peace of mind.",
  features: "Features:",
  language: "Content Devlopment & Review",
  contentdevelopm: "Template Managment",
  contentdevelopmOne: "Version Control & Review History",
  languageOne: "Advance Search & Retrieval",
  contentdevelopmTwo: "Document Workflow & Collaboration",
  contentdevelopmThree: "Document Security & Access Control",
  contentdevelopmFour: "User Managment & Control",
  languageTwo: "Audit Trail",
  contentdevelopmFive: "Governance & Advance Analytics",
  contentdevelopmSix: "Configure Managment & Retrival",
  languageThree: "Mobile Accessiblity",
  contentdevelopmSeven: "Print Control",
};

export default HOMEPAGEDoqssence;
