import React, { useEffect } from "react";
import { gsap } from "gsap";
import { Button, Img, Text } from "components";
import "./QMSC2.css";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
const ProductsQMSC2 = (props) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(".line2", {
      height: 200,
      scrollTrigger: {
        scrub: 2,
        trigger: ".line2",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);
  const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
  return (
    <>
      <div className={props.className}>
        <span id="di">
          <i class="ri-mind-map"></i>
        </span>
        <div className="line2"></div>
        <div className="flex flex-col gap-2 items-start justify-start w-[416px] sm:w-full">
          <div className="flex flex-col items-center justify-start w-16">
            <div className="flex flex-col items-center justify-start w-16">
              <div className="flex flex-col items-start justify-start w-16">
                <div className="flex flex-col items-center justify-start w-16">
                  <Img
                    className="h-16 md:h-auto object-cover w-16"
                    src="images/img_maskgroup_7.png"
                    alt="maskgroup_One"
                  />
                </div>
              </div>
            </div>
          </div>
          <Text
            className="sm:text-2xl  md:text-[26px] text-[28px] text-gray-50 w-auto"
            size="txtInterBold28"
          >
            {props?.dataintegrity}
          </Text>
        </div>
        <div className="flex md:flex-col  sm:flex-col md:gap-10 sm:gap-0 items-center justify-between max-w-[full] w-full">
          <div className="flex sm:flex-1 flex-col gap-6 sm:gap-0 items-start justify-center w-auto sm:w-full">
            <Reveal keyframes={slideUp} delay="50">

              <Text
                className="leading-[20.00px] max-w-[590px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
                size="txtInterRegular18"
              >
                {props?.descriptionFour}
              </Text>
            </Reveal>
            <Reveal keyframes={slideUp} delay="70">

              <Button
                className="cursor-pointer sm:mt-[50px] font-inter min-w-[157px] text-base md:text-sm sm:text-sm text-center"
                shape="round"
                color="white_A700_14"
                size="md"
                variant="fill"
              >
                {props?.msglackofreliability}
              </Button>
            </Reveal>
          </div>
          <Reveal keyframes={slideUp} delay="90">

            <Img
              className="h-10 sm:invisible w-10"
              src="images/img_phflowarrow.svg"
              alt="phflowarrow_One"
            />
          </Reveal>
          <div className="flex flex-col items-center justify-start rounded-[12px] shadow-bs7">
            <Reveal keyframes={slideUp} delay="120">

              <div className="bg-gradient1  flex flex-col items-center justify-center p-6 sm:px-5 rounded-[12px] shadow-bs8 w-auto sm:w-full">
                <Text
                  className="leading-[20.00px] max-w-[537px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
                  size="txtInterRegular18"
                >
                  {props?.descriptionFive}
                </Text>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSC2.defaultProps = {
  dataintegrity: "Data Integrity",
  descriptionFour:
    "eQMS and associated system must ensure data is accurate, reliable and complete. Implementing validated processes can be time consuming and resource intensive",
  msglackofreliability: "Lack of Reliability",
  descriptionFive: (
    <>
      The seamless date exchange between various modules of QMS and outside
      Qssence products provides greater assurance on data reliability and
      integrity.
      <br />
      The real-time data management and electronic signatures provide required
      assurance on ALCOA+ principles.{" "}
    </>
  ),
};

export default ProductsQMSC2;
