import React, { useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Button, Img, Text } from "components";
import "./QMSC1.css";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
gsap.registerPlugin(ScrollTrigger);

const ProductsQMSC1 = (props) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(".line1", {
      height: 220,
      scrollTrigger: {
        scrub: 2,
        trigger: ".line1",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);
  // const SlideFade = keyframes`
  //       from {
  //           opacity: 0;
  //           transform: translate(-20%, 0);
  //       }

  //       to {
  //           opacity: 1;
  //           transform: translate(0, 0);
  //       }
  //   `;
  const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
  return (
    <>
      <div className={props.className} >
        <span id="crf">
          <i class="ri-asterisk"></i>
        </span>
        <div className="line1"></div>
        <div className="flex flex-col gap-2 items-start justify-start max-w-[630px]  w-full">
          <div className="flex flex-col items-center justify-start w-16">
            <div className="flex flex-col items-center justify-start w-16">
              <div className="flex flex-col items-start justify-start w-16">
                <div className="flex flex-col h-16 items-center justify-start w-16">
                  <Img
                    className="h-16 md:h-auto object-cover w-16"
                    src="images/img_maskgroup_64x64.png"
                    alt="maskgroup"
                  />
                </div>
              </div>
            </div>
          </div>

          <Text
            className="sm:text-2xl md:text-[26px] text-[28px] text-gray-50 w-auto"
            size="txtInterBold28"
          >
            {props?.complexregulatoOne}
          </Text>
        </div>


        <div className="flex md:flex-col  sm:flex-col md:gap-10 sm:gap-0 items-center justify-between max-w-[full] w-full">
          <div className="flex sm:flex-1 flex-col gap-6 sm:gap-0 items-start justify-center w-auto sm:w-full">
            <Reveal keyframes={slideUp} delay="50">

              <Text
                className="leading-[20.00px] max-w-[590px] md:max-w-full text-gray-50 text-lg md:text-base sm:text-sm"
                size="txtInterRegular18"
              >
                {props?.description}
              </Text>
            </Reveal>
            <Reveal keyframes={slideUp} delay="70">

              <Button
                className="cursor-pointer sm:mt-[50px] font-inter min-w-[157px] text-base md:text-sm sm:text-sm text-center"
                shape="round"
                color="white_A700_14"
                size="md"
                variant="fill"
              >
                {props?.lackofagility1}
              </Button>
            </Reveal>
          </div>
          <Reveal keyframes={slideUp} delay="90">

            <Img
              className="h-10 sm:invisible w-10"
              src="images/img_phflowarrow.svg"
              alt="phflowarrow_One"
            />
          </Reveal>
          <div className="flex flex-col items-center justify-start rounded-[12px] shadow-bs7">
            <Reveal keyframes={slideUp} delay="120">

              <div className="bg-gradient1  flex flex-col items-center justify-center p-6 sm:px-5 rounded-[12px] shadow-bs8 w-auto sm:w-full">
                <Text
                  className="leading-[20.00px] max-w-[537px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
                  size="txtInterRegular18"
                >
                  {props?.lackofagility}
                </Text>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSC1.defaultProps = {
  complexregulatoOne: "Complex Regulatory Framework",
  description:
    "Complex regulatory framework with stringent requirements, frequently evolving requirements and advancements in technologies makes it difficult to keep up with changes and implement them.",
  lackofagility: "Qssence QMS is Constantly upgraded consistent with the Current regulatory industry expectations. The Product is designed to achieve user-friendliness for high adoptability.The Learning Management System (LMS) helps organizations to devlope highly capable & competent workforce",
  lackofagility1: "Lack of agility and flexibility"
};

export default ProductsQMSC1;

