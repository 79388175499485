import React, {useEffect} from "react";
import { gsap } from "gsap";
// import {ScrollTrigger} from "gsap/ScrollTrigger";
import { Button, Img, Text } from "components";
import "./QMSC4.css";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const ProductsQMSC4 = (props) => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(".line4", {
      height: 220,
      scrollTrigger: {
        scrub: 2,
        trigger: ".line4",
        start: "top 50%",
        end: "top 20%",
        toggleActions: "play"
      }
    })
  }, []);
  return (
    <>
      <div className={props.className}>
        <span id="ils">
        <i class="ri-douban-fill"></i>
        </span>
        <div className="line4"></div>
        <div className="flex flex-col gap-2 items-start justify-start w-[416px] sm:w-full">
          <div className="flex flex-col items-center justify-start w-16">
            <div className="flex flex-col items-center justify-start w-16">
              <Img
                className="h-16 md:h-auto object-cover w-16"
                src="images/img_group39744.png"
                alt="group39744"
              />
            </div>
          </div>
          <Text
            className="sm:text-2xl md:text-[26px] text-[28px] text-gray-50 w-auto"
            size="txtInterBold28"
          >
            {props?.integrationofleOne}
          </Text>
        </div>
        <div className="flex md:flex-col sm:flex-col md:gap-10 sm:gap-0 items-center justify-between  max-w-[full] w-full">
          <div className="flex sm:flex-1 flex-col gap-6 sm:gap-0 items-start justify-center w-[600px] sm:w-full">
          <Reveal keyframes={slideUp} delay="50">

            <Text
              className="leading-[20.00px] max-w-[590px] md:max-w-full text-gray-50_cc text-lg  md:text-base sm:text-sm"
              size="txtInterRegular18Gray50cc"
            >
              {props?.descriptionEight}
            </Text>
            </Reveal>
          <Reveal keyframes={slideUp} delay="70">

            <Button
              className="!text-gray-50_cc mt-[50px] cursor-pointer font-inter min-w-[188px] text-base  md:text-sm sm:text-sm text-center"
              shape="round"
              color="white_A700_14"
              size="md"
              variant="fill"
            >
              {props?.suboptimalEfficiency1}
            </Button>
            </Reveal>
          </div>
          <Reveal keyframes={slideUp} delay="90">

          <Img
            className="h-[27px] sm:invisible w-[37px] "
            src="images/img_car.svg"
            alt="car"
          />
          </Reveal>
          <Reveal keyframes={slideUp} delay="120">

          <div className="bg-gradient1    flex flex-col items-center ml-[25px] sm:ml-0 justify-center p-6 sm:px-5 rounded-[12px] shadow-bs8 w-[auto] sm:w-full">
              <Text
                className="leading-[20.00px] max-w-[537px] md:max-w-full text-gray-50 text-lg  md:text-base sm:text-sm"
                size="txtInterRegular18"
              >
                {props?.suboptimalEfficiency}
              </Text>
            </div>
            </Reveal>
        </div>
      </div>
    </>
  );
};

ProductsQMSC4.defaultProps = {
  integrationofleOne: "Integration Of Legacy Systems",
  descriptionEight:
    "The legacy systems used by organizations have valuable data and integrating these with modern eQMS technologies can be complex, time taking and requiring careful planning and data migration",
  suboptimalEfficiency: "Qssence has developed specific tools for easy & accurate data migration & scalibility from legacy system into eQMS for comtinual monitoring and governance.Wssence offers pre-configured templates & workflows based on industry best practices which are user-friendly& easy to adopt.",
  suboptimalEfficiency1:"Suboptimal efficiency"
};

export default ProductsQMSC4;
