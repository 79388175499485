import React from "react";

import { Img, Text } from "components";

const ProductsQMSStackchangecontrol = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="card md:h-[300px]">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col gap-[20px] justify-start w-[35%] bg-transparent rounded-[24px] ">
                <Img
                  className="h-20 md:h-auto md:ml-[0] ml-[19px] object-cover w-20"
                  src="images/img_image15.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] mt-[10px] text-gray-900 sm:text-lg"
                  size="txtInterRegular24"
                >
                  {props?.changecontrol}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] rounded-[24px] sm:text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-base md:text-sm"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    Classification of change
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Assisted impact & risk assessment
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Tasks & scheduling assistance
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Regulatory assessment & variation tracker
                  </li>
                  <li style={{ lineHeight: "1.5" }}>CFT & SME collaboration</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Data fields for analytics
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Integrated document management
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    Task Training assessment
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackchangecontrol.defaultProps = {
  changecontrol: "Change Control",
};

export default ProductsQMSStackchangecontrol;
