import React from "react";

import { Img, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const slideUp = keyframes`
from {
    opacity: 0;
    transform: translateY(-90%, 0);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
const ServicesSd2 = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-6 items-center justify-center sm:w-full w-full">
          <div className="flex flex-col h-[60px] items-center justify-start rotate-[180deg] w-[60px]">
            <Img
              className="h-[60px] md:h-auto object-cover w-[60px]"
              src="images/calender.png"
              alt="maskgroup"
            />
          </div>
          <div className="flex flex-col gap-6 items-center justify-start w-full">
          <Reveal keyframes={slideUp} delay="90">

            <Text
              className="leading-[32.00px] text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl w-full"
              size="txtInterRegular24Gray50"
            >
              {props?.projectname}
            </Text>
            </Reveal>
            <Text
              className="leading-[24.00px] text-base text-center text-gray-50 w-full"
              size="txtInterRegular16"
            >
              {props?.projectdescription}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

ServicesSd2.defaultProps = {
  projectname: "Project Management",
  projectdescription:
    "Project management should be pruned to: ‘Appoint a project manager from within the consortium to coordinate project activities, ensure efficient communication, and monitor project progress.’",
};

export default ServicesSd2;
