import React from "react";
// import "./QMS.css"
import { Img, Text } from "components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-90%, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;
const Qmsfdavision1 = (props) => {
  return (
    <>
      <Reveal keyframes={slideUp} delay="90">
        <div className={`${props.className} `}>
          <div className="card1">
            <div className="content relative">
              <div className="front border border-green-600 border-solid   flex flex-col md:h-auto  items-center justify-center m-auto px-3.5 py-[46px]  rounded-[20px] w-[full]  md:w-full">
                <div className="flex flex-col  rounded-[20px] justify-start w-[50%] bg-transparent">
                  <Reveal keyframes={slideUp} delay="150">
                    <Text
                      className="bg-gray-900_01 flex h-[60px] ml-[30px]  items-center justify-center sm:px-5 rounded-[50%] text-2xl md:text-[22px] text-center text-gray-50 sm:text-xl tracking-[0.48px] w-[58px]"
                      size="txtInterBold24Gray50"
                    >
                      2
                    </Text>
                  </Reveal>
                  <Text
                    className="text-2xl  mt-[10px] ml-[-25px] w-[180px] sm:text-xl"
                    size="txtInterRegular24"
                  >
                    {props?.deviationtext123}
                  </Text>
                </div>
              </div>
              <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                {/* <Text
                className="leading-[20.00px] text-sm bg-green-600 sm:p-[20px] p-[5px] max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                size="txtInterRegular18"
              >
                <li>
                  Contributes to manufacturer’s<br/> &nbsp; &nbsp; &nbsp;ability to develop an effective
                  PQS
                </li>
                <li>
                  Implement innovative quality <br/>&nbsp; &nbsp; &nbsp;management systems for
               <br/> &nbsp; &nbsp; &nbsp;manufacturing
                </li>
                <li>
                  Provide ongoing insight into an<br/> &nbsp; &nbsp; establishment’s operations
                  <br/> &nbsp; &nbsp; &nbsp;between inspections
                </li>
                <li>
                  Obtain a more quantitative and<br/> &nbsp; &nbsp; objective measure of
                  manufacturing<br/> &nbsp; &nbsp;  quality and reliability at an<br/> &nbsp; &nbsp;  establishment
                </li>
              </Text> */}
                <Text
                  className="leading-[20.00px]  rounded-[20px] text-sm bg-green-600 max-w-[272px] sm:w-[400px] sm:p-[20px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                  size="txtInterRegular18"
                >
                  <ul className="list-disc pl-5" style={{ lineHeight: "1.5" }}>
                    <li style={{ lineHeight: "1.5" }}>
                      Contributes to manufacturer’s ability to develop an
                      effective PQS
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Implement innovative quality management systems for
                      manufacturing
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Provide ongoing insight into an establishment’s operations
                      between inspections
                    </li>
                    <li style={{ lineHeight: "1.5" }}>
                      Obtain a more quantitative and objective measure of
                      manufacturing quality and reliability at an establishment
                    </li>
                  </ul>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

Qmsfdavision1.defaultProps = {
  deviationtext123: (
    <Img
      className="h-[180px] md:h-[200px]   sm:h-[200px]  w-[300px] sm:w-[250px] "
      src="images/img_imagesubmiss.png"
      alt="imagesubmiss"
    />
  ),
};

export default Qmsfdavision1;
