import React, { useRef, useEffect } from "react";
import { Button, Img, Line, List, Text } from "components";
import ABOUTUSMission from "components/ABOUTUSMission";
import ABOUTUSVision from "components/ABOUTUSVision";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "./about.css";
// app.css 
import "../../styles/App.css";


const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;

const ABOUTUSPage = () => {
  const introductionRef = useRef();
  const missionVisionRef = useRef();
  const qnaRef = useRef();

  function intro() {
    const element = document.getElementById("introtab");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  function missionVision() {
    const element = document.getElementById("ourMission");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
  function QNA() {
    const element = document.getElementById("QNA");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    const intro = document.getElementById("intro");
    const missionV = document.getElementById("m&v");
    const help = document.getElementById("help");
    const ourStory = document.getElementById("ourStory");
    const ourMission = document.getElementById("ourMission");
    const qHelp = document.getElementById("qHelp");

    const ourStoryOff = ourStory.offsetTop;
    const ourMissionOff = ourMission.offsetTop - 300;
    const qHelpOff = qHelp.offsetTop - 500;

    // console.log(qHelp.offsetTop-500);
    // Set initial background colors
    intro.style.backgroundColor = "green";
    missionV.style.backgroundColor = "transparent";
    help.style.backgroundColor = "transparent";

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // console.log(scrollPosition);

      if (scrollPosition >= ourStoryOff && scrollPosition < ourMissionOff) {
        intro.style.backgroundColor = "green";
        intro.style.fontWeight = "bold";
        missionV.style.backgroundColor = "transparent";
        help.style.backgroundColor = "transparent";
      } else if (scrollPosition >= ourMissionOff && scrollPosition < qHelpOff) {
        intro.style.backgroundColor = "transparent";
        missionV.style.backgroundColor = "green";
        missionV.style.borderRadius = "10px";
        help.style.backgroundColor = "transparent";
      } else if (scrollPosition >= qHelpOff) {
        intro.style.backgroundColor = "transparent";
        missionV.style.backgroundColor = "transparent";
        help.style.borderRadius = "10px";
        help.style.backgroundColor = "green";
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div id="introtab"
        className="bg-teal-50 flex flex-col font-inter items-center justify-start mx-auto w-full"
        style={{ overflowX: "hidden" }}
      >
        <div
          className="bg-cover fixed  sm:relative sm:z-0 z-10   bg-no-repeat flex md:items-center md:p-0 md:mx-auto md:justify-evenly h-[65px] sm:h-[242px]  items-center  justify-center  top-[33px] subnav sm:mt-[55px]  sm:px-5 w-full"
          style={{ backgroundImage: "url('images/img_group39775.svg')" }}
        >
          <div className="flex md:flex  sm:flex-col  md:gap-5 items-center justify-evenly md:ml-[0] ml-[58px] md:px-5  md:mt-0  mt-50 w-auto md:w-full">
            <Button
              className=" text-white-A700 cursor-pointer  min-w-[157px] rounded-lg text-center text-lg md:text-sm sm:text-lg"
              color="green_600"
              size="md"
              id="intro"
              // variant="fill"
              onClick={intro}
              ref={introductionRef}
            >
              Introduction
            </Button>
            <div
              id="m&v"
              ref={missionVisionRef}
              onClick={missionVision}
              className="cursor-pointer hover:bg-green-600 hover:rounded-lg   flex flex-col h-10 md:h-auto items-center justify-center md:ml-[0] ml-[26px] sm:px-5 px-4 py-[24px] md:p-[9px] sm:p-[9px] w-auto"
            >
              <Text
                className="text-gray-50 text-lg md:text-sm sm:text-lg w-auto"
                size="txtInterRegular18"
              >
                Vision & Mission
              </Text>
            </div>
            <div
              // onClick={QNA}
              id="help"
              ref={qnaRef}
              onClick={QNA}
              className="cursor-pointer  hover:bg-green-600 hover:rounded-lg  flex flex-col h-10 md:h-auto items-center justify-center ml-8 md:ml-[0] sm:px-5 px-6 py-[24px] md:p-[9px] sm:p-[9px] w-auto"
            >
              <Text
                className="text-gray-50  text-lg sm:text-lg md:text-sm w-auto"
                size="txtInterRegular18"
              >
                How Qssence Helps
              </Text>
            </div>
          </div>
        </div>
        {/* main content start here sm:mt-80  */}
        <div className="flex mt-48 md:mt-36 sm:mt-7 flex-col items-start justify-start w-full md:w-full">
          <div className="flex flex-col items-start justify-start max-w-full sm:w-full w-full">
            <div className="flex flex-col md:gap-10 gap-[0px] sm:gap-[30px] items-start justify-start w-full md:w-full">
              <div
                id="ourStory"
                className="bg-teal-50 flex flex-col md:gap-10 gap-[30px] items-start justify-start  md:pl-10 pl-20 pr-5 w-full sm:pl-5 py-[30px]   sm:w-full story"
              >
                <div className="flex relative  flex-col  items-start justify-between w-full  sm:w-[97%] ourStorytxt">
                  {/* <div className="flex flex-col items-center sm:items-start justify-start w-auto md:w-full"> */}
                    {/* <div className="flex flex-col items-center sm:items-start justify-start sm:ml-0 w-full"> */}
                      {/* <div className="flex flex-col items-center sm:items-start justify-start w-full"> */}
                        <div className="flex  items-center sm:items-start justify-start w-auto ouStory sm:mb-4 md:mb-0">
                          <Reveal keyframes={SlideFade} duration="700">
                            <Text
                              className="sm:text-[38px] flex md:text-[40px] font-brunoace text-[42px]  sm:ml-0 text-gray-900_02"
                              size="txtInterBold52Gray90001"
                            >
                              Our&nbsp;
                              <span className="text-green-600 ">Story</span>
                            </Text>
                          </Reveal>
                        </div>
                      {/* </div> */}
                    {/* </div> */}
                  {/* </div> */}
                  <div className="flex sm:flex-col md:gap-10 mt-[-35px] md:mt-0 sm:mt-[0px] items-center justify-between w-full">
                    <div className="flex md:w-[60%]  flex-col gap-3 items-start justify-start w-[60%] sm:w-full">
                      <div className="flex flex-col gap-3 sm:w-full items-start justify-start  w-auto">
                        <Text
                          className="leading-[24.00px]  max-w-[full] md:max-w-full text-gray-900_01 text-lg md:text-lg sm:text-sm sm:w-full"
                          size="txtInterRegular18Gray90001"
                        >
                          Qssence is formed by highly experienced and
                          well-established senior professionals from the
                          pharmaceutical, life-science and allied tech industry.
                          With a collective wealth of deep functional knowledge
                          and expertise.
                        </Text>
                        <Text
                          className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-900_01 text-lg md:text-lg sm:text-sm sm:w-full "
                          size="txtInterRegular18Gray90001"
                        >
                          Qssence specializes in providing high-quality IT
                          solutions, expert advisory services in the domain of quality and
                          compliance.
                        </Text>
                      </div>
                      <div className="flex flex-col gap-3 items-start justify-start w-[full] sm:w-full">
                        <Text
                          className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-900_01 text-lg md:text-lg sm:text-sm"
                          size="txtInterRegular18Gray90001"
                        >
                          We are dedicated to delivering niche services that
                          meet the unique needs and challenges of the industries
                          we serve.
                        </Text>
                        <Text
                          className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-900_01 text-lg md:text-lg sm:text-sm"
                          size="txtInterRegular18Gray90001"
                        >
                          Our team of seasoned professionals have a sound
                          understanding of the intricacies and regulations governing the
                          industry, allowing us to offer specialised solutions
                          tailored to the specific requirements.
                        </Text>
                      </div>
                    </div>
                    <div className=" flex justify-end md:justify-center  sm:h-auto h-[430px] md:h-[450px]  md:ml-5 sm:ml-0 relative w-[50%] sm:w-full">
                      <Img
                        className="flex  top-0 storyImg inset-[0] justify-end md:object-cover  sm:object-cover rounded-[12px] w-[90%]"
                        src="images/ourStory.webp"
                        alt="images/ourStory.png"
                      />
                    </div>
                  </div>
                </div>
                {/* partnership strength  */}
                <div className="flex flex-col  md:gap-10 gap-[30px] items-start justify-start max-w-full w-full">
                  {/* ps header and para  */}
                  <div className="partstrength flex flex-col  gap-6 items-start h-auto sm:items-start justify-start w-full md:w-full">
                    {/* <div className="flex flex-col items-center sm:items-start justify-start w-auto md:w-full">
                      <div className="flex flex-col items-center sm:items-start justify-start w-full">
                        <div className="flex flex-col items-center sm:items-start justify-start w-full"> */}
                          <div className="Partnership flex items-center sm:items-start justify-start w-full">
                            <Reveal keyframes={SlideFade} duration="700">
                              <Text
                                className="sm:text-[38px] flex sm:ml-0 font-brunoace sm:flex-wrap md:text-[44px] text-[45px] text-gray-900_01"
                                size="txtInterBold52Gray90001"
                              >
                                <span className="text-green-600">
                                  Partnership
                                </span>
                                &nbsp;Strength
                              </Text>
                            </Reveal>
                          </div>
                        {/* </div>
                      </div>
                    </div> */}
                    <div className="flex flex-col gap-3 items-start justify-start max-w-full w-full">
                      <div className="flex flex-col items-start justify-start max-w-full w-full">
                        <div className="flex flex-col sm:w-[full] gap-3 items-start justify-start max-w-full w-full">
                          <Text
                            className="text-gray-900_01 text-lg md:text-md sm:text-sm w-auto"
                            size="txtInterRegular18Gray90001"
                          >
                            Our IT solutions encompass a range of high end
                            technologies and platforms designed to provide
                            sustainable solutions to our customers.
                          </Text>
                          <Text
                            className="leading-[24.00px] max-w-full md:max-w-full text-gray-900_01 text-lg md:text-md sm:text-sm"
                            size="txtInterRegular18Gray90001"
                          >
                            From advanced data management systems to intelligent
                            automation and analytics, our IT solutions enable
                            clients to achieve high quality standards, optimize
                            processes, improve decision-making, and achieve
                            greater operational excellence.{" "}
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start max-w-full w-full">
                        <Text
                          className="leading-[24.00px] sm:w-[full] max-w-full md:max-w-full text-gray-900_01 text-lg md:text-md sm:text-sm"
                          size="txtInterRegular18Gray90001"
                        >
                          Overall, Qssence stands as a trusted partner for
                          pharmaceutical organizations seeking superior IT
                          solutions, expert advisory services, and specialized
                          support in quality and compliance services.{" "}
                        </Text>
                      </div>
                    </div>
                  </div>
                  {/* ps header and para end */}
                  <div className="flex md:flex-col sm:w-[full] sm:flex-col md:gap-10 items-center justify-between max-w-full w-full">
                    <div className="flex flex-col w-[70%] md:w-full items-center justify-start">
                      <Img
                        className="h-[390px] md:h-auto object-fill w-[auto]"
                        src="images/img_17850removebgpreview.png"
                        alt="17850removebgpr"
                      />
                    </div>
                    <div className="w-[100%] md:w-[full] sm:w-[full] "> {/* full width */}
                      <Text
                        className="leading-[24.00px] text-gray-900_01 text-lg md:text-md sm:text-sm"
                        size="txtInterRegular18Gray90001"
                      >
                        <>
                          We offer our products and services to a wide-range of
                          mid, small, and large sized enterprizes dealing with
                          regulated markets and operates in Asia, Australia ,
                          Europe and India from following sectors:
                          <br />
                          <br />
                          <Reveal keyframes={SlideFade} delay="50">
                            <li className="cursor-text">
                              Drug product manufacturers
                            </li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="70">
                            <li>Analytical testing laboratories</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="90">
                            <li>
                              Batch release sites in Europe and UK (QP release)
                            </li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="110">
                            <li>
                              Validation and calibration services provides
                            </li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="130">
                            <li>Contract Manufacturing Organizations (CMOs)</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="150">
                            <li>
                              Contract Development & Manufacturing Organizations
                              (CDMOs)
                            </li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="170">
                            <li >Clinical research organizations (CROs)</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="190">
                            <li id="ourMission">Bio-medical industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="210">
                            <li>Bio-technology industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="230">
                            <li>Nutraceuticals industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="250">
                            <li>Cosmetic industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="270">
                            <li>Food processing industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="290">
                            <li>Medical device industry</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="310">
                            <li>Research & Development Labs</li>
                          </Reveal>
                          <Reveal keyframes={SlideFade} delay="330">
                            <li >Pharmacovigilance </li>
                          </Reveal>
                        </>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>

              {/* Oiur vision  */}
              <div
                id="missionVision"
                className="bg-gray-900_01 h-[764px] sm:h-[870px]  md:h-[885px]  md:px-5 relative w-full "
              >
                {/* <li style={{ listStyle: "none" }} id="missionVision"></li> */}
                <div className="absolute h-[1063px] md:h-[915px] inset-y-[0] my-auto right-[0] rotate-[6deg] w-[84%] sm:w-full md:w-full">
                  <div className="absolute flex flex-col items-center justify-start right-[0] top-[0] w-[49%]">
                    <Img
                      className="h-[889px] md:h-auto object-cover w-full"
                      src="images/img_maskgroup_889x615.png"
                      alt="maskgroup"
                    />
                  </div>
                  <div className="absolute bottom-[0] flex flex-col items-center justify-end left-[0] rotate-[-177deg] w-[71%]">
                    <Img
                      className="h-[915px] md:h-auto object-cover w-full"
                      src="images/img_maskgroup_915x896.png"
                      alt="maskgroup_One"
                    />
                  </div>
                </div>
                {/*1391px*/}
                <List
                  className="absolute flex flex-col gap-[10px] inset-y-[0] items-start left-[0] max-w-[full] sm:h-[819px] md:h-[717px] my-auto px-10 md:px-10 pl-20 sm:pl-5 sm:px-0 w-full"
                  orientation="vertical"
                >
                  <ABOUTUSVision
                    className="bg-gray-900_04 hover:bg-gradient-to-r relative  from-black-900_cc to-green-600_1e flex  flex-col gap-2.5 items-start justify-start mb-[5%] mt-[2%] px-[60px] py-[20px] md:py-[20px] sm:py-[20px] md:px-10 sm:w-[90%] sm:px-5 rounded-[24px] w-full  md:w-full sm:pl-8"
                    visionlabel={
                      <Text className="font-bold sm:text-4xl md:text-[38px] text-[40px] text-gray-50 tracking-[0.20px] w-[250px]">
                        <span className="text-gray-50 font-inter text-left">
                          Our{" "}
                        </span>
                        <span className="text-gray-50 font-inter text-left">
                          Vision
                        </span>
                      </Text>
                    }
                  />
                  <ABOUTUSMission className="bg-gray-900_04 relative hover:bg-gradient-to-r  from-black-900_cc to-green-600_1e flex flex-col gap-2.5 items-start justify-start my-0 px-[60px] py-[20px] sm:px-[30px] sm:w-[90%] md:px-10 rounded-[24px] w-auto md:w-full sm:pl-8" />
                </List>
              </div>
            </div>

            {/* How Qssence helps */}
            {/* <span className="invisible" ></span> */}
            <div
              id="qHelp"
              className="bg-teal-50 flex flex-col gap-10 items-start justify-start md:px-10 px-20 sm:px-5 py-[30px] w-full  md:w-full Qssencehelps"
            >
              <Reveal keyframes={SlideFade} duration="700">
                <Text
                  className="sm:text-[38px] md:text-[44px] font-brunoace text-[46px] text-gray-900_01 w-auto"
                  size="txtInterBold52Gray90001"
                >
                  <span className="text-gray-900_01  text-left font-bold">
                    How{" "}
                  </span>
                  <span className="text-green-600 text-left  font-bold">
                    Qssence
                  </span>
                  <span className="text-gray-900_01 text-left font-bold">
                    {" "}
                  </span>
                  <span className="text-gray-900_01 text-left font-bold">
                    Helps
                  </span>
                </Text>
              </Reveal>
              <List
                className="flex flex-col gap-20 items-start max-w-[full] mx-auto md:px-10 sm:px-5  w-full"
                orientation="vertical"
              >
                <div className="flex flex-1 flex-col items-center justify-start my-0 w-full">
                  <div className="flex md:flex-col sm:flex-col  md:gap-10 gap-[120px] items-start  sm:w-auto w-full md:w-full">
                    <Reveal keyframes={SlideFade} duration="700">
                      <div className="flex sm:flex-col  gap-6 items-center justify-start w-auto sm:w-full">
                        <Img
                          className="h-16 w-16"
                          src="images/img_trash.svg"
                          alt="trash"
                        />
                        <Line className="bg-gray-900 h-16 sm:h-px sm:w-full w-px" />
                        <Text
                          className="leading-[24.00px] md:max-w-full max-w-md text-gray-900_01 text-lg md:text-lg sm:text-sm"
                          size="txtInterRegular18Gray90001"
                        >
                          Engage in high quality regulatory training processes,
                          keep watch on regulatory updates
                        </Text>
                      </div>
                    </Reveal>
                    <Reveal keyframes={SlideFade} duration="500">
                      <div className="flex sm:flex-col  gap-6 items-center justify-start w-auto sm:w-full">
                        <Img
                          className="h-16 w-16"
                          src="images/img_checklist1.svg"
                          alt="checklistOne"
                        />
                        <Line className="bg-gray-900 h-16 sm:h-px sm:w-full w-px" />
                        <Text
                          className="leading-[24.00px] md:max-w-full max-w-md text-gray-900_01 text-lg md:text-lg sm:text-sm"
                          size="txtInterRegular18Gray90001"
                        >
                          Build capabilities to align with your company’s
                          specific needs & offers the necessary features for
                          compliance
                        </Text>
                      </div>
                    </Reveal>
                  </div>
                </div>
                <div className="flex md:flex-1 md:flex-col sm:flex-col md:gap-10 gap-[120px] items-start justify-between sm:ml-0 w-auto md:w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <div className="flex sm:flex-col  gap-6 items-center justify-start w-auto sm:w-full">
                      <Img
                        className="h-16 w-16"
                        src="images/img_business1.svg"
                        alt="businessOne"
                      />
                      <Line className="bg-gray-900 h-16 sm:h-px sm:w-full w-px" />
                      <Text
                        className="leading-[24.00px] md:max-w-full max-w-md text-gray-900_01 text-lg md:text-lg sm:text-sm"
                        size="txtInterRegular18Gray90001"
                      >
                        Creates a culture of quality that prioritizes compliance
                        and encourages employees to report and address issues
                        promptly
                      </Text>
                    </div>
                  </Reveal>
                  <Reveal keyframes={SlideFade} duration="500">
                    <div className="flex sm:flex-col  gap-6 items-center justify-start w-auto sm:w-full">
                      <Img
                        className="h-16 w-16"
                        src="images/img_solutions1.svg"
                        alt="solutionsOne"
                      />
                      <Line className="bg-gray-900 h-16 sm:h-px sm:w-full w-px" />
                      <Text
                        className="leading-[24.00px] md:max-w-full max-w-md text-gray-900_01 text-lg md:text-lg sm:text-sm"
                        size="txtInterRegular18Gray90001"
                      >
                        Provides easy to deploy and adopt technology for
                        sustainable benefits
                      </Text>
                    </div>
                  </Reveal>
                </div>
              </List>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ABOUTUSPage;
