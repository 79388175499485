import React from "react";
import "./DMS.css";
import { Img, Text } from "components";

const ProductsQMSStackOne = (props) => {
  return (
    <>
      <div className={`${props.className} `}>
        {/* <div className="card">
                    <div className="content relative">
                        <div className="front border border-green-600 border-solid   flex flex-col md:h-auto h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full]  md:w-full">
                            <div className=" flex flex-col gap-8 h-[234px] md:h-auto items-center justify-center px-5 py-10 rounded-[12px] w-full">
                                <Img
                                    className="h-16 md:h-auto object-cover w-16"
                                    src="images/img_maskgroup_9.png"
                                    alt="imageTwelve"
                                />
                                <Text
                                    className="text-2xl md:text-[22px] text-center text-gray-900_01 sm:text-xl w-auto"
                                    size="txtInterRegular24"
                                >
                                    {props?.deviationtext}
                                </Text>
                            </div>
                        </div>
                        <div className="absolute px-[5px] back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
                            <Text
                                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                                size="txtInterRegular18"
                            >
                                <li>SOP intiation through &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;changecontrol</li>
                                <li>SOP Drafting</li>
                                <li>Collaborative Review</li>
                                <li>SOP finalization</li>
                                <li>
                                    SOP Approval
                                </li>
                                <li>
                                    Training and SOP effective data
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp; control
                                </li>
                                <li>
                                    SOP Reository access and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;advance
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp; search
                                </li>
                            </Text>
                        </div>
                    </div>
                </div> */}
        <div className="card h-full">
          <div className="content relative">
            <div className="front border border-green-600 border-solid   flex flex-col md:h-full h-full items-center justify-center m-auto px-3.5 py-[46px]  rounded-[24px] w-[full] md:w-full">
              <div className="flex flex-col justify-start w-[full] bg-transparent">
                <Img
                  className="h-16 md:h-auto mx-auto object-cover w-16"
                  src="images/img_maskgroup_9.png"
                  alt="imageTwelve"
                />
                <Text
                  className="text-2xl md:text-[22px] text-center mb-[20px] ml-[18px] text-gray-900 sm:text-xl"
                  size="txtInterRegular24"
                >
                  {props?.deviationtext}
                </Text>
              </div>
            </div>
            <div className="absolute back bg-green-600  border border-green-600 border-solid flex flex-col md:h-auto h-full inset-[0] items-center justify-center m-auto rounded-[24px] w-[full] md:w-full">
              <Text
                className="leading-[20.00px] text-sm bg-green-600  max-w-[272px] md:max-w-full gap-y-5 text-gray-50 text-lg"
                size="txtInterRegular18"
              >
                <ul className="list-disc pl-5">
                  <li style={{ lineHeight: "1.5" }}>
                    SOP initiation through change control
                  </li>
                  <li style={{ lineHeight: "1.5" }}>SOP Drafting</li>
                  <li style={{ lineHeight: "1.5" }}>Collaborative Review</li>
                  <li style={{ lineHeight: "1.5" }}>SOP finalization</li>
                  <li style={{ lineHeight: "1.5" }}>SOP Approval</li>
                  <li style={{ lineHeight: "1.5" }}>
                    Training and SOP effective data control
                  </li>
                  <li style={{ lineHeight: "1.5" }}>
                    SOP Repository access and advance search
                  </li>
                </ul>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductsQMSStackOne.defaultProps = {
  deviationtext: "SOP / Work Instructions",
};

export default ProductsQMSStackOne;
