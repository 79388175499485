import React from "react";
import { Link } from "react-router-dom";
import { Img, Line, Text } from "components";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
const SlideFade = keyframes`
        from {
            opacity: 0;
            transform: translate(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
const slideUp = keyframes`
        from {
            opacity: 0;
            transform: translateY(-90%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    `;
const HOMEPAGEAspire = (props) => {
  return (
    <>
      {/* <div className="relative"> */}
      <div className={props.className}>
        <img id="lms" src="./lms.png" alt="aspirelms" />
        <div className="lmsLine"></div>
        <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between max-w-[full] w-full">
          <div className="flex flex-1 flex-col items-start justify-start max-w-[721px] w-full">
            <div className="flex flex-col items-start justify-start max-w-[721px] w-full">
              <div className="flex flex-col items-start justify-start w-full md:w-full">
                <div className="flex flex-col items-start justify-start max-w-[661px] w-full">
                  <Reveal keyframes={SlideFade} duration="700">
                    <div className="head">
                      <Text
                        className="sm:text-[38px] md:text-[44px] text-[48px] text-green-600 w-auto"
                        size="txtBrunoAceRegular52"
                      >
                        {props?.aspire}
                      </Text>
                    </div>
                  </Reveal>
                </div>
                <Reveal keyframes={SlideFade} duration="700">
                  <Text
                    className="text-xl md:text-[22px] text-gray-50 sm:text-xl tracking-[0.48px] w-auto"
                    size="txtInterBold24Gray50"
                  >
                    {props?.learningmanagemOne}
                  </Text>
                </Reveal>
              </div>
            </div>
          </div>
          <Reveal keyframes={SlideFade} delay="20">
            <Link to="/LMS">
              <Img
                className="h-12 w-12"
                src="images/img_arrowleft.svg"
                alt="arrowleft"
              />
            </Link>
          </Reveal>
        </div>
        <Line className="bg-white-A700_99 h-px w-full" />
        <Text
          className="leading-[28.00px] max-w-[1206px] md:max-w-full text-gray-50 text-lg"
          size="txtInterRegular20"
        >
          {props?.description}
        </Text>
        <div className="flex  flex-col gap-2 items-start justify-start w-full md:w-full">
          <Text
            className="text-lg text-white-A700 w-auto"
            size="txtInterRegular18WhiteA700"
          >
            {props?.features}
          </Text>
          <div className="flex  sm:flex-col md:flex-col  md:gap-10 items-center justify-between max-w-[full] w-full">
            <div className="flex   sm:flex-col gap-[47px] items-start justify-start w-full md:w-full">
              <div className="flex flex-col items-start justify-start w-full">
                <div className="flex flex-col  items-start justify-start max-w-[full] w-full">
                  <div className="flex md:flex-row sm:flex-col gap-[40px] items-start justify-start w-full">
                    <div className="flex flex-col gap-3 items-start justify-start w-full">
                      <Reveal keyframes={slideUp} delay="50">
                        <div className="flex flex-row gap-2  justify-start w-full">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopm}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="70">
                        <div className="flex flex-row gap-2  justify-start w-full">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmOne}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="90">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmTwo}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="110">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmThree}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="130">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmFour}
                          </Text>
                        </div>
                      </Reveal>
                    </div>
                    <div className="flex flex-col gap-3 items-start justify-start w-full">
                      <Reveal keyframes={slideUp} delay="50">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmFive}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="70">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmSix}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="90">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmSeven}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="110">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmEight}
                          </Text>
                        </div>
                      </Reveal>
                      <Reveal keyframes={slideUp} delay="130">
                        <div className="flex flex-row gap-2  justify-start w-auto">
                          <Img
                            className="h-5 w-5"
                            src="images/img_iconparkoutlinecheckone.svg"
                            alt="iconparkoutline_Nine"
                          />
                          <Text
                            className="leading-[24.00px] max-w-[full] md:max-w-full text-gray-50 text-base"
                            size="txtInterRegular18"
                          >
                            {props?.contentdevelopmNine}
                          </Text>
                        </div>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Reveal keyframes={slideUp} delay="50">
              <Img
                className="md:flex Md:justify-center h-[250px] sm:h-auto object-cover sm:ml-[-25px] md::ml-[-35px] md:w-[340px] w-[340px]"
                src="images/Aspire final.png"
                alt="imagesremovebgp"
              />
            </Reveal>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

HOMEPAGEAspire.defaultProps = {
  aspire: "Aspire",
  learningmanagemOne: "Learning Management System (LMS)",
  description:
    "Develop role specific competencies using Aspire - LMS that is integrated with  document management and QMS for high-end competent workforce and fulfils  compliance requirements.",
  features: "Features:",
  contentdevelopm: "Schedule Track & Manage Training Sessions ",
  contentdevelopmOne: "Track Attendance & Waitlist",
  contentdevelopmTwo: "Interactive Online Modules & Remote Learning",
  contentdevelopmThree: "Certification Of Compliance",
  contentdevelopmFour: "Skill Gap Assessment, Role Based Courses & Curriculum",
  contentdevelopmFive: "Scalablity & Customisation, Mobility Features",
  contentdevelopmSix: "Integration Capability & Interoprability",
  contentdevelopmSeven: "Advance Analytics & Audit Readiness",
  contentdevelopmEight: "Track Expiration Dates",
  contentdevelopmNine: "Training Feedback Process & Effective Evalution",
};

export default HOMEPAGEAspire;
