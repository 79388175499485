import React, { useEffect, useState } from "react";
import MiniHader from "components/ProductsQMSColumnqualityManagementSystemQms";

import "./qms.css";
import QMS from "./QMS";
import LMS from "Main/Products/LMS";
import DMS from "Main/Products/DMS";


// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const ProductsQMSPage = () => {
  const [type, setType] = useState(0);

  useEffect(() =>{
    window.scrollTo(0, 0);
  })
  

  return (
    <>
      <div className="w-full h-auto   overflow-x-hidden overflowQms">

      

          <MiniHader type={type} setType={setType} className="bg-gray-900_01 flex sm:flex-col  md:flex-col fixed z-10 top-[90px] sm:-mt-2 md:-mt-2  font-inter gap-3 items-center  justify-center max-w-full  px-10  sm:px-5  w-full sm:w-full  qmssubmenu" />
          {type === 0 ? <QMS/> : type === 1 ? <LMS/> : <DMS/>}
      </div>
    </>
  );
};

export default ProductsQMSPage;